import { Context } from '@nuxt/types'
import { sdk } from '@abby/sdk'
import debounce from 'lodash/debounce'

// Import our single-source auth logic
import { configureAuth } from '~/plugins/auth/authToken'

// Screen dimension state management
let cachedScreenHeight: number = window.innerHeight
let cachedScreenWidth: number = window.innerWidth

// Debounced dimension update function
const updateScreenDimensions = debounce(() => {
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth
}, 250)

export default (context: Context, inject: any) => {
  // 1) Get our central auth functions from `configureAuth`
  const { handleTokenRefresh, logout, tokenManager } = configureAuth(context)

  // 2) Initialize the cached dimension values
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth

  // 3) Watch for window resizes
  window.addEventListener('resize', updateScreenDimensions)

  // 4) Attach an SDK request interceptor to ensure we always have a valid token
  sdk.client.interceptors.request.use(async (request: Request) => {
    // Add dimension headers to the request
    request.headers.set('screen-height', cachedScreenHeight.toString())
    request.headers.set('screen-width', cachedScreenWidth.toString())
    request.headers.set('support', 'web-app')

    // Attempt to refresh (only if needed), leveraging concurrency from `handleTokenRefresh`
    try {
      const token = await handleTokenRefresh()
      if (token) {
        request.headers.set('Authorization', `Bearer ${token}`)
      }
      return request
    } catch (e) {
      // If refresh fails, log out and rethrow
      await logout()
      throw e
    }
  })

  // 5) Attach a response interceptor to handle 401 errors
  sdk.client.interceptors.response.use((response: Response) => {
    if (response.status === 401) {
      // If unauthorized, force logout
      logout()
    }
    return response
  })

  // 6) Configure the SDK (base URL, error behavior, etc.)
  sdk.client.setConfig({
    baseUrl: '/api',
    throwOnError: true,
  })

  // 7) Inject `$sdk` for use throughout the Nuxt application
  inject('sdk', {
    ...sdk,

    // Provide utility methods to directly reset or set the token in memory if needed
    resetToken: () => tokenManager.setToken(null),
    setToken: (token: string) => tokenManager.setToken(token),
  })
}

/**
 * Type augmentation so `$sdk` is recognized in Nuxt context and Vue instances.
 */
declare module 'vue/types/vue' {
  interface Vue {
    $sdk: typeof sdk & { resetToken: () => void; setToken: (token: string) => void }
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $sdk: typeof sdk & { resetToken: () => void; setToken: (token: string) => void }
  }
  interface Context {
    $sdk: typeof sdk & { resetToken: () => void; setToken: (token: string) => void }
  }
}

declare module 'vuex' {
  interface Store<S> {
    $sdk: typeof sdk & { resetToken: () => void; setToken: (token: string) => void }
  }
}
