import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'

export const useFixCustomerAddress = () => {
  const { i18n } = useContext()
  const confirmManager = useConfirmManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    const confirm = await confirmManager.ask({
      title: i18n.t('confirmBilling.ADMIN_REGENERATE_DOCUMENT.title') as string,
      content: i18n.t('confirmBilling.ADMIN_REGENERATE_DOCUMENT.content') as string,
      confirmText: i18n.t('confirmBilling.ADMIN_REGENERATE_DOCUMENT.button') as string,
      confirmButton: { color: 'primary', depressed: true },
    })

    if (!confirm) {
      return
    }
    await billingRepository.fixCustomerAddress(billing)
  }
}
