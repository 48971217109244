import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  CalculationMethod,
  CreationCompanyStep,
  ErrorIdentification,
  getCurrentDeclaration,
  getNextDeclaration,
  isDeclarationPeriodOpen, isFirstDeclarations,
  MandatState,
  Periodicite,
  Recurrence,
} from '@abby/core-legacy'

import type {
  ICompany,
  ICreationCompany,
  DeclarationDates,
  Balances,
  CreateMandat,
  QueryUrssaf,
  ReadDeclarationTotals,
  ReadEstimationsFromUrssaf,
  ReadIdentification,
  ReadMandatSepa,
  ReadUrssafJourneyDocument,
  UpdateRateConfiguration,
  UpdateUrssafJourney,
  DeleteMandat,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export interface UrssafState {
  currentEstimationsFromAPI: ReadDeclarationTotals | null,
  nextEstimationsFromAPI: ReadDeclarationTotals | null,
  initialLoading: boolean,
  loading: boolean,
  loadingUrssafEstimations: boolean,
  firstDeclarationEstimations: ReadDeclarationTotals | null,
  identification: ReadIdentification | undefined,
  currentEstimationsFromUrssaf: ReadEstimationsFromUrssaf | null,
  mandatState: MandatState | null,
  urssafJourney: ReadUrssafJourneyDocument | null,
  mandatsSepa: ReadMandatSepa[] | null,
  loadingCreationMandat: boolean,
  isCipav: boolean | null,
  errors: {
    identification: ErrorIdentification | null
  },
  modals: {
    declaration: boolean,
    revokeMandat: boolean,
    freeEstimations: boolean,
    createMandatSepa: boolean,
    createMandatUrssaf: boolean,
    updateTaxRateJanuary2025: boolean,
  },
}

export type UrssafModals = Extract<keyof UrssafState['modals'], string>
export type UrssafErrors = Extract<keyof UrssafState['errors'], string>

export const state = (): UrssafState => ({
  currentEstimationsFromAPI: null,
  nextEstimationsFromAPI: null,
  initialLoading: false,
  loading: false,
  loadingUrssafEstimations: false,
  firstDeclarationEstimations: null,
  identification: undefined,
  currentEstimationsFromUrssaf: null,
  mandatState: null,
  urssafJourney: null,
  mandatsSepa: null,
  loadingCreationMandat: false,
  isCipav: null,
  errors: {
    identification: null,
  },
  modals: {
    declaration: false,
    freeEstimations: false,
    revokeMandat: false,
    createMandatSepa: false,
    createMandatUrssaf: false,
    updateTaxRateJanuary2025: false,
  },
})

export const getters: GetterTree<UrssafState, RootState> = {
  modals: state => state.modals,
  errors: state => state.errors,
  loadingUrssafEstimations: state => state.loadingUrssafEstimations,
  identification: state => state.identification,
  mandatState: state => state.mandatState,
  mandatBelongsToUs: state => state.mandatState && [MandatState.BELONGS_TO_US, MandatState.HORS_PERIODE].includes(state.mandatState),
  calculationMethod: state => state.urssafJourney?.basedOn || CalculationMethod.BANK_ACCOUNT,
  currentEstimationsFromUrssaf: (state): ReadEstimationsFromUrssaf | null => state.currentEstimationsFromUrssaf,
  initialLoading: state => state.initialLoading,
  loading: state => state.loading,
  urssafJourney: state => state.urssafJourney,
  loadingCreationMandat: state => state.loadingCreationMandat,
  isCipav: state => state.isCipav,
  mandatsSepa: state => state.mandatsSepa,
  recurrence: (state, _, __, rootGetters): Recurrence | undefined => {
    if (state.identification) {
      return state.identification.periodicite === Periodicite.M ? Recurrence.MONTH : Recurrence.QUARTER
    }
    return (rootGetters['company/company'] as ICompany | null)?.declarationRecurrence
  },
  currentEstimationsFromAPI: state => state.currentEstimationsFromAPI,
  nextEstimationsFromAPI: state => state.nextEstimationsFromAPI,
  firstDeclarationsEstimations: state => state.firstDeclarationEstimations,
  isDeclarationPeriodOpen: (_, getters, ___, rootGetters): boolean => {
    const { creationDate } = (rootGetters['company/company'] as ICompany | null) || {}

    return isDeclarationPeriodOpen(creationDate, getters.recurrence)
  },
  isFirstDeclarations: (_, getters, ___, rootGetters): boolean => {
    if (!rootGetters['company/company']) {
      return false
    }
    const { creationDate } = rootGetters['company/company']
    const creation = rootGetters['company/creation'] as ICreationCompany | null

    if (creation && !creation?.steps[CreationCompanyStep.COMPANY_CREATED]?.finishedAt) {
      return true
    }

    if (!creationDate || !getters.recurrence) {
      return false
    }

    return isFirstDeclarations(creationDate, getters.recurrence)
  },
  nextDeclarationDates: (_, getters): DeclarationDates => {
    return getNextDeclaration(getters.recurrence)
  },
  currentDeclarationDates: (_, getters): DeclarationDates => {
    return getCurrentDeclaration(getters.recurrence)
  },
}

export const mutations: MutationTree<UrssafState> = {
  SET_CURRENT_ESTIMATIONS_FROM_API (state, currentEstimationsFromAPI: ReadDeclarationTotals) {
    state.currentEstimationsFromAPI = currentEstimationsFromAPI
  },
  SET_NEXT_ESTIMATIONS_FROM_API (state, nextEstimationsFromAPI: ReadDeclarationTotals) {
    state.nextEstimationsFromAPI = nextEstimationsFromAPI
  },
  SET_MANDAT_STATE (state, value: MandatState) {
    state.mandatState = value
  },
  SET_INITIAL_LOADING (state, value: boolean) {
    state.initialLoading = value
  },
  SET_LOADING (state, value: boolean) {
    state.loading = value
  },
  SET_LOADING_URSSAF_ESTIMATIONS (state, value: boolean) {
    state.loadingUrssafEstimations = value
  },
  SET_LOADING_CREATION_MANDAT (state, value: boolean) {
    state.loadingCreationMandat = value
  },
  OPEN_MODAL (state: UrssafState, key: UrssafModals) {
    state.modals[key] = true
  },
  SET_URSSAF_ERRORS (state: UrssafState, { key, code }: {key: UrssafErrors, code: any}) {
    state.errors[key] = code
  },
  CLOSE_MODAL (state: UrssafState, key: UrssafModals) {
    state.modals[key] = false
  },
  SET_URSSAF_JOURNEY (state, journey: ReadUrssafJourneyDocument) {
    state.urssafJourney = journey
  },
  SET_MANDATS_SEPA (state, mandatsSepa: ReadMandatSepa[] | null) {
    state.mandatsSepa = mandatsSepa
  },
  SET_IDENTIFICATION (state, identification: ReadIdentification) {
    state.identification = identification
  },
  SET_IS_CIPAV (state, isCipav: boolean | null) {
    state.isCipav = isCipav
  },
  SET_CURRENT_ESTIMATIONS_FROM_URSSAF (state, currentEstimationsFromUrssaf: ReadEstimationsFromUrssaf) {
    state.currentEstimationsFromUrssaf = currentEstimationsFromUrssaf
  },
  RESET (_currentState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<UrssafState, UrssafState> = {
  async identification ({ commit }, siret: QueryUrssaf) {
    try {
      const identification = await this.$api.urssaf.getIdentification(siret)
      commit('SET_IDENTIFICATION', identification)
    } catch (e: any) {
      commit('SET_URSSAF_ERRORS', { key: 'identification', code: e.response.data.code })
    }
  },
  async verifyHasMandatWithUs ({ commit, dispatch, state, getters }, { siret }) {
    if (!siret) {
      commit('SET_MANDAT_STATE', null)
      return
    }

    try {
      await dispatch('identification', { siret })
      if (!state.identification) {
        commit('SET_MANDAT_STATE', MandatState.NOT_RECOGNIZED)
        return
      }

      const { mandatState, isCipav } = await this.$api.urssaf.hasMandatWithUs({ siret })
      commit('SET_IS_CIPAV', isCipav)

      if (mandatState !== MandatState.DOES_NOT_BELONG_TO_US) {
        commit('SET_MANDAT_STATE', mandatState)
        return
      }
      commit('SET_MANDAT_STATE', MandatState.FREE)
    } catch (e) {
      commit('SET_MANDAT_STATE', MandatState.NOT_RECOGNIZED)
    }
  },
  async createMandat ({ commit }, payload: CreateMandat): Promise<boolean> {
    commit('SET_URSSAF_ERRORS', { key: 'identification', code: null })
    try {
      const doneSuccessfully = await this.$api.urssaf.createMandat(payload)
      return Boolean(doneSuccessfully)
    } catch (e) {
      return false
    }
  },
  async updateUrssafJourney ({ commit }, payload: UpdateUrssafJourney) {
    const urssafJourney = await this.$api.urssaf.updateUrssafJourney(payload)
    commit('SET_URSSAF_JOURNEY', urssafJourney)
  },
  async revokeMandat (_, payload: DeleteMandat): Promise<boolean> {
    const doneSuccessfully = await this.$api.urssaf.revokeMandat(payload)
    return Boolean(doneSuccessfully)
  },
  async fetchUrssafJourney ({ commit }) {
    const journey = await this.$api.urssaf.get()
    commit('SET_URSSAF_JOURNEY', journey)
  },
  async fetchMandatsSepa ({ commit, rootGetters }) {
    const { siret } = rootGetters['company/company']
    const mandats = await this.$api.urssaf.getMandatsSepaList({ siret })
    commit('SET_MANDATS_SEPA', mandats)
  },
  async fetchCurrentEstimationsFromUrssaf ({ rootGetters, commit, getters }, balances: Balances) {
    if (getters.mandatState !== MandatState.BELONGS_TO_US) {
      return
    }

    commit('SET_LOADING_URSSAF_ESTIMATIONS', true)
    // on admet que si on utilise cette méthode, l'utilisateur possède déjà un mandat avec nous
    const { siret } = rootGetters['company/company']
    const recurrence = getters.recurrence
    const { period } = getCurrentDeclaration(recurrence)

    try {
      const currentEstimationsFromUrssaf = await this.$api.urssaf.getEstimations({
        from: new Date(period.from.toString()),
        to: new Date(period.to.toString()),
        siret,
        balances,
        recurrence,
      })
      commit('SET_CURRENT_ESTIMATIONS_FROM_URSSAF', currentEstimationsFromUrssaf)
    } finally {
      commit('SET_LOADING_URSSAF_ESTIMATIONS', false)
    }
  },
  async fetchCurrentEstimationsFromAPI ({ getters, commit }, balances: Balances) {
    commit('SET_LOADING_URSSAF_ESTIMATIONS', true)
    const { period } = getters.currentDeclarationDates

    try {
      const currentEstimationsFromAPI = await this.$api.urssaf.getTotals({
        from: period.from.format('YYYY-MM-DD'),
        to: period.to.format('YYYY-MM-DD'),
        balances,
      })
      commit('SET_CURRENT_ESTIMATIONS_FROM_API', currentEstimationsFromAPI)
    } finally {
      commit('SET_LOADING_URSSAF_ESTIMATIONS', false)
    }
  },
  async changeCalculationMethod ({ dispatch }, value: CalculationMethod) {
    await this.$api.urssaf.changeBasedOn({ basedOn: value })
    await dispatch('init')
  },
  async changeRateConfig ({ dispatch }, value: UpdateRateConfiguration) {
    await this.$api.urssaf.changeRateConfig(value)
    await dispatch('init')
  },
  async fetchNextEstimationsFromAPI ({ commit, getters }, balances) {
    commit('SET_LOADING_URSSAF_ESTIMATIONS', true)
    const { period } = getters.nextDeclarationDates

    try {
      const nextEstimationsFromAPI = await this.$api.urssaf.getTotals({
        from: period.from.format('YYYY-MM-DD'),
        to: period.to.format('YYYY-MM-DD'),
        balances,
      })
      commit('SET_NEXT_ESTIMATIONS_FROM_API', nextEstimationsFromAPI)
    } finally {
      commit('SET_LOADING_URSSAF_ESTIMATIONS', false)
    }
  },
  async init ({ dispatch, getters, rootGetters, commit }) {
    try {
      commit('SET_LOADING', true)
      const isEI = rootGetters['company/isEI']
      const { siret } = (rootGetters['company/company'] as ICompany | null) || {}
      await dispatch('fetchUrssafJourney')
      if (!isEI || !siret) {
        return
      }

      const isInCreation = rootGetters['company/isInCreation']
      const creation = rootGetters['company/creation']

      await Promise.all([
        ...isInCreation && !creation ? [dispatch('company/fetchCreation', undefined, { root: true })] : [],
        ...(getters.mandatState !== MandatState.BELONGS_TO_US) ? [dispatch('verifyHasMandatWithUs', { siret })] : [],
      ])

      if (siret) {
        await dispatch('fetchCurrentEstimationsFromUrssaf')
      }

      await Promise.all([
        dispatch('fetchNextEstimationsFromAPI'),
        dispatch('fetchCurrentEstimationsFromAPI'),
        dispatch('declarations/fetchDeclarationForCurrentPeriod', null, { root: true }),
        dispatch('declarations/fetchDeclarations', null, { root: true }),
      ])
    } catch (e) {
      //
    } finally {
      commit('SET_LOADING', false)
    }
  },
  openModal ({ commit }, key: keyof UrssafState['modals']) {
    commit('OPEN_MODAL', key)
  },
  closeModal ({ commit }, key: keyof UrssafState['modals']) {
    commit('CLOSE_MODAL', key)
  },
}
