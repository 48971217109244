import { PlanFeature } from '@abby/core-legacy'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'

export const useOpenBillingFrequencyModal = () => {
  const planManager = usePlanManager()
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    if (!planManager.hasAccessToFeature(PlanFeature.BILLING_FREQUENCY)) {
      return planManager.openPlansModal(PlanFeature.BILLING_FREQUENCY)
    }
    await billingStore.openBillingFrequencyModal(billing)
  }
}
