import { Money, PaymentMethod } from '@abby/shared'
import { BillingAccountingRegulations } from '~/services/billing/_common/interfaces/BillingAccountingRegulations'

export type BillingPaymentProps = {
  id: string
  transactionId?: string
  method: PaymentMethod
  otherMethod?: string;
  amount: Money
  receivedAt: number
  regulations?: BillingAccountingRegulations
};

export class BillingPayment {
  readonly id: string;
  readonly transactionId?: string;
  readonly method: PaymentMethod;
  readonly otherMethod?: string;
  readonly amount: Money;
  readonly receivedAt: number;
  readonly regulations?: BillingAccountingRegulations

  private constructor (props: BillingPaymentProps) {
    this.id = props.id
    this.transactionId = props.transactionId
    this.method = props.method
    this.otherMethod = props.otherMethod
    this.amount = props.amount
    this.receivedAt = props.receivedAt
    this.regulations = props.regulations
  }

  public static create (props: BillingPaymentProps): BillingPayment {
    return new BillingPayment(props)
  }
}
