import { PersonalServiceActivity } from '@abby/shared'
import { ProductForm } from '~/services/product/_common/forms/Product.form'
import { useProductRepository } from '~/composables/product/_common/useProduct.repository'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'

export const useUpdateProductPSA = () => {
  const productRepository = useProductRepository()
  const toasterManager = useToasterManager()

  return async (product: ProductForm) => {
    if (product.id) {
      const editedProduct = await productRepository.updateProductPersonalServiceActivity({
        ...product,
        id: product.id,
        personalServiceActivity: product.personalServiceActivity || PersonalServiceActivity.AIDE_HUMAINE, // uniquement à but de typage
      })
      toasterManager.success({ message: 'Le produit a bien été modifié' })
      return editedProduct
    }
  }
}
