import { Middleware } from '@nuxt/types'
import { User } from '@firebase/auth'
import { UpdateManagerInstance } from '~/plugins/updateManager'
import { TokenManager } from '~/plugins/auth/authToken'

const duplicateSlash = (arr: string[]) => [
  ...arr,
  ...arr.map(path => `${path}/`),
]
const anonymousPaths = duplicateSlash([
  '/firebase-connect',
  '/auth/login',
  // TODO: redirection path for legacy onboarding
  '/start',
  '/maintenance',
])

const publicPaths = duplicateSlash([
  '/firebase-connect',
  '/redirection',
  '/connect',
  '/payment',
  '/clientPortal',
  '/create-contact-sap',
  '/discovery',
  '/maintenance',
])

const handleAnonymous = (isConnected: boolean, to: string): string | null => {
  if (!isConnected && !anonymousPaths.includes(to)) {
    return '/auth/login'
  }
  if (isConnected && anonymousPaths.includes(to)) {
    return '/'
  }
  return null
}

const channel = window.BroadcastChannel ? new BroadcastChannel('logout') : null

export const initFirebaseEvents = ($fire: any, $api: any, $sdk: any, store: any, $updateManager: UpdateManagerInstance) => {
  $fire.auth.onAuthStateChanged(async (user: User) => {
    if (!user) {
      return
    }

    try {
      const expirationTime = (user as any).stsTokenManager.expirationTime
      const diffExpirationTime = expirationTime - new Date().getTime()
      const token = await user.getIdToken(diffExpirationTime < 1000)
      TokenManager.getInstance().setToken(token)
      await store.dispatch('auth/onAuthStateChangedAction', { authUser: user })
      await $updateManager.checkForUpdate()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  })
}

export const retrieveToken = () => {
  const tokenKey = Object.entries(localStorage).find(([key]) =>
    key.includes('firebase:authUser:'),
  )
  if (!tokenKey) {
    return {
      token: null,
      refreshToken: null,
      expirationTime: null,
    }
  }
  const { stsTokenManager } = JSON.parse(localStorage[tokenKey[0]])
  return {
    token: stsTokenManager.accessToken,
    refreshToken: stsTokenManager.refreshToken,
    expirationTime: stsTokenManager.expirationTime,
  }
}

export const handleAuthentication = async (
  $fire: any,
  $api: any,
  $sdk: any,
  store: any,
  $updateManager: any,
) => {
  if (store.getters['auth/expirationTime'] !== null) {
    return true
  }
  initFirebaseEvents($fire, $api, $sdk, store, $updateManager)

  const { token, expirationTime } = retrieveToken()
  const isConnected = !!token
  if (isConnected) {
    store.dispatch('auth/setExpirationTime', expirationTime)
    TokenManager.getInstance().setToken(token)
    await store.dispatch('nuxtClientInit', { $fire })
  }
  return isConnected
}

const auth: Middleware = async ({
  $fire,
  $api,
  store,
  route,
  $updateManager,
  $planManager,
  redirect,
  $sdk,
}) => {
  if (publicPaths.includes(route.path)) {
    return
  }

  const isConnected = await handleAuthentication(
    $fire,
    $api,
    $sdk,
    store,
    $updateManager,
  )

  channel?.addEventListener('message', (event) => {
    if (document.hasFocus()) { return }
    if (event.data === 'logout') {
      store.dispatch('auth/logout')
    }
    channel?.close()
  })

  const user = store.getters['auth/user']

  // DEBUT - GESTION TEST TUNNEL INSCRIPTION
  // @ts-ignore
  if (
    !route.path.includes('/start/') &&
    user?.onboardingAt === null &&
    route.path !== '/start'
  ) {
    return redirect('/start', route.query)
  }

  if (
    route.path.includes('/start/') &&
    user?.id &&
    user?.onboardingAt !== null &&
    route.path !== '/'
  ) {
    return redirect('/', route.query)
  }

  // FIN - GESTION TEST TUNNEL INSCRIPTION

  const anonymousRedirect = handleAnonymous(isConnected, route.path)
  if (anonymousRedirect) {
    return redirect(anonymousRedirect, {
      redirect_to: route.path,
      ...route.query,
    })
  }

  if (!isConnected && !anonymousRedirect) {
    return
  }

  if (($planManager.displayAbbyCreationPlans()) && route.path !== '/company/creation') {
    return redirect('/company/creation', route.query)
  }
}

export default auth
