import { BillingType } from '@abby/shared'
import { BillingType as LegacyBillingType } from '@abby/core-legacy'

export const mapBillingTypeToLegacy: { [key in LegacyBillingType]: BillingType } = {
  [LegacyBillingType.INVOICE]: BillingType.INVOICE,
  [LegacyBillingType.ADVANCE]: BillingType.ADVANCE,
  [LegacyBillingType.ASSET]: BillingType.ASSET,
  [LegacyBillingType.ESTIMATE]: BillingType.ESTIMATE,
  [LegacyBillingType.PURCHASE_ORDER]: BillingType.PURCHASE_ORDER,
}
