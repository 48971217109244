import { CreateContact } from '@abby/shared'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'

export const useCreateContact = () => {
  const contactRepository = useContactRepository()
  const toasterManager = useToasterManager()
  const busManager = useBusManager()

  const createContact = async (contact: CreateContact) => {
    const newContact = await contactRepository.createContact(contact)
    toasterManager.success({ message: 'Le contact a bien été créé' })
    busManager.emit('contactCreated', newContact)
    return newContact
  }

  const handleError = (error: any) => {
    toasterManager.autoError(error)
  }

  return async (contact: CreateContact) => {
    try {
      return await createContact(contact)
    } catch (error) {
      handleError(error)
    }
  }
}
