import { OrganizationContactForm } from '~/services/client/organization/forms/OrganizationContact.form'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useUpdateOrganizationContact = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organizationContact: OrganizationContactForm) => {
    const editedOrganizationContact = await organizationRepository.updateContact(organizationContact)
    alertManager.success('Le contact a bien été mis à jour')
    busManager.emit('organizationContactUpdated', editedOrganizationContact)
    return editedOrganizationContact
  }
}
