import { PlanFeature } from '@abby/core-legacy'
import { Category } from '~/services/productivity/opportunity/entities/Category.entity'
import { BillingCustomer } from '~/services/billing/_common/entities/BillingCustomer.entity'
import { useOpportunityStore } from '~/composables/productivity/opportunity/useOpportunity.store'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'

export const useOpenOpportunityFormSidePanel = () => {
  const opportunityStore = useOpportunityStore()
  const planManager = usePlanManager()

  return async ({ category, customer }: { category: Category, customer?: BillingCustomer }) => {
    if (!planManager.hasAccessToFeature(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)) {
      return planManager.openPlansModal(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)
    }
    return await opportunityStore.openOpportunityFormSidePanel({ category, customer })
  }
}
