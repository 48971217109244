import { ClientType } from '~/services/client/_common/enums/ClientType.enum'
import { Organization } from '~/services/client/organization/entities/Organization.entity'
import { useClientStore } from '~/composables/client/_common/useClient.store'

export const useOpenClientFormSidePanel = () => {
  const clientStore = useClientStore()

  return ({ type, organization }: {type: ClientType, organization?: Organization}) => {
    if (type === ClientType.CONTACT_ORGANIZATION) {
      clientStore.selectedOrganizationContactOrganization.value = organization
    }
    clientStore.toggleClientFormSidePanel(true, type)
  }
}
