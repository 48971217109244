import { Plugin } from '@nuxt/types'

export interface PosthogInstance {
  init: () => void;
}

export const PosthogPlugin: Plugin = ({ store, $planManager }, inject) => {

  const waitUntilPosthogIsSet = (callback?: () => any) => {
    if (!process.browser) { return }
    // @ts-ignore
    if (typeof window?.posthog !== 'undefined') {
      callback?.()
    } else {
      setTimeout(() => { waitUntilPosthogIsSet(callback) }, 1000)
    }
  }

  const identify = () => {
    if (!process.browser) { return }
    waitUntilPosthogIsSet(() => {
      const user = store.getters['auth/user']
      if (!user) { return }
      const company = store.getters['company/company']
      const billingConfiguration = store.getters['billingConfiguration/billingConfiguration']
      // @ts-ignore
      window?.posthog.identify(user.id, {
        id: user?.id || '',
        email: user?.email || '',
        name: user?.fullName || '',
        subscribedPlan: $planManager.whichPlanCompanyHasSubscribed(),
        actualPlan: $planManager.whichPlanCompanyHas(),
        hasVat: company?.hasVat || false,
        activity: company?.activity?.name || company?.otherActivity || '',
        tiersPrestationActivated: billingConfiguration?.tiersPrestationActivated || false,
        tiersPrestationValidated: billingConfiguration?.tiersPrestationValidated || false,
      })

    })
  }

  const init = () => {
    if (store.getters['auth/connectedFromDashboard']) { return }
    identify()
    if ((window as any).posthog) { return }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = '/scripts/posthog.js'
    const firstScript: any = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  }

  inject('posthog', {
    init,
    identify,
  })
}
export default PosthogPlugin

declare module 'vue/types/vue' {
  interface Vue {
    $posthog: PosthogInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $posthog: PosthogInstance
  }
  interface Context {
    $posthog: PosthogInstance
  }
}

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $posthog: PosthogInstance
  }
}
