import { Money, PaymentMethod, CurrencyCode } from '@abby/shared'

type BankTransactionItemProps = {
  id: string;
  accountId: string;
  label: string;
  fullLabel: string;
  amount: Money;
  valueDate: Date;
  method?: PaymentMethod
  otherMethod?: string
  currencyCode: CurrencyCode
  remainingAmount?: Money
}

export class BankTransactionItem {
  readonly id: string;
  readonly accountId: string;
  readonly label: string;
  readonly fullLabel?: string;
  readonly amount: Money;
  readonly valueDate: Date;
  readonly method?: PaymentMethod
  readonly otherMethod?: string
  readonly currencyCode: CurrencyCode
  readonly remainingAmount?: Money

  private constructor (props: BankTransactionItemProps) {
    this.id = props.id
    this.accountId = props.accountId
    this.label = props.label
    this.fullLabel = props.fullLabel
    this.amount = props.amount
    this.valueDate = props.valueDate
    this.method = props.method
    this.otherMethod = props.otherMethod
    this.currencyCode = props.currencyCode
    this.remainingAmount = props.remainingAmount
  }

  public static create (props: BankTransactionItemProps): BankTransactionItem {
    return new BankTransactionItem(props)
  }
}
