import { Context, Plugin } from '@nuxt/types'
import {
  Civility,
  RegisteredType,
  SegmentEvent,
  TrackAdvertising,
} from '@abby/core-legacy'
import { Action } from '@abby/shared'
import { AuthState } from '~/store/auth'
import { CompanyState } from '~/store/company'

export interface AbbyPixelManagerInstance {
  identify: (params?: any) => void;
  page: () => void;
  sendToGTM: (payload: TrackAdvertising) => void;
  track: (action: Action, params?: any) => void;
  startExperimentation: (params: { experimentName: string, variantName: string }) => void;
}

const dontSendEvent = false

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AbbyPixelManagerPlugin: Plugin = ({ store, app, $config, $dayjs, $backend, $api }: Context, inject) => {
  const user = (): AuthState['user'] => store.getters['auth/user']
  const company = (): CompanyState['company'] => store.getters['company/company']

  const convertRegistredType = (registeredType: number | null) => {
    if (!registeredType === null) { return undefined }
    if (registeredType === RegisteredType.GESTION) { return 'Gestion' }
    if (registeredType === RegisteredType.CREATION) { return 'Creation' }
  }
  const generateRandomString = () => {
    return `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 30)
      .toUpperCase()}-${(Math.floor(Math.random() * 900000) + 100000).toString()}`
  }

  const convertGender = (civility: number | null) => {
    if (civility === Civility.MISS) { return 'm' }
    if (civility === Civility.MISTER) { return 'f' }
    return undefined
  }

  const track = async (action: Action, params?: any) => {
    if (!process.browser) { return }
    try {
      const { feature, ...data } = params || {}
      const user = store.getters['auth/user']
      // TODO remplacer par des calls depuis le sdk lorsqu'ils existeront
      if (user) {
        await $api.advertising.trackV2({
          action,
          feature,
          ...data ? { metadata: data } : {},
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        })
      } else {
        await $api.advertising.trackUnauthenticatedV2({
          action,
          feature,
          ...data ? { metadata: data } : {},
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        })
      }
    } catch {}
  }

  const sendToGTM = (payload: TrackAdvertising): void => {
    if (!process.browser) { return }
    // if (process.env.NODE_ENV !== 'production') { return }
    // Ne pas détruire la fonction la, si besoin en parler avec @Benjamin 🙏🏼
    let event = payload.event
    if (event === SegmentEvent.ACTION_KEY) {
      event = payload.data?.eventType
    }
    const _user = user()
    const _company = company()

    if (dontSendEvent || _user?.registeredType === RegisteredType.ADMINISTRATION) { return }

    const phone = _user?.phone === null || _user?.phone === '+33' ? undefined : _user?.phone
    const age = isNaN($dayjs().diff(_user?.birthDate, 'y')) ? undefined : $dayjs().diff(_user?.birthDate, 'y')

    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    // @ts-ignore
    window.dataLayer.push({
      event,
      event_id: generateRandomString(),
      user_data: {
        id: _user?.id || undefined,
        email: _user?.email || undefined,
        firstname: _user?.firstname || undefined,
        lastname: _user?.lastname || undefined,
        phone,
        birthday: _user?.birthDate ? $dayjs(_user?.birthDate).format('DD/MM/YYYY') : undefined,
        age,
        zipCode: _company?.zipCode || undefined,
        city: _company?.city?.name || undefined,
        street: _company?.address || undefined,
        registeredType: convertRegistredType(_company?.registeredType || null),
        gender: convertGender(_user?.civility || null),
      },
      data: {
        ...payload.data,
      },
    })
  }

  const startExperimentation = (params: { experimentName: string, variantName: string }) => {
    if (!process.browser) { return }
    const data = {
      ...params,
    }
    $backend.analytics.startAuthenticatedExperimentation(data)
  }

  const manager = {
    sendToGTM,
    track,
    startExperimentation,
  }
  inject('ap', {
    ...manager,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $ap: AbbyPixelManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $ap: AbbyPixelManagerInstance
  }
  interface Context {
    $ap: AbbyPixelManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $ap: AbbyPixelManagerInstance
  }
}

export default AbbyPixelManagerPlugin
