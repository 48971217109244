import { PersonalServiceActivity } from '@abby/shared'
import { useProductRepository } from '~/composables/product/_common/useProduct.repository'
import { ProductForm } from '~/services/product/_common/forms/Product.form'

export const useCreateProductPSA = () => {
  const productRepository = useProductRepository()

  const createProduct = async (product: ProductForm) => {
    return await productRepository.createProductPersonalServiceActivity({
      ...product,
      personalServiceActivity: product.personalServiceActivity || PersonalServiceActivity.AIDE_HUMAINE, // uniquement à but de typage
    })
  }

  return async (product: ProductForm) => {
    return await createProduct(product)
  }
}
