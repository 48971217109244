import { ContactMapper } from '~/services/client/contact/mappers/Contact.mapper'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useClientStore } from '~/composables/client/_common/useClient.store'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'

export const useOpenContactDetailsSidePanel = () => {
  const contactRepository = useContactRepository()
  const billingRepository = useBillingRepository()
  const clientStore = useClientStore()

  return async (contactId: string, options?: any) => {
    const readContact = await contactRepository.getContactById(contactId)
    const contact = ContactMapper.toDomain(readContact)

    const billingCustomer = await billingRepository.fetchCustomerFromContact(contact.id)
    if (contact.hasTiersPrestation) {
      contact.tiersPrestation = await contactRepository.getContactSapData(contact.id)
    }
    contact.customerId = billingCustomer.id
    clientStore.toggleContactDetailsSidePanel(true, contact, options)
  }
}
