import { useStockManagementRepository } from '~/composables/product/stock/useStockManagement.repository'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'

export const useFetchStockManagement = () => {
  const stockManagementRepository = useStockManagementRepository()
  const planManager = usePlanManager()

  const fetchStockManagement = () => {
    return stockManagementRepository.fetchStockConfiguration({
      enabled: planManager.hasAccessToFeature(planManager.features.stock_management),
    })
  }

  return {
    fetchStockManagement,
  }
}
