
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
// @ts-ignore
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify'
import { ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-phone-input',
  components: {
    VueTelInputVuetify,
    ValidationProvider,
  },
})
export default class ASPhoneInput extends Vue {
  @Prop({ default: '' })
  rules!: string | object

  @Prop({ default: false })
  required!: boolean

  @Prop({ default: 'eager' })
  mode!: string

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ default: false })
  displayOnlyFrance!: boolean;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ required: false })
  dataTestid?: string;

  dialCode = ''

  get _rules () {
    return this.rules ?? (this.required ? `requiredPhone:${this.dialCode}` : undefined)
  }

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get countriesOptions () {
    if (this.displayOnlyFrance) {
      return {
        preferredCountries: ['FR', 'RE', 'GF', 'GP', 'PF', 'YT'],
        onlyCountries: ['FR', 'RE', 'GF', 'GP', 'PF', 'YT'],
      }
    }
    return {
      preferredCountries: ['FR', 'RE', 'GF', 'GP', 'PF', 'YT', 'DE', 'GB', 'BE', 'ES', 'IT', 'CH', 'LU', 'DZ'],
      onlyCountries: [],
    }
  }

  countryChanged (value: any) {
    this.$emit('country-changed', value)
    this.dialCode = value.dialCode
    const split = this.innerValue?.split(' ') || []
    if (this.innerValue === '' || (split.length === 1 && split[0] !== `+${value.dialCode}`)) {
      this.innerValue = `+${value.dialCode}`
    }
  }

  get isProviderDisabled () {
    return (!Object.keys(this._rules).length || this.$attrs.disabled)
  }

  @ModelSync('value', 'input')
  innerValue: any;
}
