import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { OrganizationMapper } from '~/services/client/organization/mappers/Organization.mapper'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'

export const useFetchOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const billingRepository = useBillingRepository()

  return async (organizationId: string) => {
    const readOrganization = await organizationRepository.getOrganizationById(organizationId)
    const organization = OrganizationMapper.toDomain(readOrganization)
    const billingCustomer = await billingRepository.fetchCustomerFromOrganization(organizationId)
    organization.customerId = billingCustomer.id
    return organization
  }
}
