import { render, staticRenderFns } from "./ClientAvatar.vue?vue&type=template&id=7e3b71c4&scoped=true"
import script from "./ClientAvatar.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClientAvatar.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../common/temp/webapp-legacy/node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__pretti_ij34aobnxlkdmmy5vni36wgymq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e3b71c4",
  null
  
)

export default component.exports