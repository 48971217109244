import { ActionTree, GetterTree } from 'vuex'
import type { ReadThirdParty } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export type ProviderDetailsPanelConfig = {
  focusOnNote?: boolean
}

export interface ProviderDetailsState {
  provider: ReadThirdParty | null;
  openedProviderDetails: Array<{ provider: ReadThirdParty, config?: ProviderDetailsPanelConfig }>
}

export const state = (): ProviderDetailsState => ({
  provider: null,
  openedProviderDetails: [],
})

export const getters: GetterTree<ProviderDetailsState, RootState> = {
  openedProviderDetails: state => state.openedProviderDetails,
  provider: state => state.provider,
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.openedProviderDetails.slice(-1)?.[0]?.provider.id === id,
}

export const mutations = {
  SET_OPENED_PROVIDERS (state: ProviderDetailsState, payload: Array<{ provider: ReadThirdParty, config?: ProviderDetailsPanelConfig }>) {
    state.openedProviderDetails = payload
  },
  SPLICE_OPENED_PROVIDERS (state: ProviderDetailsState, index: number) {
    state.openedProviderDetails.splice(index)
  },
  RESET (_currentState: ProviderDetailsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  openProviderDetails ({ commit, getters }, { provider, config } : { provider: ReadThirdParty, config?: ProviderDetailsPanelConfig }) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(provider.id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('SET_OPENED_PROVIDERS', [
      ...getters.openedProviderDetails,
      { provider, config },
    ])
  },
  cleanProviderDetailsConfig ({ commit, getters }) {
    commit('SET_OPENED_PROVIDERS', getters.openedProviderDetails.map(({ provider }: { provider: ReadThirdParty }) => ({ provider })))
  },
  closeProviderDetails ({ commit }, index: number) {
    commit('SPLICE_OPENED_PROVIDERS', index)
  },
}
