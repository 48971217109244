import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { StockEvent } from '@abby/shared/'
import {
  StockManagementConfigurationItemMapper,
} from '~/services/product/stocks/mappers/StockManagementConfigurationItem.mapper'
import { StockMovementsPageMapper } from '~/services/product/stocks/mappers/StockMovementsPage.mapper'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'
import { AbbyError } from '~/plugins/toasterManager'

import { useBackend } from '~/composables/abby/manager/action/useBackend.manager'

export type StockMovementsPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
}

export const useStockManagementRepository = () => {
  const backend = useBackend()
  const queryClient = useQueryClient()
  const toasterManager = useToasterManager()

  const refreshConfiguration = async () => {
    await queryClient.invalidateQueries(['stockConfiguration'])
  }

  const refreshProductPaginate = async () => {
    await queryClient.invalidateQueries(['product'])
  }

  const refreshStockMovements = async () => {
    await queryClient.invalidateQueries(['stockMovements'])
    await queryClient.invalidateQueries(['product'])
  }

  const fetchStockConfiguration = ({ enabled }: { enabled: boolean }) => {
    return useQuery({
      queryClient,
      queryKey: ['stockConfiguration'],
      enabled,
      queryFn: async () => {
        const stockConfiguration = await backend.stock.retrieveStockConfiguration()
        if (!stockConfiguration) {
          return null
        }
        return StockManagementConfigurationItemMapper.fromHttp(stockConfiguration)
      },
    })
  }

  const { mutateAsync: enableStockManagement } = useMutation({
    mutationFn: () => backend.stock.enableStockManagement(),
    onSuccess: async () => {
      await refreshConfiguration()
      toasterManager.success({
        message: 'La gestion des stocks a bien été activée',
      })
    },
    onError: (error: AbbyError) => {
      toasterManager.autoError(error)
    },
  })

  const { mutateAsync: disableStockManagement } = useMutation({
    mutationFn: () => backend.stock.disableStockManagement(),
    onSuccess: async () => {
      await refreshConfiguration()
      toasterManager.success({
        message: 'La gestion des stocks a bien été désactivée',
      })
    },
    onError: (error: AbbyError) => {
      toasterManager.autoError(error)
    },
  })

  const { mutateAsync: updateStockManagementEvent } = useMutation({
    mutationFn: ({ stockEvent }: {stockEvent: StockEvent}) => backend.stock.updateStockManagementEvent({ stockEvent }),
    onSuccess: async () => {
      await refreshConfiguration()
      toasterManager.success({
        message: 'La configuration des stocks a bien été mise à jour',
      })
    },
  })

  const { mutateAsync: updateProductStock } = useMutation({
    mutationFn: ({ productId, stock } : {productId: string, stock: number}) => backend.stock.updateProductStock(productId, stock),
    onSuccess: async () => {
      await refreshConfiguration()
      await refreshProductPaginate()
      toasterManager.success({
        message: 'Le stock a bien été mis à jour',
      })
    },
  })

  const fetchStockMovements = ({ page, limit }: StockMovementsPaginateQuery) => {
    return useQuery({
      queryClient,
      queryKey: ['stockMovements', { page, limit }],
      queryFn: async () => {
        const stockMovementsPage = await backend.stock.retrieveStockMovementsPage({
          page: page.value,
          limit: limit.value,
        })
        const stockMovementsPageMapped = StockMovementsPageMapper.fromHttp(stockMovementsPage)
        return stockMovementsPageMapped
      },
      onError: (error: AbbyError) => {
        toasterManager.autoError(error)
      },
    })
  }

  const fetchStockMovementsForProduct = (productId: string) => {
    return useQuery({
      queryClient,
      queryKey: ['stockMovements'],
      queryFn: async () => {
        // TODO: Check with Andréa if it must be paginated
        const stockMovementsPage = await backend.stock.retrieveStockMovementsPageForProduct(productId, {
          page: 1,
          limit: 100,
        })
        const stockMovementsPageMapped = StockMovementsPageMapper.fromHttp(stockMovementsPage)
        return stockMovementsPageMapped
      },
      onError: (error: AbbyError) => {
        toasterManager.autoError(error)
      },
    })
  }

  const { mutateAsync: deleteStockMovements } = useMutation({
    mutationFn: (id: string) => backend.stock.deleteStockMovement(id),
    onSuccess: async () => {
      await refreshStockMovements()
    },
    onError: (error : AbbyError) => {
      toasterManager.autoError(error)
    },
  })

  return {
    // Stock configuration
    fetchStockConfiguration,
    enableStockManagement,
    disableStockManagement,
    updateStockManagementEvent,

    // Stock movements
    fetchStockMovements,
    deleteStockMovements,
    fetchStockMovementsForProduct,
    refreshProductPaginate,

    // Stock
    updateProductStock,
  }
}
