import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useMarkAsNotSigned = () => {
  const busManager = useBusManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    await billingRepository.markAsNotSigned(billing)
    billing.signedAt = undefined
    busManager.emit('billingUpdated', billing)
  }
}
