import { injectItem } from './billing.utils';
import { BillingDocumentPDFData, BillingTemplate } from '../../../types/Interface/api/pdf/PDF';

const bottomBorder = [false, false, false, true];

const mappedLines: any = {
  number: ({ i }: any) => ({
    text: i + 1,
    fontSize: 9,
    bold: true,
    style: 'grey',
    alignment: 'center',
    border: bottomBorder,
  }),
  description: ({ product, options }: any) => ({
    border: bottomBorder,
    stack: [
      ...injectItem(product.reference, {
        text: product.reference,
        bold: true,
        style: ['body', 'grey'],
        fontSize: 8,
        margin: [0, 0, 0, 1],
        alignment: 'left',
      }),
      {
        text: product.designation?.replace(/\b(\+|-)\b/gm, ' $1 '),
        style: 'body',
        bold: true,
        alignment: 'left',
      },
      ...injectItem(!options.hasSomeCompatibleSapProducts && product.productTypeRequiredMention && options.displayRequiredMentionsProduct, {
        text: product.productTypeRequiredMention,
        fontSize: 6,
        style: 'grey',
        alignment: 'left',
      }),
      ...injectItem(options.hasSomeCompatibleSapProducts && product.designation, {
        text: product.codeNature || 'Non éligible au crédit d\'impôt',
        fontSize: 6,
        style: 'grey',
        alignment: 'left',
      }),
      ...injectItem(product.description, product.description),
    ],
  }),
  unity: ({ product }: any) => ({
    text: product.productUnit,
    noWrap: true,
    margin: [0, 0, 0, 0],
    border: bottomBorder,
    style: 'body',
    alignment: 'center',
  }),
  quantity: ({ product }: any) => ({
    text: product.quantity,
    margin: [0, 0, 0, 0],
    border: bottomBorder,
    style: 'body',
    alignment: 'right',
  }),
  unit_price_without_tax: ({ product }: any) => ({
    text: product.unitPrice,
    margin: [0, 0, 0, 0],
    noWrap: true,
    style: 'body',
    border: bottomBorder,
    alignment: 'right',
  }),
  tva: ({ product }: any) => ({
    text: product.vatPercentage,
    margin: [0, 0, 0, 0],
    style: 'body',
    border: bottomBorder,
    alignment: 'right',
  }),
  total_without_tax: ({ product }: any) => ({
    alignment: 'right',
    border: bottomBorder,
    margin: [0, 0, 0, 0],
    stack: [{
      text: product.priceWithoutTaxBeforeDiscount,
      noWrap: true,
      style: 'body',
      alignment: 'right',
    },
    ...injectItem(!!product.discount, {
      text: product.discount,
      margin: [0, 1, 0, 0],
      noWrap: true,
      style: ['body', 'grey'],
      alignment: 'right',
    }),
    ],
  }),
  total_with_tax: ({ product }: any) => ({
    text: product.priceTotalTax,
    margin: [0, 0, 0, 1],
    noWrap: true,
    style: 'body',
    border: bottomBorder,
    alignment: 'right',
  }),
};

const mappedColumns: any = {
  number: {
    text: '#',
    width: 12,
  },
  tva: {
    width: 24,
  },
  unity: {
    width: 25,
  },
  quantity: {
    width: 35,
  },
  total_without_tax: {
    width: 20,
  },
  total_with_tax: {
    width: 30,
  },
  unit_price_without_tax: {
    width: 36,
  },
};

const checkColumns = [
  'total_without_tax',
  'unity',
  'total_with_tax',
  'unit_price_without_tax',
];
const checkColumnsMapping: any = {
  unity: 'productUnit', total_without_tax: 'priceWithoutTaxBeforeDiscount', total_with_tax: 'priceWithoutTax', unit_price_without_tax: 'unitPrice',
};

const getDesiredWidth = (columns: any[], products: any[], width: number, key: string) => {
  const _key = checkColumnsMapping[key];
  const header = columns.find(column => column.type === key);
  const largest = [header.text.length, ...products.map(product => (product?.[_key] || '').length)].sort((a, b) => b - a)[0];
  const desiredWidth = Math.round((50 * largest) / 12);
  return desiredWidth < width ? width : desiredWidth;
};

export const lines = (columns: { type: string; text: string }[], products: any[], options: BillingDocumentPDFData) => {
  const { template } = options;
  const processCol = columns.map((col) => {
    if (mappedColumns[col.type]) {
      return {
        ...col,
        ...mappedColumns[col.type],
        ...(checkColumns.includes(col.type)
          ? {
              width: getDesiredWidth(columns, products, mappedColumns[col.type].width, col.type),
            }
          : {}),
      };
    }
    return {
      ...col,
      alignment: 'left',
      width: '*',
    };
  });

  const productLines = [];
  const _products = Array.from(products).sort((a, b) => a.index - b.index);
  for (let i = 0; i < _products.length; i += 1) {
    const product = _products[i];
    productLines.push(columns.map(({ type }) => mappedLines[type]?.({
      product, options, i,
    }) || {}));
  }
  return [
    {
      layout: {
        defaultBorder: false,
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor(i: number) {
          if (i === productLines.length + 1 && template === BillingTemplate.TEMPLATE_3) {
            return options.style?.color?.primary || '#0175EB';
          }
          if (i > 1) {
            return template !== BillingTemplate.TEMPLATE_2 ? '#e7e7f3' : '#ffffff';
          }
          if (i === 1 && template === BillingTemplate.TEMPLATE_3) {
            return options.style?.color?.primary || '#0175EB';
          }
          return '#ffffff';
        },
        vLineColor() {
          return '#fff';
        },
        hLineStyle(i: number) {
          if (i === 0) {
            return {
              style: 'primary',
            };
          }
          return null;
        },
        paddingLeft() {
          return 10;
        },
        paddingRight() {
          return 10;
        },
        paddingTop(i: number) {
          return i > 0 ? 5 : 2;
        },
        paddingBottom(i: number) {
          return i > 0 ? 5 : 2;
        },
        fillColor(rowIndex: number) {
          if (template === BillingTemplate.TEMPLATE_1 || (template === BillingTemplate.TEMPLATE_3 && rowIndex === 0)) {
            return '#fff';
          }
          return rowIndex % 2 === 1 ? '#fff' : '#F6F7FD';
        },
      },
      table: {
        headerRows: 1,
        dontBreakRows: true,
        widths: processCol.map(({ width }) => width),
        body: [
          [
            ...columns.map(({ text }, i) => ({
              text,
              alignment: processCol[i].alignment || 'center',
              bold: true,
              noWrap: true,
              ...(template === BillingTemplate.TEMPLATE_3
                ? {
                    style: 'primary',
                    border: bottomBorder,
                  }
                : {
                    color: 'white',
                    style: 'fillPrimary',
                    border: i < columns.length - 1 ? [false, false, true, false] : [false, false, false, false],
                  }),
              fontSize: 9,
              margin: [0, 4, 0, 4],
              textTransform: 'uppercase',
            })),
          ],
          ...productLines,
        ],
      },
    },
    '\n',
  ];
};
