import { GuideLevelItem, GuideStepItem } from '@abby/core-legacy'

export const configBuilderGroupB = (getters: any, rootGetters: any, xpm: any, options: any) => [
  {
    title: 'Facturez en quelques clics',
    duration: 'Environ 8 min',
    level: GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY,
    iconUrl: '/onboarding/guide/levels/facturation.png',
    steps: [
      {
        step: GuideStepItem.CREATE_YOUR_FIRST_DOCUMENT,
        title: 'Créez votre premier document',
        content: `
        Pour commencer avec la facturation, créez votre premier document en quelques étapes simples. Saisissez les informations de votre client, ajoutez les produits ou services fournis, et générez un document professionnel en un rien de temps. 
        <br><br>
        Cette étape vous permettra de gérer facilement vos transactions et de maintenir une comptabilité précise.
        `,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/facture.png',
        videoUrl: '',
        action: {
          text: 'Créer mon premier document',
          icon: '',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.ACTIVATE_ONLINE_PAYMENT_OF_YOUR_INVOICE,
        title: 'Activez le paiement en ligne',
        content: 'Accélérez et simplifiez vos paiements ! En activant le paiement en ligne, un lien vers un portail client est généré, permettant à vos clients de régler par carte, Apple Pay ou Google Pay. Vous récupérez ensuite votre rémunération par virement bancaire.',
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/paiement-en-ligne.png',
        videoUrl: '',
        action: {
          text: 'Activer le paiement en ligne',
          icon: 'toggle_off',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.IMPORT_YOUR_CLIENTS_AND_INVOICES,
        title: 'Importez vos données',
        content: 'Déjà un historique ? Importez facilement vos factures, clients et articles sur Abby. <br><br>Si vous avez des soucis lors de l’import, notre support est là pour vous aider !',
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/client.png',
        videoUrl: '',
        action: {
          text: 'Importer mes données',
          icon: 'upload',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.DISABLE_TEST_MODE,
        title: 'Désactivez le mode test',
        content: `
        Pour assurer la conformité avec la loi en cas de contrôle, désactivez le mode test de l'application. 
        <br><br>
        Cela permet de commencer à attribuer des numéros uniques aux factures dans une séquence chronologique continue, garantissant que chaque transaction est correctement enregistrée et suivie. 
        `,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/test-mode.png',
        videoUrl: '',
        action: {
          text: 'Désactiver le mode test',
          icon: 'toggle_off',
          iconDirection: 'left',
        },
      },
    ],
  },
  {
    title: 'Laissez nous faire la comptabilité à votre place',
    level: GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY,
    duration: 'Environ 5 min',
    iconUrl: '/onboarding/guide/levels/comptabilite.png',
    steps: [
      {
        isDisplayable: !!rootGetters['company/isEI'],
        step: GuideStepItem.CONNECT_YOUR_URSSAF_ACCOUNT,
        title: 'Connectez votre compte Urssaf',
        iconUrl: '',
        content: 'Votre comptabilité se fait automatiquement ! Connectez votre compte Urssaf à Abby pour déclarer et payer vos cotisations en quelques clics. Abby est partenaire certifié Urssaf, et vous pouvez vérifier vos déclarations à tout moment sur le site officiel.',
        imageUrl: '/onboarding/guide/steps/urssaf.png',
        videoUrl: '',
        action: {
          text: 'Connecter mon compte urssaf',
          icon: 'sensors',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: !!rootGetters['company/isEI'],
        step: GuideStepItem.DISCOVER_URSSAF_SPACE,
        title: 'Estimez et déclarez votre URSSAF',
        iconUrl: '',
        content: `Simplifiez vos obligations avec notre fonctionnalité d'estimation et de déclaration URSSAF. 
        <br><br>
        Grâce à notre partenariat officiel, estimez et déclarez vos cotisations sociales directement dans Abby, pour rester conforme et gagner du temps pour développer votre entreprise.`,
        imageUrl: '/onboarding/guide/steps/urssaf.png',
        videoUrl: '',
        action: {
          text: 'Découvrir l\'espace URSSAF',
          icon: 'sensors',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.CONNECT_YOUR_BANK_ACCOUNT,
        title: 'Connectez votre compte bancaire',
        iconUrl: '',
        content: `Envie de mieux suivre vos dépenses et revenus ? Synchronisez votre compte bancaire sur Abby pour visualiser et catégoriser vos transactions, assurant un suivi précis de votre trésorerie.
          <br><br>
          Connecté à plus de 300 banques, Abby garantit la sécurité de vos données, sans possibilité d'effectuer de paiements ou virements.`,
        imageUrl: '/onboarding/guide/steps/connexion-bancaire.png',
        videoUrl: '',
        action: {
          text: 'Connecter un compte',
          icon: 'toggle_off',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.ANNOTATE_YOUR_FIRST_TRANSACTION,
        title: 'Annotez votre première transaction',
        iconUrl: '',
        content: `Catégorisez facilement vos dépenses et revenus pour mieux suivre votre trésorerie et prendre de meilleures décisions.
        <br><br>
        Grâce à l'IA, Abby vous propose des catégories automatiques et apprend de vos habitudes pour des catégorisations futures simplifiées.`,
        imageUrl: '/onboarding/guide/steps/annoter.png',
        videoUrl: '',
        action: {
          text: 'Annoter une transaction',
          icon: 'announcement',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.DISCOVER_STATISTICS_SPACE,
        title: 'Accédez à des statistiques détaillées sur votre activité',
        iconUrl: '',
        content: `
        Optimisez la gestion de votre entreprise en accédant à des statistiques détaillées sur votre activité directement dans Abby. Analysez vos performances financières, identifiez les tendances et prenez des décisions éclairées grâce à des rapports clairs et précis. 
        <br><br>
        Cette fonctionnalité vous permet de suivre votre progression, d'ajuster vos stratégies et de favoriser la croissance de votre entreprise en toute simplicité.
        `,
        imageUrl: '/onboarding/guide/steps/statistiques.png',
        videoUrl: '',
        action: {
          text: 'Découvrir la page analyse',
          icon: 'sensors',
          iconDirection: 'left',
        },
      },

    ],
  },
  {
    title: 'Organisez votre travail',
    level: GuideLevelItem.GET_ORGANIZE_EASILY,
    duration: 'Environ 2 min',
    iconUrl: '/onboarding/guide/levels/organisation.png',
    steps: [
      {
        step: GuideStepItem.DISCOVER_PRODUCTIVITY_PRODUCTS,
        title: 'Découvrez les outils de productivité',
        iconUrl: '',
        content: `
        Améliorez votre efficacité en explorant les outils de productivité disponibles dans Abby. Gérez vos tâches, planifiez vos projets et optimisez votre temps avec des fonctionnalités conçues pour simplifier votre travail quotidien. 
        <br><br>
        Ces outils vous aident à rester organisé, à mieux prioriser vos activités et à maximiser votre productivité, vous permettant de vous concentrer sur l'essentiel.
        `,
        imageUrl: '/onboarding/guide/steps/productivite.png',
        videoUrl: '',
        action: {
          text: 'Découvrir les outils de productivité',
          iconDirection: 'left',
        },
      },

    ],
  },
]
