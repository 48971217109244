import { BillingType, BillingLocale } from '@abby/shared'
import { useContext } from '@nuxtjs/composition-api'
import { PlanFeature } from '@abby/core-legacy'
import { useOpenMigrateSAPArticlesModal } from './useOpenMigrateSAPArticlesModal'
import { BillingAction } from '~/services/billing/_common/enums/BillingAction.enum'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useOpenBillingFrequencyModal } from '~/composables/billing/_common/action/useOpenBillingFrequencyModal'
import { useMarkAsNotSigned } from '~/composables/billing/_common/action/useMarkAsNotSigned'
import { useMarkAsSigned } from '~/composables/billing/_common/action/useMarkAsSigned'
import { useActivateOnlineSignature } from '~/composables/billing/_common/action/useActivateOnlineSignature'
import { useOpenBillingDetailPanel } from '~/composables/billing/_common/action/useOpenBillingDetailPanel'
import { useOpenBillingEditionModal } from '~/composables/billing/_common/action/useOpenBillingEditionModal'
import { useDownloadBilling } from '~/composables/billing/_common/action/useDownloadBilling'
import { useOpenSendBillingByEmailModal } from '~/composables/billing/_common/action/useOpenSendBillingByEmailModal'
import { useOpenBillingMarkAsPaidModal } from '~/composables/billing/_common/action/useOpenBillingMarkAsPaidModal'
import { useMarkAsUnpaid } from '~/composables/billing/_common/action/useMarkAsUnpaid'
import { useMarkAsRefused } from '~/composables/billing/_common/action/useMarkAsRefused'
import { useMarkAsNotRefused } from '~/composables/billing/_common/action/useMarkAsNotRefused'
import { useMarkAsFinalized } from '~/composables/billing/_common/action/useMarkAsFinalized'
import { useMarkAsFinalizedAndSend } from '~/composables/billing/_common/action/useMarkAsFinalizedAndSend'
import { useOpenDuplicateBillingModal } from '~/composables/billing/_common/action/useOpenDuplicateBillingModal'
import { useOpenBillingTitleModal } from '~/composables/billing/_common/action/useOpenBillingTitleModal'
import { useOpenFinalInvoiceCreationModal } from '~/composables/billing/_common/action/useOpenFinalInvoiceCreationModal'
import { useRemoveBilling } from '~/composables/billing/_common/action/useRemoveBilling'
import { useOpenAdvanceCreationModal } from '~/composables/billing/_common/action/useOpenAdvanceCreationModal'
import { useOpenAssetCreationModal } from '~/composables/billing/_common/action/useOpenAssetCreationModal'
import { useDownloadSignatureProof } from '~/composables/billing/_common/action/useDownloadSignatureProof'
import { useOpenBillingReminderModal } from '~/composables/billing/_common/action/useOpenBillingReminderModal'
import { useOpenCancelBillingModal } from '~/composables/billing/_common/action/useOpenCancelBillingModal'
import { useActivateOnlinePayment } from '~/composables/billing/_common/action/useActivateOnlinePayment'
import { useOpenOpportunityPanel } from '~/composables/billing/_common/action/useOpenOpportunityPanel'
import { useUnlinkBilling } from '~/composables/billing/_common/action/useUnlinkBilling'
import { useRemoveBillingAdmin } from '~/composables/billing/_common/action/useRemoveBillingAdmin'
import { useRegenerateBilling } from '~/composables/billing/_common/action/useRegenerateBilling'
import { useFixCustomerAddress } from '~/composables/billing/client/useFixCustomerAddress'
import { useOpenContactDetailsSidePanel } from '~/composables/client/contact/action/useOpenContactDetailsSidePanel'
import {
  useOpenOrganizationDetailsSidePanel,
} from '~/composables/client/organization/action/useOpenOrganizationDetailsSidePanel'
import { useFetchBillingUrssafSyncStatus } from '~/composables/billing/_common/action/useFetchBillingUrssafSyncStatus'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'

export const useHandleBillingAction = () => {
  const toasterManager = useToasterManager()
  const openBillingDetailPanel = useOpenBillingDetailPanel()
  const billingRepository = useBillingRepository()
  const billingConfiguration = billingRepository.fetchConfiguration()
  const planManager = usePlanManager()
  const { $clipboard } = useContext()

  const openBillingFrequencyModal = useOpenBillingFrequencyModal()
  const markAsNotSigned = useMarkAsNotSigned()
  const markAsSigned = useMarkAsSigned()
  const activateOnlineSignature = useActivateOnlineSignature()
  const openBillingEditionModal = useOpenBillingEditionModal()
  const downloadBilling = useDownloadBilling()
  const openSendBillingByEmailModal = useOpenSendBillingByEmailModal()
  const openBillingMarkAsPaidModal = useOpenBillingMarkAsPaidModal()
  const markAsUnpaid = useMarkAsUnpaid()
  const markAsRefused = useMarkAsRefused()
  const markAsNotRefused = useMarkAsNotRefused()
  const markAsFinalized = useMarkAsFinalized()
  const markAsFinalizedAndSend = useMarkAsFinalizedAndSend()
  const openDuplicateBillingModal = useOpenDuplicateBillingModal()
  const openBillingTitleModal = useOpenBillingTitleModal()
  const openFinalInvoiceCreationModal = useOpenFinalInvoiceCreationModal()
  const removeBilling = useRemoveBilling()
  const openAdvanceCreationModal = useOpenAdvanceCreationModal()
  const openAssetCreationModal = useOpenAssetCreationModal()
  const downloadSignatureProof = useDownloadSignatureProof()
  const openBillingReminderModal = useOpenBillingReminderModal()
  const openCancelBillingModal = useOpenCancelBillingModal()
  const activateOnlinePayment = useActivateOnlinePayment()
  const openOpportunityPanel = useOpenOpportunityPanel()
  const unlinkBilling = useUnlinkBilling()
  const removeBillingAdmin = useRemoveBillingAdmin()
  const regenerateBilling = useRegenerateBilling()
  const fixCustomerAddress = useFixCustomerAddress()
  const openContactDetailsSidePanel = useOpenContactDetailsSidePanel()
  const openOrganizationDetailsSidePanel = useOpenOrganizationDetailsSidePanel()
  const fetchBillingUrssafSyncStatus = useFetchBillingUrssafSyncStatus()
  const openMigrateSAPArticlesModal = useOpenMigrateSAPArticlesModal()

  const copyBillingConfigurationId = async () => {
    await $clipboard.copy(billingConfiguration.value.id)
    toasterManager.success({
      message: 'Le message a été copié dans le presse-papier',
    })
  }

  const copyBillingId = async (id: string) => {
    await $clipboard.copy(`_id: ObjectId("${id}")`)
    toasterManager.success({
      message: 'Le message a été copié dans le presse-papier',
    })
  }

  const copyBillingCustomerPortalLink = async (billing: BillingItem) => {
    const url = `${process.env.baseAppUrl}/clientPortal?id=${billing.customer.legacyId}&billing=${billing.id}&token=${billing.customer.token}`
    await $clipboard.copy(url)
    toasterManager.success({
      message: 'Le lien a été copié dans le presse-papier',
    })
  }

  const copyTiersPrestationError = async (billing: BillingItem) => {
    const message = `Bonjour, \n
        Nous avons une erreur ERR_PARTICULIER_INCONNU avec "idClient" : "${billing.customer.tiersPrestation?.idClient}".\nLe client ID de l’OSP est : clientId" : "${billingConfiguration.value.tiersPrestationCredentials?.clientId}",\n
        Le particulier a correctement activé son compte.
        \nMerci de résoudre le problème.
        \nBien cordialement`
    await $clipboard.copy(message)
    toasterManager.success({
      message: 'Le message a été copié dans le presse-papier',
    })
  }

  const archiveBilling = async (billing: BillingItem) => {
    if (!planManager.hasAccessToFeature(PlanFeature.BILLING_ARCHIVE_DOCUMENTS)) {
      return planManager.openPlansModal(PlanFeature.BILLING_ARCHIVE_DOCUMENTS)
    }
    await billingRepository.archiveBilling(billing)
  }

  const actions: {
    [key in BillingAction]: {
      run: (billing: BillingItem, options?: any) => Promise<any> | any;
    }
  } = {
    [BillingAction.GET_SIGNATURE_LINK]: {
      run: billing => copyBillingCustomerPortalLink(billing),
    },
    [BillingAction.MANAGE_RECURRING]: {
      run: billing => openBillingFrequencyModal(billing),
    },
    [BillingAction.GET_PAYMENT_LINK]: {
      run: billing => copyBillingCustomerPortalLink(billing),
    },
    [BillingAction.MARK_AS_NOT_SIGNED]: {
      run: billing => markAsNotSigned(billing),
    },
    [BillingAction.MARK_AS_SIGNED]: {
      run: billing => markAsSigned(billing),
    },
    [BillingAction.ACTIVATE_ONLINE_SIGNATURE]: {
      run: billing => activateOnlineSignature(billing),
    },
    [BillingAction.ARCHIVE]: {
      run: billing => archiveBilling(billing),
    },
    [BillingAction.UNARCHIVE]: {
      run: billing => billingRepository.unarchiveBilling(billing),
    },
    [BillingAction.VIEW_DETAILS]: {
      run: billing => openBillingDetailPanel(billing.id),
    },
    [BillingAction.EDIT]: {
      run: billing => openBillingEditionModal(billing),
    },
    [BillingAction.DOWNLOAD]: {
      run: billing => downloadBilling(billing),
    },
    [BillingAction.SEND]: {
      run: billing => openSendBillingByEmailModal(billing),
    },
    [BillingAction.MARK_AS_PAID]: {
      run: billing => openBillingMarkAsPaidModal({ billingItem: billing }),
    },
    [BillingAction.MARK_AS_PAID_ASSET]: { // only for asset
      run: billing => openBillingMarkAsPaidModal({ billingItem: billing }),
    },
    [BillingAction.MARK_AS_UNPAID]: {
      run: billing => markAsUnpaid(billing),
    },
    [BillingAction.MARK_AS_REFUSED]: {
      run: billing => markAsRefused(billing),
    },
    [BillingAction.MARK_AS_NOT_REFUSED]: {
      run: billing => markAsNotRefused(billing),
    },
    [BillingAction.FINALIZE]: {
      run: billing => markAsFinalized(billing),
    },
    [BillingAction.FINALIZE_AND_SEND]: {
      run: billing => markAsFinalizedAndSend(billing),
    },
    [BillingAction.DUPLICATE_TO_INVOICE]: {
      run: billing => openDuplicateBillingModal(billing, BillingType.INVOICE),
    },
    [BillingAction.DUPLICATE_TO_ESTIMATE]: {
      run: billing => openDuplicateBillingModal(billing, BillingType.ESTIMATE),
    },
    [BillingAction.DUPLICATE_TO_PURCHASE_ORDER]: {
      run: billing => openDuplicateBillingModal(billing, BillingType.PURCHASE_ORDER),
    },
    [BillingAction.EDIT_TITLE]: {
      run: billing => openBillingTitleModal(billing),
    },
    [BillingAction.CREATE_FINAL_INVOICE]: {
      run: billing => openFinalInvoiceCreationModal(billing),
    },
    [BillingAction.DELETE]: {
      run: billing => removeBilling(billing),
    },
    [BillingAction.VIEW_CONTACT]: {
      run: async (billing) => {
        if (billing.customer.contactId) {
          await openContactDetailsSidePanel(billing.customer.contactId)
        }
      },
    },
    [BillingAction.VIEW_ORGANIZATION]: {
      run: async (billing) => {
        if (billing.customer.organizationId) {
          await openOrganizationDetailsSidePanel(billing.customer.organizationId)
        }
      },
    },
    [BillingAction.CREATE_ADVANCE]: {
      run: billing => openAdvanceCreationModal(billing),
    },
    [BillingAction.CREATE_ASSET]: {
      run: billing => openAssetCreationModal(billing),
    },
    [BillingAction.DOWNLOAD_SIGNATURE_PROOF]: {
      run: billing => downloadSignatureProof(billing),
    },
    [BillingAction.MANAGE_REMINDER]: {
      run: billing => openBillingReminderModal(billing),
    },
    [BillingAction.CANCEL]: {
      run: billing => openCancelBillingModal(billing),
    },
    [BillingAction.ACTIVATE_ONLINE_PAYMENT]: {
      run: billing => activateOnlinePayment(billing),
    },
    [BillingAction.CHECK_URSSAF_TP_STATUT]: {
      run: billing => fetchBillingUrssafSyncStatus(billing),
    },
    [BillingAction.DOWNLOAD_FRENCH_LOCALE]: {
      // @ts-ignore
      run: billing => downloadBilling(billing, { locale: BillingLocale.FR }),
    },
    [BillingAction.VIEW_OPPORTUNITY]: {
      run: billing => openOpportunityPanel(billing),
    },
    // ADMIN ACTIONS
    [BillingAction.ADMIN_COPY_DOCUMENT_ID]: {
      run: billing => copyBillingId(billing.id),
    },
    [BillingAction.ADMIN_COPY_BILLING_CONFIGURATION_ID]: {
      run: (_: BillingItem) => copyBillingConfigurationId(),
    },
    [BillingAction.ADMIN_UNLINK_FROM_CREATE_FROM]: {
      run: billing => unlinkBilling(billing),
    },
    [BillingAction.ADMIN_DELETE_DOCUMENT]: {
      run: billing => removeBillingAdmin(billing),
    },
    [BillingAction.ADMIN_COPY_ISSUE_PARTICULIER_INCONNU]: {
      run: billing => copyTiersPrestationError(billing),
    },
    [BillingAction.ADMIN_REGENERATE_DOCUMENT]: {
      run: billing => regenerateBilling(billing),
    },
    [BillingAction.ADMIN_FIX_CUSTOMER_ADDRESS]: {
      run: billing => fixCustomerAddress(billing),
    },
    [BillingAction.ADMIN_RESOLVE_NUMBER]: {
      run: billing => billingRepository.regenerateNumber(billing),
    },
    [BillingAction.MIGRATE_SAP_PRODUCTS]: {
      run: billing => openMigrateSAPArticlesModal(billing),
    },
  }

  return async ({ action, billing, options }: { action: BillingAction, billing: BillingItem, options?: any }) => {
    try {
      await actions[action].run(billing, options)
    } catch (error: any) {
      toasterManager.autoError(error)
    }
  }

}
