import { Context } from '@nuxt/types'

/** Basic shape for a locally-stored token. */
interface TokenInfo {
  token: string | null
  refreshToken: string | null
  expirationTime: number | null
}

/**
 * Looks in localStorage for the Firebase token and returns
 * { token, refreshToken, expirationTime } if found, or nulls otherwise.
 */
export const retrieveToken = (): TokenInfo => {
  const tokenKey = Object.entries(localStorage).find(([key]) =>
    key.includes('firebase:authUser:'),
  )

  if (!tokenKey) {
    return {
      token: null,
      refreshToken: null,
      expirationTime: null,
    }
  }

  const { stsTokenManager } = JSON.parse(localStorage[tokenKey[0]])
  return {
    token: stsTokenManager.accessToken,
    refreshToken: stsTokenManager.refreshToken,
    expirationTime: stsTokenManager.expirationTime,
  }
}

/**
 * Performs the actual token refresh logic, either by using
 * Firebase's `currentUser.getIdToken(true)` or by calling the
 * Google `securetoken.googleapis.com` endpoint with the refresh token.
 */
export const refreshToken = async (
  $fire: any,
  hasFirebaseUser: boolean,
  store: any,
): Promise<string> => {
  const expirationTime = Date.now() + 3600 * 1000
  if (hasFirebaseUser) {
    // Refresh via Firebase SDK
    const token = await $fire.auth.currentUser.getIdToken(true)
    await store.dispatch('auth/setExpirationTime', expirationTime, { root: true })
    return token
  }

  // Otherwise, call Google's Secure Token endpoint manually
  const { refreshToken } = retrieveToken()
  const apiKey = $fire.auth.config.apiKey

  if (!refreshToken) {
    throw new Error('No refresh token available')
  }

  // Update store with whatever expirationTime we have (optional)
  await store.dispatch('auth/setExpirationTime', expirationTime, { root: true })

  const response = await fetch('https://securetoken.googleapis.com/v1/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      key: apiKey,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  })

  if (!response.ok) {
    throw new Error('Token refresh failed')
  }

  const data = await response.json()
  return data.id_token
}

/**
 * A singleton manager for tracking the current in-memory token, plus
 * any in-progress refresh. Only one refresh can happen at a time.
 */
export class TokenManager {
  private static instance: TokenManager

  /** The current access token in memory. */
  private currentToken: string | null = null

  /**
   * If a refresh call is in progress, we store the resulting Promise here.
   * That way, multiple parallel requests can share the *same* refresh
   * instead of triggering multiple refresh calls.
   */
  private refreshPromise: Promise<string> | null = null

  private refreshTimeout: ReturnType<typeof setTimeout> | null = null

  private constructor () {
    // Initialize from localStorage
    const { token } = retrieveToken()
    this.currentToken = token
  }

  /** Retrieve or create the singleton. */
  public static getInstance (): TokenManager {
    // If it’s already stored on window, reuse it
    // @ts-ignore
    if (window.__globalTokenManager__) {
      // @ts-ignore
      return window.__globalTokenManager__
    }
    // Otherwise, create and store it
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager()
    }
    // @ts-ignore
    window.__globalTokenManager__ = TokenManager.instance
    return TokenManager.instance
  }

  /** Returns the current token from memory, falling back to localStorage if needed. */
  getToken (): string | null {
    if (!this.currentToken) {
      const { token } = retrieveToken()
      this.currentToken = token
    }
    return this.currentToken
  }

  /** Manually sets the current in-memory token. */
  setToken (token: string | null): void {
    this.currentToken = token
  }

  /**
   * Returns any existing refresh Promise (if one is in progress).
   * Mostly for debugging or advanced usage.
   */
  getRefreshPromise (): Promise<string> | null {
    return this.refreshPromise
  }

  /** Internally sets the refresh Promise. */
  setRefreshPromise (promise: Promise<string> | null): void {
    this.refreshPromise = promise
  }

  /** Clears any existing timeout. */
  clearRefreshTimeout (): void {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  /** Optionally sets a timeout to clear the stored refresh promise after some delay. */
  setRefreshTimeout (callback: () => void, delay: number): void {
    this.clearRefreshTimeout()
    this.refreshTimeout = setTimeout(callback, delay)
  }
}

/**
 * Main function for hooking up authentication in your Nuxt app.
 * Exports `logout` and `handleTokenRefresh` that the rest of your code can use.
 */
export const configureAuth = (context: Context) => {
  const tokenManager = TokenManager.getInstance()

  /**
   * Logs the user out (both in store and in-memory),
   * clears timeouts, etc.
   */
  const logout = async () => {
    tokenManager.setToken(null)
    tokenManager.setRefreshPromise(null)
    tokenManager.clearRefreshTimeout()
    await context.store.dispatch('auth/logout', null, { root: true })
  }

  /**
   * Checks if the token is expired, and if so, refreshes it (with concurrency protection).
   * If multiple calls arrive simultaneously, they wait for the same refresh promise.
   */
  const handleTokenRefresh = async (): Promise<string | null> => {
    // 1) Gather info from store and localStorage
    const expirationTime = context.store.getters['auth/expirationTime'] || 0
    const storedToken = retrieveToken()
    const hasUser = !!context.$fire.auth.currentUser || !!storedToken.refreshToken
    const hasFirebaseUser = !!context.$fire.auth.currentUser

    // If we have a token in storage but not in memory, set it
    if (!tokenManager.getToken() && storedToken.token) {
      tokenManager.setToken(storedToken.token)
    }

    // 2) If there's no user at all, do nothing
    if (!hasUser) {
      return tokenManager.getToken() // Will be null in most cases
    }

    // 3) If the token is expired according to store, refresh it
    if (expirationTime < (Date.now() + 300_000)) {
      try {
        // Is a refresh already in progress? If yes, wait for it
        if (!tokenManager.getRefreshPromise()) {
          // Not in progress => start a new refresh
          const refreshOperation = (async () => {
            const newToken = await refreshToken(context.$fire, hasFirebaseUser, context.store)
            tokenManager.setToken(newToken)
            return newToken
          })()

          tokenManager.setRefreshPromise(refreshOperation)
          // auto-clear the stored promise after 10s so it doesn't linger
          tokenManager.setRefreshTimeout(() => tokenManager.setRefreshPromise(null), 10000)
        }

        // Wait for the in-progress or newly-started refresh to complete
        return await tokenManager.getRefreshPromise()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Token refresh failed:', error)
        await logout()
        throw error
      } finally {
        // Once this refresh attempt completes (success or fail), clear the promise
        tokenManager.setRefreshPromise(null)
      }
    }

    // Otherwise, the token is still valid. Return it from memory.
    return tokenManager.getToken()
  }

  return {
    logout,
    handleTokenRefresh,
    tokenManager,
  }
}
