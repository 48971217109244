import { OrganizationItem } from '~/services/client/organization/entities/OrganizationItem.entity'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'

export const useRemoveOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const busManager = useBusManager()
  const alertManager = useAlertManager()
  const confirmManager = useConfirmManager()

  return async (organization: OrganizationItem) => {
    const confirm = await confirmManager.ask({
      title: 'Supprimer une organisation',
      content: 'Cette action est irréversible. Voulez vous vraiment supprimer cette organisation ?',
      confirmText: 'Supprimer',
      icon: 'remove',
      confirmButton: { color: 'error', depressed: true },
    })
    if (!confirm) {
      return false
    }
    await organizationRepository.removeOrganization(organization.id)
    busManager.emit('organizationRemoved', organization)
    alertManager.success('L\'organisation a été supprimée avec succès.')
  }
}
