var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AbbyMenu,{attrs:{"model-value":_setup.selectorOpened,"min-width":!_setup.$vuetify.breakpoint.xsOnly ? '500px' : '200px',"max-width":"500px","max-height":"400px","open-on-click":"","left":"","close-on-content-click":false},on:{"update:model-value":_setup.setSelectorOpened},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"cash-action",on:{"click":() => {}}},'div',attrs,false),on),[_vm._v("\n      Associer une transaction bancaire\n    ")])]}}])},[_vm._v(" "),_c('div',[_c(_setup.AbbyInputText,{ref:"transactionInputSearch",staticClass:"tw-py-2 mt-0 transaction-input__search",attrs:{"model-value":_setup.search,"autofocus":"","hide-details":"","placeholder":!_setup.$vuetify.breakpoint.xsOnly ? 'Rechercher une transaction par nom, montant, date...' : 'Rechercher une transaction...'},on:{"update:model-value":function($event){_setup.search = $event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"tw-pl-1"},[_vm._v("\n          search\n        ")])]},proxy:true}])}),_vm._v(" "),_c(_setup.AbbyDataTable,{staticClass:"tw-overflow-hidden tw-rounded-none",attrs:{"data":_setup.transactions,"loading":_setup.isTransactionLoading,"error":_setup.isTransactionError,"rounded":false,"columns":[
        {
          name: 'Désignation',
          alignment: 'left',
        }, {
          name: 'Montant',
          alignment: 'right',
        },
      ]},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(transaction){return _c('tr',{key:transaction.id,staticClass:"tw-cursor-pointer",on:{"click":function($event){return _setup.addTransactionToAccountingBilling(transaction)}}},[_c('td',{staticClass:"tw-py-2 tw-pl-4 tw-leading-5"},[_c('span',{staticClass:"as-label-3"},[_vm._v(_vm._s(transaction.label))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"as-caption tw-text-grey-base tw-font-medium"},[_vm._v(_vm._s(_setup.$dayjs(transaction.valueDate).format('DD/MM/YYYY')))])]),_vm._v(" "),_c('td',{staticClass:"tw-pr-4 tw-text-right"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-justify-center tw-gap-1"},[_c(_setup.AbbyAmountLabel,{attrs:{"type":"cents","size":"small","amount":transaction.amount.value}}),_vm._v(" "),(transaction.remainingAmount)?_c(_setup.AbbyAmountLabel,{attrs:{"type":"cents","size":"small","amount":transaction.remainingAmount.value,"color":"grey-base","bg-color":"background-base","suffix":"non encaissé"}}):_vm._e()],1)])])})}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data-text"},[_vm._v("\n          Aucune transaction bancaire trouvée\n        ")])]},proxy:true},{key:"error",fn:function(){return [_c(_setup.AbbyErrorDataCard,{attrs:{"title":"Une erreur est survenue","description":"Nous n'avons pas pu charger vos transactions. Veuillez réessayer ou contacter le support si le problème persiste.","hide-action":false}})]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }