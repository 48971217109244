import { BillingAccountingForm } from '~/services/billing/_common/forms/BillingAccounting.form'

export const isAmountInvalid = (t: BillingAccountingForm) => {
  if (!t.remainingAmount) {
    return false
  }
  if (t.alreadySaved && t.initialAmount) {
    return t.amount.value - t.initialAmount.value > t.remainingAmount.value
  }
  return t.amount.value > t.remainingAmount.value
}
