export const countryItems = [
  { text: 'France', value: 'fr' },
  { text: 'Italie', value: 'it' },
  { text: 'Allemagne', value: 'de' },
  { text: 'Espagne', value: 'es' },
  { text: 'Portugal', value: 'pt' },
  { text: 'Luxembourg', value: 'lu' },
  { text: 'Pays-Bas', value: 'nl' },
  { text: 'Belgique', value: 'be' },
]
