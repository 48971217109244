
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  BillingState,
  BillingType,
  IBilling,
  PaymentMethod,
  paymentMethodsTranslation,
  PaymentRequestStatusUrssafTp,
} from '@abby/core-legacy'

@Component
export default class BillingStateLabel extends Vue {
  @Prop({ required: true })
  value!: IBilling

  @Prop({ default: () => false })
  isTiersPrestation!: boolean

  loading = false

  labelClicked () {
    this.$emit('click')
  }

  get labelConfig () {
    if (!this.isTiersPrestation || this.value.billingState === BillingState.PAID) {
      switch (this.value.billingState) {
      case BillingState.DRAFT:
        return {
          color: 'background',
          textClass: 'tw-text-grey-base',
          text: 'Brouillon',
        }
      case BillingState.FINALIZED:
        if (this.value.dueDate && [BillingType.ADVANCE, BillingType.INVOICE].includes(this.value.billingType) &&
              this.$dayjs(this.value.dueDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).diff(this.$dayjs(), 'day') < 0) {
          return {
            color: 'error lighten-4',
            textClass: 'tw-text-red-base',
            text: 'En retard',
          }
        } else if (this.value.validityDate && [BillingType.PURCHASE_ORDER, BillingType.ESTIMATE].includes(this.value.billingType) &&
              this.$dayjs(this.value.validityDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).diff(this.$dayjs(), 'day') < 0) {
          return {
            color: 'error lighten-4',
            textClass: 'tw-text-red-base',
            text: 'Expiré',
          }
        }
        return {
          color: 'primary lighten-4',
          textClass: 'tw-text-primary-base',
          text: 'Finalisé',
        }
      case BillingState.PAID:
        return {
          color: 'success lighten-4',
          textClass: 'tw-text-secondary-base',
          text: 'Payé',
          tooltip: this.tooltipPaid,
        }
      case BillingState.SIGNED:
        return {
          color: 'success lighten-4',
          textClass: 'tw-text-secondary-base',
          text: this.value.signature && !this.value.signature.canceledAt ? 'Signé en ligne' : 'Signé',
        }
      case BillingState.REFUSED:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Refusé',
        }
      }
    } else if (this.isTiersPrestation) {
      if (this.dateVirementTp) {
        return {
          color: 'success lighten-4',
          textClass: 'tw-text-secondary-base',
          text: 'Virement effectué le',
        }
      }
      switch (this.value.paymentRequest?.statut) {
      case PaymentRequestStatusUrssafTp.INTEGRATED:
        return {
          color: 'background',
          textClass: 'tw-text-grey-base',
          text: 'Reçue',
        }
      case PaymentRequestStatusUrssafTp.WAITING_FOR_VALIDATION:
        return {
          color: 'background',
          textClass: 'tw-text-grey-base',
          text: 'En attente de validation',
        }
      case PaymentRequestStatusUrssafTp.VALIDATED:
        return {
          color: 'primary lighten-4',
          textClass: 'tw-text-primary-base',
          text: 'Validée',
        }
      case PaymentRequestStatusUrssafTp.DEBIT:
        return {
          color: 'success lighten-4',
          textClass: 'tw-text-secondary-base',
          text: 'Prélevée',
        }
      case PaymentRequestStatusUrssafTp.PAYED:
        return {
          color: 'success lighten-4',
          textClass: 'tw-text-secondary-base',
          text: 'Virement effectué le',
        }
      case PaymentRequestStatusUrssafTp.DEBIT_REFUSED:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Prélèvement refusé',
          icon: 'warning',
          iconColor: 'error',
        }
      case PaymentRequestStatusUrssafTp.REFUSED:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Refusée',
          icon: 'warning',
          iconColor: 'error',
        }
      case PaymentRequestStatusUrssafTp.CANCELED:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Annulée',
          icon: 'warning',
          iconColor: 'error',
        }
      case PaymentRequestStatusUrssafTp.ERR_PARTICULIER_INCONNU:
        return {
          color: 'warning lighten-4',
          textClass: 'tw-text-yellow-base',
          text: 'Espace particulier non activé',
          tooltip: 'Votre client ne peut pas recevoir de demande de paiement tant qu\'il n\'a pas activé son compte particulier Urssaf.',
          icon: 'warning',
          iconColor: 'warning',
        }
      case PaymentRequestStatusUrssafTp.ERR_COMPTE_BLOQUE:
        return {
          color: 'warning lighten-4',
          textClass: 'tw-text-yellow-base',
          text: 'Espace particulier bloqué',
          tooltip: 'Votre client ne peut pas recevoir de demande de paiement tant que son compte est bloqué. Il faut qu\'il contact l\'Urssaf pour débloquer la situation.',
          icon: 'warning',
          iconColor: 'warning',
        }
      case PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON:
        return {
          color: 'warning lighten-4',
          textClass: 'tw-text-yellow-base',
          text: 'Perte de suivi du statut',
          tooltip: "L'Urssaf ne nous a pas renvoyé le numéro nous permettant de suivre l'évolution de votre facture. Elle a normalement correctement été envoyé à votre client.",
          icon: 'warning',
          iconColor: 'warning',
        }
      case PaymentRequestStatusUrssafTp.ERR_NBRE_PREST_MAX:
        return {
          color: 'warning lighten-4',
          textClass: 'tw-text-yellow-base',
          text: 'Limite prestations atteinte',
          tooltip: 'Un trop grand nombre de prestations a été déclaré, rapprochez vous de l\'Urssaf pour résoudre la situation.',
          icon: 'warning',
          iconColor: 'warning',
        }
      case PaymentRequestStatusUrssafTp.ERR_PERIODE_EMPLOI_MOIS_NON_UNIQUE:
        return {
          color: 'warning lighten-4',
          textClass: 'tw-text-yellow-base',
          text: 'Dates incorrectes',
          tooltip: 'Les dates de début et fin de prestation ne sont pas correctes. Elles doivent être dans le même mois de la même année. Essayez de modifier les dates de début et fin de prestation.',
          icon: 'warning',
          iconColor: 'warning',
        }
      case PaymentRequestStatusUrssafTp.ERR_LIEN_PARTICULIER_PRESTATAIRE:
        return {
          color: 'warning lighten-4',
          textClass: 'tw-text-yellow-base',
          text: 'Lien particulier incorrect',
          tooltip: 'Votre client est activé mais la date de début de prestation est probablement antérieure à la date d\'activation de votre client. Essayez de modifier les dates de début et fin de prestation.',
          icon: 'warning',
          iconColor: 'warning',
        }
      case PaymentRequestStatusUrssafTp.ERR_FORMAT_VALEUR:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Erreur - Format de la valeur',
          tooltip: 'Vous avez utilisé des montants avec des virgules. Cela provoque un problème d\'arrondi du côté de l\'Urssaf. Veuillez contacter le support Abby.',
          icon: 'warning',
          iconColor: 'error',
        }
      case PaymentRequestStatusUrssafTp.ERR_MNT_PREST_HT_TVA:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Erreur - Montant TVA',
          tooltip: 'Vous avez utilisé des montants avec des virgules. Cela provoque un problème d\'arrondi du côté de l\'Urssaf. Veuillez contacter le support Abby.',
          icon: 'warning',
          iconColor: 'error',
        }
      case PaymentRequestStatusUrssafTp.ERR_MNT_PREST_TTC:
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          text: 'Erreur - Montant TTC',
          tooltip: 'Vous avez utilisé des montants avec des virgules. Cela provoque un problème d\'arrondi du côté de l\'Urssaf. Veuillez contacter le support Abby.',
          icon: 'warning',
          iconColor: 'error',
        }
      default: {
        if (this.value.billingState === BillingState.DRAFT) {
          return {
            color: 'warning lighten-4',
            textClass: 'tw-text-yellow-base',
            text: 'Non finalisé',
          }
        }
        return {
          color: 'error lighten-4',
          textClass: 'tw-text-red-base',
          // @ts-ignore
          text: 'Erreur inconnue',
          icon: 'warning',
          iconColor: 'error',
          // @ts-ignore
          // tooltip: ErrorCodeUrssafTp.find(e => e.code === this.value.paymentRequest?.statut).detail,
        }
      }
      }
    } else if (this.value.billingState === BillingState.DRAFT) {
      return {
        color: 'background',
        textClass: 'tw-text-grey-base',
        text: 'Brouillon',
      }
    } else {
      return {}
    }
  }

  get tooltipPaid () {
    return `Marqué comme payé le ${this.$dayjs(this.value.paidAt).format('DD/MM/YYYY')}.`
  }

  get dateVirementTp () {
    if (!this.value.tiersPrestationIsActivatedForThisBilling) { return }
    if (this.value?.paymentRequest?.dateVirement && this.value.billingState !== BillingState.PAID) {
      return this.value?.paymentRequest?.dateVirement
    }
  }
}
