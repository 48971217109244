
import { Action, Component, Getter, Prop, Ref, Vue } from 'nuxt-property-decorator'
import {
  BillingActivity, BillingActivityEvent,
  billingHasAlreadyHadFinalizedState,
  billingIsSignedOnline,
  BillingState,
  billingTPDateAreValid,
  BillingType,
  Currency,
  ErrorCodeUrssafTp,
  getCompleteTotalAmount,
  IBilling, IBillingConfiguration,
  ICompany,
  IPaymentRequest,
  isFinalizable,
  PaymentMethod,
  PaymentRequestStatusUrssafTp,
  PlanFeature,
  ReadOpportunity, ReadStripeAccount, UpdateBillingConfiguration,
  messageForDisabledAction,
} from '@abby/core-legacy'
import dayjs from 'dayjs'
import BillingStateLabel from '~/components-legacy/cards/BillingStateLabel.vue'
import AbbyLink from '~/components/abby/buttons/AbbyLink.vue'
import BillingPaymentRequest from '~/components-legacy/forms/billingDocument/BillingPaymentRequest.vue'
import BillingLineDesignation from '~/components-legacy/BillingLineDesignation.vue'
import ASActionButtonGroup from '~/components-legacy/inputs/ASActionButtonGroup.vue'
import BillingTableActionMenu from '~/components-legacy/menus/BillingTableActionMenu.vue'
import ASTextField from '~/components-legacy/inputs/ASTextField.vue'

@Component({
  components: {
    ASTextField,
    BillingTableActionMenu,
    ASActionButtonGroup,
    BillingLineDesignation,
    BillingPaymentRequest,
    AbbyLink,
    BillingStateLabel,
  },
})
export default class BillingLine extends Vue {
  @Action('opportunity/openOpportunityDetails') openOpportunityDetails!: (payload: { opportunity: ReadOpportunity }) => Promise<void>;

  @Prop()
  item!: IBilling

  @Prop()
  summaryMode!: boolean

  @Prop()
  singleLineMode!: boolean

  @Prop({ default: false })
  forceSummaryMode!: boolean

  @Prop({ default: false })
  hideFloatingActionMenu!: boolean

  @Prop()
  search!: string

  @Prop({ default: true })
  enableDisplaySolveIssuesButton!: boolean

  @Prop({ default: false })
  hideTpState!: boolean

  @Getter('billingCreation/billingDocumentsLoadingForAction')
  billingDocumentsLoadingForAction!: Array<string>

  @Getter('billing/sidePanelBillingOpened')
  sidePanelBillingOpened!: Array<IBilling>

  @Getter('billing/isAlreadyOpenedOnSidePanel')
  _isAlreadyOpenedOnSidePanel!: (id: string) => boolean

  @Getter('company/company')
  company!: ICompany

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration

  @Getter('billingCreation/isBillingCreationModalOpened')
  isBillingCreationModalOpened!: boolean

  @Getter('billingCreation/isBillingEditionModalOpened')
  isBillingEditionModalOpened!: boolean

  @Getter('billingConfiguration/stripeAccount')
  stripeAccount!: ReadStripeAccount | null

  @Action('billing/openBillingSidePanel')
  openBillingSidePanel!: (payload: IBilling) => Promise<void>;

  @Action('billing/openMarkAsPaidModal')
  openMarkAsPaidModal!: (item: IBilling) => Promise<void>;

  @Action('billing/retrySendBillingUrssaf')
  retrySendBillingUrssaf!: (data: { _id: string, paymentRequest?: IBilling['paymentRequest'] }) => Promise<void>;

  @Action('billingConfiguration/updateBillingConfiguration')
  updateBillingConfiguration!: (payload: UpdateBillingConfiguration) => Promise<void>;

  @Action('billingConfiguration/fetchBillingConfiguration')
  fetchBillingConfiguration!: () => Promise<void>;

  @Action('billingConfiguration/updateNovaNumber')
  _updateNovaNumber!: (novaNumber: string) => Promise<void>;

  @Ref('actionMenu')
  actionMenu!: BillingTableActionMenu & HTMLElement

  serverItem: IBilling | null = null

  isMenuOpened = false
  loading = false
  loadingNovaNumber = false
  expanded = false
  isEditTPEmailModalOpened = false
  pointerFine = true // utilisateur utilise une souris ou non

  paymentRequest: IPaymentRequest | null = null
  isTpDateValidated: boolean = true
  novaNumber: string | null = null

  showActionMenu (hover: boolean) {
    return !this.isModalOpened &&
      (
        !hover ||
        this.$vuetify.breakpoint.smAndDown ||
        (hover && this.summaryMode) ||
        (!this.$vuetify.breakpoint.smAndDown && this.forceSummaryMode)
      )
  }

  showFloatingActionButtons (hover: boolean) {
    if (this.hideFloatingActionMenu) { return false }
    return !this.isModalOpened &&
      (hover || this.isMenuOpened) &&
      !this.$vuetify.breakpoint.smAndDown &&
      !this.summaryMode &&
      this.pointerFine
  }

  displayActivity (item: BillingActivity): any {
    if (item.action === BillingActivityEvent.REMINDER_SENT) {
      let activityInformations: string = 'Un email de relance a été envoyé'
      if (item.data.isTest) {
        activityInformations += '<strong> en mode test</strong>'
      }
      if (item.data.to.length) {
        activityInformations += ` à l'e-mail ${item.data.to.map((to: { email: string }) => `${to.email}`).join(', ')}`
      }
      if (item.data.from) {
        activityInformations += ` de la part de ${item.data.from.email}`
      }
      if (item.data.cc) {
        activityInformations += `, en copie à ${item.data.cc.email}`
      }
      if (item.date) {
        activityInformations += ` le ${item.date ? dayjs(item.date).format('DD/MM/YYYY à hh:mm') : ''}`
      }
      return activityInformations
    }
  }

  get isModalOpened (): boolean {
    return this.isBillingCreationModalOpened || this.isBillingEditionModalOpened
  }

  retrySendBillingUrssafTpWithNewDates () {
    this.retrySendBillingUrssafTp(this.paymentRequest || undefined)
  }

  get displaySolveIssuesButton () {
    return this.hasCustomerTPNonActivated || this.hasCustomerTPLienParticulier || this.hasCustomerTPBlocked || this.hasInvoiceDuplicate || this.canRetrySendBillingTp || this.hasPeriodeEmploiMoisNonUnique
  }

  get displayNovaNumberInput () {
    return this.billingConfiguration?.tiersPrestationActivated && !this.billingConfiguration?.tiersPrestationCredentials?.novaNumber
  }

  openBillingCustomerTPForEmail () {
    this.$store.dispatch('billingCreation/openBillingCustomerTPForEmail', this.itemToDisplay.customer)
  }

  get customerEmail () {
    return this.itemToDisplay.customer?.client?.[0]?.email || ''
  }

  get hasValidCustomerTpFields (): boolean {
    if (!this.itemToDisplay?.tiersPrestationIsActivatedForThisBilling || this.itemToDisplay?.customer?.customerCompany) {
      return true
    }
    return typeof this.itemToDisplay?.customer?.tiersPrestation?.idClient === 'string'
  }

  get hasValidTpDates (): boolean {
    const dateDebutEmploi = this.$dayjs(this.paymentRequest?.dateDebutEmploi).startOf('d').toDate()
    const dateFinEmploi = this.$dayjs(this.paymentRequest?.dateFinEmploi).startOf('d').toDate()
    const customerRegisteredAt = this.$dayjs(this.customerRegisteredAt).startOf('d').toDate()
    return billingTPDateAreValid(dateDebutEmploi, dateFinEmploi, customerRegisteredAt)
  }

  get customerRegisteredAt () {
    return this.itemToDisplay?.customer?.tiersPrestation?.registeredAt
  }

  get hasReminderActivity () {
    return this.itemToDisplay?.activity?.length && this.itemToDisplay.activity.find((activity: BillingActivity) => activity.action === BillingActivityEvent.REMINDER_SENT)
  }

  get hasAccessToOpportunity () {
    return this.$planManager.hasAccessTo(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)
  }

  get hasCustomerTPNonActivated () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_PARTICULIER_INCONNU
  }

  get hasCustomerTPLienParticulier () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_LIEN_PARTICULIER_PRESTATAIRE
  }

  get hasCustomerTPAccountBlocked () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_COMPTE_BLOQUE
  }

  get hasPeriodeEmploiMoisNonUnique () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_PERIODE_EMPLOI_MOIS_NON_UNIQUE
  }

  get hasCustomerTPBlocked () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_COMPTE_BLOQUE
  }

  get isInvoiceIntegrated () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.INTEGRATED
  }

  get isInvoiceValidated () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.VALIDATED
  }

  get isInvoiceDebit () {
    return this.item.paymentRequest?.statut === PaymentRequestStatusUrssafTp.DEBIT
  }

  get itemToDisplay () {
    return this.serverItem || this.item
  }

  closeMenu () {
    this.isMenuOpened = false
  }

  async updateNovaNumber () {
    this.loadingNovaNumber = true
    try {
      const matchStartWithSap = /^\SAP+/.exec(this.novaNumber || '')
      if (!matchStartWithSap) {
        this.$alertsManager.error('Votre numéro NOVA doit commencer par SAP')
        return
      }
      if (this.novaNumber?.length !== 12) {
        this.$alertsManager.error('Votre numéro NOVA doit comporter 9 chiffres et commencer par SAP')
        return
      }

      await this._updateNovaNumber(this.novaNumber)
      this.$alertsManager.success('Votre numéro NOVA est maintenant enregistré ! Vous pouvez désormais résoudre les erreurs en cliquant sur "Réparation automatique" à tous vos documents concernés.')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.loadingNovaNumber = false
    }
  }

  openOpportunity () {
    if (!this.itemToDisplay.opportunity || this.isModalOpened) { return }
    this.openOpportunityDetails({
      opportunity: this.itemToDisplay.opportunity,
    })
  }

  // Pour les factures et acomptes pour savoir s'ils ont été annulés par un avoir totalement ou partiellement
  get cancelledAmount () {
    if (![BillingType.INVOICE, BillingType.ADVANCE].includes(this.itemToDisplay.billingType)) { return false }
    const childBillings = this.itemToDisplay.childBilling?.filter(b => [BillingState.PAID, BillingState.FINALIZED].includes(b.billingState))
    if (!childBillings) { return false }
    const amount = { priceWithoutTax: 0, priceTotalTax: 0 }
    for (let cb = 0; cb < childBillings.length || 0; cb += 1) {
      const childBilling = childBillings[cb]
      const billingAmount = getCompleteTotalAmount(childBilling, undefined, 1)
      amount.priceWithoutTax += typeof billingAmount.remainingPriceToPayWithoutTax === 'number' ? (billingAmount.remainingPriceToPayWithoutTax || 0) : (billingAmount.priceWithoutTax || 0)
      amount.priceTotalTax += typeof billingAmount.remainingPriceToPayTotalTax === 'number' ? (billingAmount.remainingPriceToPayTotalTax || 0) : (billingAmount.priceTotalTax || 0)
    }
    if (!amount?.priceTotalTax && !amount?.priceWithoutTax) { return false }
    return amount
  }

  // Pour les devis : savoir s'ils ont été facturés totalement ou partiellement
  get billedAmount () {
    if (![BillingType.PURCHASE_ORDER, BillingType.ESTIMATE].includes(this.itemToDisplay.billingType)) { return false }
    const amount = this.itemToDisplay.childBilling?.filter(b => [BillingState.PAID, BillingState.FINALIZED].includes(b.billingState))?.filter(i => !i.canceledAt).reduce((acc, _b) => {
      const billingAmount = getCompleteTotalAmount(_b, this.itemToDisplay, 1)
      acc.priceWithoutTax += typeof billingAmount.remainingPriceToPayWithoutTax === 'number' ? (billingAmount.remainingPriceToPayWithoutTax || 0) : (billingAmount.priceWithoutTax || 0)
      acc.priceTotalTax += typeof billingAmount.remainingPriceToPayTotalTax === 'number' ? (billingAmount.remainingPriceToPayTotalTax || 0) : (billingAmount.priceTotalTax || 0)
      return acc
    }, { priceWithoutTax: 0, priceTotalTax: 0 })
    if (!amount?.priceTotalTax && !amount?.priceWithoutTax) { return false }
    return amount
  }

  get isAlreadyOpenedOnSidePanel () {
    return this._isAlreadyOpenedOnSidePanel(this.itemToDisplay._id as string)
  }

  openSidePanel () {
    if (this.isModalOpened) { return }
    this.openBillingSidePanel(this.itemToDisplay)
  }

  isBillingDocumentLoadingForAction (id?: string) {
    if (!id) {
      return false
    }
    return this.billingDocumentsLoadingForAction.includes(id)
  }

  get dateVirementTp () {
    if (!this.itemToDisplay.tiersPrestationIsActivatedForThisBilling) { return }
    if (this.itemToDisplay?.paymentRequest?.dateVirement && this.itemToDisplay.billingState !== BillingState.PAID) {
      return this.itemToDisplay?.paymentRequest?.dateVirement
    }
  }

  get canRetrySendBillingTp () {
    if (!this.itemToDisplay.tiersPrestationIsActivatedForThisBilling) { return }
    if (this.itemToDisplay.billingState === BillingState.FINALIZED && !this.itemToDisplay.paymentRequest?.statut) { return true }
    const error = ErrorCodeUrssafTp.find(err => this.itemToDisplay.paymentRequest && [err.code].includes(this.itemToDisplay.paymentRequest.statut) && ![PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON, PaymentRequestStatusUrssafTp.ERR_NBRE_PREST_MAX].includes(this.itemToDisplay.paymentRequest.statut))
    if (error && this.itemToDisplay.billingState !== BillingState.PAID) { return error }
  }

  get hasInvoiceDuplicate () {
    if (!this.itemToDisplay.tiersPrestationIsActivatedForThisBilling) { return }
    const error = this.itemToDisplay?.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON
    if (error && this.itemToDisplay.billingState !== BillingState.PAID) { return error }
  }

  async retrySendBillingUrssafTp (data?: IBilling['paymentRequest']) {
    if (!this.itemToDisplay._id) { return }
    this.loading = true
    try {
      await this.retrySendBillingUrssaf({ _id: this.itemToDisplay._id, paymentRequest: data })
      if (this.itemToDisplay?.paymentRequest?.idDemandePaiement) {
        this.$alertsManager.success('Problème résolu, votre facture a été correctement envoyé à l\'Urssaf !')
        return
      }
      if (this.itemToDisplay?.paymentRequest?.statut &&
          [PaymentRequestStatusUrssafTp.ERR_MNT_PREST_TTC,
            PaymentRequestStatusUrssafTp.ERR_MNT_PREST_HT_TVA,
            PaymentRequestStatusUrssafTp.ERR_FORMAT_VALEUR].includes(this.itemToDisplay.paymentRequest.statut)) {
        await this.$api.intercom.sendTicket({
          subject: 'Problème facture Tiers prestation',
          message: `J'ai un problème avec ma facture ${this.itemToDisplay._id} - ${this.itemToDisplay.number}. La réparation automatique ne marche pas
        et la facture revient avec les informations suivantes: statut -->${this.itemToDisplay.paymentRequest.statut} dateDebutEmploi -->${this.itemToDisplay?.paymentRequest?.dateDebutEmploi}
        dateFinEmploi -->${this.itemToDisplay?.paymentRequest?.dateFinEmploi}.
        Mon client a été enregistré sur Abby avec l'api le ${this.itemToDisplay.customer.tiersPrestation?.registeredAt}


        Informations relatives à la demande : 💬type:ticket 🐛bug:urssaf_declaration`,
        })
        this.$alertsManager.error('Le problème n\'a pu être résolu, un message automatique a été envoyé au support.')
      } else {
        this.$alertsManager.error('Le problème n\'a pu être résolu, merci de réessayer ultérieurement.')
      }
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.loading = false
    }
  }

  get BillingType () {
    return BillingType
  }

  get BillingState () {
    return BillingState
  }

  get isPaidFromStripe () {
    return this.itemToDisplay && this.itemToDisplay.paymentMethodUsed?.some(e => e.value === PaymentMethod.STRIPE)
  }

  get paidAt () {
    if (!this.itemToDisplay.paidAt) {
      return ''
    }
    const paidAt = this.$date.formatDDMMMYYYY(this.itemToDisplay.paidAt)
    const diff = this.$dayjs(this.itemToDisplay.dueDate).diff(this.$dayjs(this.itemToDisplay.paidAt), 'day')
    return (this.itemToDisplay?.paidAt
      ? {
        text: `Payé le ${paidAt}`,
        overDue: diff < 0,
      }
      : {})
  }

  get priceWithoutTax (): string {
    const price = getCompleteTotalAmount(this.itemToDisplay, this.itemToDisplay.createdFromEstimate || this.itemToDisplay.createdFromPurchaseOrder, 1)
    return this.$amount.formatCents(typeof price.remainingPriceToPayWithoutTax === 'number' ? price.remainingPriceToPayWithoutTax : price.priceWithoutTax).text
  }

  get currencyPriceWithoutTax (): string {
    const price = getCompleteTotalAmount(this.itemToDisplay, this.itemToDisplay.createdFromEstimate || this.itemToDisplay.createdFromPurchaseOrder)
    return this.$amount.formatCents(typeof price.remainingPriceToPayWithoutTax === 'number' ? price.remainingPriceToPayWithoutTax : price.priceWithoutTax, { currency: this.currency }).text
  }

  get currency () {
    return this.itemToDisplay.currency || Currency.EUR
  }

  get priceTotalTax (): string {
    const price = getCompleteTotalAmount(this.itemToDisplay, this.itemToDisplay.createdFromEstimate || this.itemToDisplay.createdFromPurchaseOrder, 1)
    return this.$amount.formatCents(typeof price.remainingPriceToPayTotalTax === 'number' ? price.remainingPriceToPayTotalTax : price.priceTotalTax).text
  }

  get currencyPriceTotalTax (): string {
    const price = getCompleteTotalAmount(this.itemToDisplay, this.itemToDisplay.createdFromEstimate || this.itemToDisplay.createdFromPurchaseOrder)
    return this.$amount.formatCents(typeof price.remainingPriceToPayTotalTax === 'number' ? price.remainingPriceToPayTotalTax : price.priceTotalTax, { currency: this.currency }).text
  }

  get isDefaultCurrency () {
    return this.currency === Currency.EUR
  }

  get messageForDisabledAction () {
    return messageForDisabledAction(this.itemToDisplay, this.stripeAccount)
  }

  get actionButtonGroupConfig (): { label: string, icon: string, actionNumber: string, disabled?: boolean, disabledTooltip?: string, onClick: () => void }[] {
    const items = [
      {
        label: 'Modifier',
        icon: 'edit',
        billingType: [BillingType.INVOICE, BillingType.ADVANCE, BillingType.ESTIMATE, BillingType.PURCHASE_ORDER, BillingType.ASSET],
        billingStatus: [BillingState.DRAFT],
        actionNumber: '1',
        onClick: () => {
          this.actionMenu.updateDocument()
        },
      },
      {
        label: 'Finaliser',
        icon: 'check_circle',
        billingType: [BillingType.INVOICE, BillingType.ADVANCE, BillingType.ESTIMATE, BillingType.PURCHASE_ORDER, BillingType.ASSET],
        billingStatus: [BillingState.DRAFT],
        actionNumber: '2',
        disabled: !billingHasAlreadyHadFinalizedState(this.itemToDisplay) && !isFinalizable(this.itemToDisplay, this.stripeAccount),
        disabledTooltip: !billingHasAlreadyHadFinalizedState(this.itemToDisplay) && !isFinalizable(this.itemToDisplay, this.stripeAccount)
          ? '<p>Ce document ne peut pas être finalisé.</p>' + this.messageForDisabledAction
          : undefined,
        onClick: () => this.actionMenu.markAsFinalized(),
      },
      {
        label: 'Télécharger le PDF',
        icon: 'file_download',
        billingType: [BillingType.INVOICE, BillingType.ADVANCE, BillingType.ESTIMATE, BillingType.PURCHASE_ORDER, BillingType.ASSET],
        billingStatus: [BillingState.DRAFT, BillingState.FINALIZED, BillingState.PAID, BillingState.REFUSED, BillingState.SIGNED],
        actionNumber: '3',
        onClick: () => this.actionMenu.download(),
      },
      {
        label: 'Marquer comme payée',
        icon: 'check_circle',
        billingType: [BillingType.INVOICE, BillingType.ADVANCE, BillingType.ASSET],
        billingStatus: [BillingState.FINALIZED],
        actionNumber: '1',
        onClick: () => { this.actionMenu.markAsPaid() },
      },
      {
        label: 'Envoyer',
        icon: 'send',
        billingType: [BillingType.INVOICE, BillingType.ESTIMATE, BillingType.PURCHASE_ORDER, BillingType.ADVANCE, BillingType.ASSET],
        billingStatus: [BillingState.FINALIZED, BillingState.PAID, BillingState.REFUSED, BillingState.SIGNED],
        actionNumber: '2',
        onClick: () => this.actionMenu.sendByEmail(),
      },
      {
        label: 'Marquer comme non payée',
        icon: 'cancel',
        billingType: [BillingType.INVOICE, BillingType.ADVANCE, BillingType.ASSET],
        billingStatus: [BillingState.PAID],
        actionNumber: '1',
        onClick: () => this.actionMenu.markAsUnPaid(),
      },
      {
        label: 'Marquer comme signé',
        icon: 'check_circle',
        billingType: [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER],
        billingStatus: [BillingState.FINALIZED],
        actionNumber: '1',
        onClick: () => this.actionMenu.markAsSigned(),
      },
      {
        label: 'Marquer comme non signé',
        icon: 'cancel',
        billingType: [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER],
        billingStatus: [BillingState.SIGNED],
        actionNumber: '1',
        onClick: () => this.actionMenu.markAsNotSigned(),
      },
      {
        label: 'Marquer comme non refusé',
        icon: 'check_circle',
        billingType: [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER],
        billingStatus: [BillingState.REFUSED],
        actionNumber: '1',
        onClick: () => this.actionMenu.markAsNotRefused(),
      },
    ]

    const config = items.filter((item) => {
      return item.billingType.includes(this.itemToDisplay.billingType) && item.billingStatus.includes(this.itemToDisplay.billingState)
    }).map((item) => {
      return {
        label: item.label,
        icon: item.icon,
        actionNumber: item.actionNumber,
        ...(item.disabled ? { disabled: item.disabled } : {}),
        ...(item.disabledTooltip ? { disabledTooltip: item.disabledTooltip } : {}),
        onClick: item.onClick,
      }
    })

    // Signé en ligne
    if (billingIsSignedOnline(this.itemToDisplay)) {
      config.splice(config.findIndex(c => c.actionNumber === '1'), 1)
      config.push({
        label: 'Télécharger la preuve',
        icon: 'source',
        actionNumber: '1',
        onClick: () => this.actionMenu.downloadProof(),
      })
    }
    return config
  }

  async markAsPaid () {
    if (!this.item._id) { return }
    await this.openMarkAsPaidModal(this.item)
  }

  mounted () {
    this.$busManager.on('billingUpdated', (b: IBilling) => {
      if (b?._id !== this.item._id) { return }
      this.serverItem = b
    })
    this.paymentRequest = this.itemToDisplay.paymentRequest || null
    this.detectInputDevice()
    window.addEventListener('mousemove', this.detectInputDevice)
  }

  beforeDestroy () {
    window.removeEventListener('mousemove', this.detectInputDevice)
  }

  detectInputDevice () {
    if (window.matchMedia('(pointer:fine)').matches && !this.pointerFine) {
      // L'utilisateur utilise une souris
      this.pointerFine = true
    } else if (window.matchMedia('(pointer:coarse)').matches && this.pointerFine) {
      // L'utilisateur utilise un périphérique tactile
      this.pointerFine = false
    }
  }

  get actionStyle () {
    if (this.singleLineMode) { return 'min-width: 50px' }
    return !this.$vuetify.breakpoint.smAndDown && this.forceSummaryMode ? 'min-width: 100px' : 'min-width: 225px'
  }
}
