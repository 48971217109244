import { CreateOrganization } from '@abby/shared'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useCreateOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organization: CreateOrganization) => {
    const newOrganization = await organizationRepository.createOrganization(organization)
    alertManager.success("L'organisation a bien été créé")
    busManager.emit('organizationCreated', newOrganization)
    return newOrganization
  }
}
