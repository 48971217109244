import type {
  ReadVatConfiguration,
  CreateVatConfiguration,
  QueryVatDetails,
  UpdateVatConfiguration,
  ReadVatDetails,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Vat extends Resource {
  getConfiguration (): Promise<ReadVatConfiguration> {
    return this.axios.$get('/vat/configuration')
  }

  createConfiguration (data: CreateVatConfiguration): Promise<ReadVatConfiguration> {
    return this.axios.$post('/vat/configuration', data)
  }

  updateConfiguration (data: UpdateVatConfiguration): Promise<ReadVatConfiguration> {
    return this.axios.$patch('/vat/configuration', data)
  }

  getDetails (params: QueryVatDetails): Promise<ReadVatDetails> {
    return this.axios.$get('/vat/details', { params })
  }
}
