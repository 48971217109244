import { Plugin } from '@nuxt/types'
import { IApiError } from '@abby/core-legacy'
import { AxiosError } from 'axios'
import { useAlertManagerStore } from '~/composables/abby/manager/useAlertManager.store'

export type AlertType = 'info' | 'success' | 'warning' | 'error'

export type AlertComponent = 'alert' | 'snack'

export interface AlertParams {
  type?: AlertType
  component?: AlertComponent
  dismissible?: boolean
}

export interface Alert {
  message: string
  type: AlertType
  component: AlertComponent
  dismissible: boolean
  errorId?: string
  timeout?: number
}

export interface AlertsManagerInstance {
  error(message: string, params?: AlertParams): void;
  success(message: string, params?: AlertParams): void;
  warning(message: string, params?: AlertParams): void;
  info(message: string, params?: AlertParams): void;
  autoError(error: IApiError, params?: AlertParams): void
}

const AlertsManagerPlugin: Plugin = (context, inject) => {
  const defaultSnackConfig: { dismissible: boolean, component: AlertComponent } = {
    dismissible: true,
    component: 'snack',
  }

  const calculateTimeNeededToReadMessage = (message: string) => {
    const readingTimeNeeded = message.split(' ').length * 240 + 500
    return readingTimeNeeded > 3000 ? readingTimeNeeded : 3000
  }

  inject('alertsManager', {
    error: (message: string, params?: AlertParams) => {
      const alertManagerStore = useAlertManagerStore()
      alertManagerStore.addAlert({
        message,
        type: 'error',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    success: (message: string, params?: AlertParams) => {
      const alertManagerStore = useAlertManagerStore()
      alertManagerStore.addAlert({
        message,
        type: 'success',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    warning: (message: string, params?: AlertParams) => {
      const alertManagerStore = useAlertManagerStore()
      alertManagerStore.addAlert({
        message,
        type: 'warning',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    info: (message: string, params?: AlertParams) => {
      const alertManagerStore = useAlertManagerStore()
      alertManagerStore.addAlert({
        message,
        type: 'info',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    autoError: (error: AxiosError & { config: any, response?: any, status: number, message?: string }, params?: AlertParams) => {
      const alertManagerStore = useAlertManagerStore()
      context?.$hotjar?.sendHotjarEvent('error')
      if (!['production', 'staging'].includes(context.$config.nodeEnv as string)) {
        // eslint-disable-next-line no-console
        console.trace(error)
      }

      let message: string = context.app.i18n.t('error.basic_error') as string
      const code = error.response?.data?.code || error?.code

      const errorId = error.response?.data?.errorId as string

      if (error.response?.data.message) {
        message = context.app.i18n.t(`error.${error.response.data.message as string}`) as string
      } else if (code) {
        const errorMessage = context.app.i18n.t(`error.${code as string}`)
        if (errorMessage !== `error.${code as string}`) {
          message = errorMessage as string
        }
      }

      alertManagerStore.addAlert({
        message,
        type: 'error',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
        errorId,
      })
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $alertsManager: AlertsManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $alertsManager: AlertsManagerInstance
  }
  interface Context {
    $alertsManager: AlertsManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $alertsManager: AlertsManagerInstance
  }
}

export default AlertsManagerPlugin
