import * as PhoneNumber from 'awesome-phonenumber';
import {
  BillingState,
  BillingType,
  CreateBilling,
  EmailType,
  IBilling,
  IBillingConfiguration,
  ICustomer,
  ILineProduct,
  IUser,
  PaymentDelay,
  PaymentDelayItems,
  ReadContact,
  UpdateBillingConfiguration,
  ReadStripeAccount,
} from '../../types';
import { getCompleteTotalAmount, getTotalAmount } from './calculate';
import { formatCents, isHumanNameValidYousign, isMailValidYousign } from '../common';
import { Overwrite } from '../objectUtils';
import { toArray } from '../arrayUtils';
import { BillingAction } from '../../types/Enum/MenuAction';
import dayjs from 'dayjs';
import 'dayjs/plugin/isSameOrAfter';
import 'dayjs/plugin/isSameOrBefore';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const customParseFormat = require('dayjs/plugin/customParseFormat');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const billingLineIsValid = (line: ILineProduct, params?: { tiersPrestation?: boolean; advance?: boolean }): boolean => {
  if (!line) {
    return false;
  }
  if (params?.tiersPrestation) {
    return !!(
      line.productType && line.designation && line.quantity !== null && line.quantity! >= 0
      && line.codeNature && line.tpUnit && line.unitPrice !== undefined && line.unitPrice !== null
    );
  }
  if (params?.advance) {
    return (line.quantity !== null && line.unitPrice !== undefined && line.unitPrice !== null);
  }
  return !!(line.productType && line.designation && line.quantity !== null && line.quantity! >= 0 && line.unitPrice !== undefined && line.unitPrice !== null);
};

export const billingTPDateAreValid = (debutEmploi: Date | undefined, finEmploi: Date | undefined, customerRegisteredAt: Date | undefined): boolean => !!debutEmploi
  && !!finEmploi
  && !!customerRegisteredAt
  && dayjs(debutEmploi).isSame(finEmploi, 'month')
  && dayjs(debutEmploi).isSame(finEmploi, 'year')
  && dayjs(debutEmploi).isSameOrAfter(customerRegisteredAt)
  && dayjs(finEmploi).isSameOrAfter(debutEmploi)
  && dayjs(finEmploi).isSameOrBefore();

export const billingIsValidForTP = (item: IBilling): boolean => {
  const debutEmploi = dayjs(item.paymentRequest?.dateDebutEmploi).startOf('d').toDate();
  const finEmploi = dayjs(item.paymentRequest?.dateFinEmploi).startOf('d').toDate();
  const customerRegisteredAt = dayjs(item.customer.tiersPrestation?.registeredAt).startOf('d').toDate();
  const _amount = getTotalAmount(item.product, { discountMode: item.discountMode, discountAmount: item.discountAmount }).priceTotalTax;
  const advanceIsLessThanTotal = (item.paymentRequest?.advanceAlreadyPayed || 0) < _amount / 100;
  return _amount >= 0 && (_amount === 0 || advanceIsLessThanTotal)
    && !!item?.tiersPrestationIsActivatedForThisBilling
    && !!item?.customer?.tiersPrestationIsActivatedForThisCustomer
    && billingTPDateAreValid(debutEmploi, finEmploi, customerRegisteredAt);
};

export const billingHasValidProducts = (item: IBilling | undefined, createFromBilling?: IBilling | undefined): boolean => {
  if (!item) return false;
  let products = item.product;

  if ([BillingType.ADVANCE, BillingType.ASSET].includes(item.billingType) && createFromBilling) {
    products = createFromBilling.product;
  }

  return products?.length > 0 && products?.every(product => billingLineIsValid(product, { tiersPrestation: item.tiersPrestationIsActivatedForThisBilling, advance: item.billingType === BillingType.ADVANCE || (item.billingType === BillingType.ASSET && createFromBilling?.billingType === BillingType.ADVANCE) }));
};

export const getDueDate = (paymentDelay: PaymentDelayItems, dateBillingDocument: Date): string | Date => {
  if (!paymentDelay?.value || !dateBillingDocument) return '';
  if (paymentDelay.other && paymentDelay.other !== '') return paymentDelay.other;

  const dueDates = {
    [PaymentDelay.AT_RECEPTION]: dayjs(dateBillingDocument).endOf('day'),
    [PaymentDelay.END_OF_MONTH]: dayjs(dateBillingDocument).endOf('day').endOf('month'),
    [PaymentDelay.SEVEN_DAYS]: dayjs(dateBillingDocument).endOf('day').add(7, 'days'),
    [PaymentDelay.TEN_DAYS]: dayjs(dateBillingDocument).endOf('day').add(10, 'days'),
    [PaymentDelay.FIFTEEN_DAYS]: dayjs(dateBillingDocument).endOf('day').add(15, 'days'),
    [PaymentDelay.THIRTY_DAYS]: dayjs(dateBillingDocument).endOf('day').add(30, 'days'),
    [PaymentDelay.THIRTY_DAYS_END_OF_MONTH]: dayjs(dateBillingDocument).endOf('day').add(30, 'days').endOf('month'),
    [PaymentDelay.FORTY_FIVE_DAYS]: dayjs(dateBillingDocument).endOf('day').add(45, 'days'),
    [PaymentDelay.FORTY_FIVE_DAYS_END_OF_MONTH]: dayjs(dateBillingDocument).endOf('day').add(45, 'days').endOf('month'),
    [PaymentDelay.SIXTY_DAYS]: dayjs(dateBillingDocument).endOf('day').add(60, 'days'),
    [PaymentDelay.SIXTY_DAYS_END_OF_MONTH]: dayjs(dateBillingDocument).endOf('day').add(60, 'days').endOf('month'),
    [PaymentDelay.NINETY_DAYS]: dayjs(dateBillingDocument).endOf('day').add(90, 'days'),
    [PaymentDelay.NINETY_DAYS_END_OF_MONTH]: dayjs(dateBillingDocument).endOf('day').add(90, 'days').endOf('month'),
    [PaymentDelay.ONE_HUNDRED_AND_TWENTY_DAYS]: dayjs(dateBillingDocument).endOf('day').add(120, 'days'),
    [PaymentDelay.OTHER]: dayjs(dateBillingDocument).endOf('day').add(1, 'days'),
  };

  const dueDate = dueDates[paymentDelay.value];
  return dueDate.toDate();
};

export const dueDateIsPassed = (billing: IBilling) => {
  if (
    !billing.paymentDelay
    || !billing.date
    || ![BillingType.INVOICE, BillingType.ADVANCE].includes(billing.billingType)
    || ![BillingState.FINALIZED].includes(billing.billingState)
  ) {
    return false;
  }
  const diff = dayjs(getDueDate(billing.paymentDelay, billing.date)).diff(dayjs(), 'day');
  return diff < 0;
};

export const isCancelled = (billing: IBilling): boolean => {
  const childBillings = billing.childBilling?.filter(b => [BillingState.PAID, BillingState.FINALIZED].includes(b.billingState));
  if (!childBillings) {
    return false;
  }
  const amount = { priceWithoutTax: 0 };
  for (let cb = 0; cb < childBillings.length || 0; cb += 1) {
    const childBilling = childBillings[cb];
    const billingAmount = getCompleteTotalAmount(childBilling, undefined, 1);
    amount.priceWithoutTax += typeof billingAmount.remainingPriceToPayWithoutTax === 'number' ? (billingAmount.remainingPriceToPayWithoutTax || 0) : (billingAmount.priceWithoutTax || 0);
  }
  const totalAmount = getCompleteTotalAmount(billing, billing.createdFromEstimate || billing.createdFromPurchaseOrder, 1);
  return amount.priceWithoutTax >= (totalAmount.remainingPriceToPayWithoutTax || totalAmount.priceWithoutTax);
};

export const getAvailableBillingActions = (billing: IBilling, admin?: boolean): Array<BillingAction> => {
  const defaultActions: Array<BillingAction> = [
    BillingAction.OPEN,
    ...(billing.locale && billing.locale !== 'fr' ? [BillingAction.DOWNLOAD_FRENCH_LOCALE] : []),
    ...(billing.archivedAt ? [BillingAction.UNARCHIVE] : [BillingAction.ARCHIVE]),
    BillingAction.DOWNLOAD,
    BillingAction.UPDATE_TITLE,
    // On peut tout dupliquer sauf les avances
    ...billing.billingType !== BillingType.ADVANCE
      ? [BillingAction.DUPLICATE_AS_ESTIMATE, BillingAction.DUPLICATE_AS_INVOICE, BillingAction.DUPLICATE_AS_PURCHASE_ORDER]
      : [],
    // actions commune a tous les documents quand il sont en brouillon
    ...billing.billingState === BillingState.DRAFT
      ? [BillingAction.UPDATE_DOCUMENT, BillingAction.MARK_AS_FINALIZED, BillingAction.MARK_AS_FINALIZED_AND_SEND, BillingAction.DELETE_DOCUMENT]
      : [BillingAction.SEND_BY_EMAIL],
    // autoriser la modification de devis non signé
    ...[BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType) && ![BillingState.SIGNED, BillingState.REFUSED].includes(billing.billingState)
      ? [BillingAction.UPDATE_DOCUMENT]
      : [],
    // Suppression toujours disponible en admin
    ...(billing.billingState !== BillingState.DRAFT && admin ? [BillingAction.ADMIN_DELETE_DOCUMENT, BillingAction.ADMIN_COPY_ISSUE_PARTICULIER_INCONNU] : []),
    ...(admin ? [BillingAction.ADMIN_COPY_DOCUMENT_ID, BillingAction.ADMIN_COPY_BILLING_CONFIGURATION_ID, BillingAction.ADMIN_REGENERATE_DOCUMENT, BillingAction.ADMIN_UNLINK_FROM_CREATE_FROM] : []),
  ];

  if ([BillingType.PURCHASE_ORDER, BillingType.ESTIMATE].includes(billing.billingType)) {
    // Somme des acomptes + factures qui dépendent d'un devis --> si il y'a déjà une facture finale on ne peut plus créer ni de facture ni d'acompte à partir de ce devis
    const canCreateInvoice = !billing.childBilling?.some(b => b.billingType === BillingType.INVOICE);
    const actionsByState = {
      [BillingState.DRAFT]: [
        BillingAction.MARK_AS_SIGNED,
        BillingAction.MARK_AS_REFUSED,
      ],
      [BillingState.FINALIZED]: [
        BillingAction.MARK_AS_SIGNED,
        BillingAction.MARK_AS_REFUSED,
        ...(canCreateInvoice ? [BillingAction.CREATE_AN_INVOICE, BillingAction.CREATE_AN_ADVANCE] : []),
        ...([BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType) && !billing.signature
          ? [BillingAction.ACTIVATE_ONLINE_SIGNATURE]
          : []),
      ],
      [BillingState.SIGNED]: [
        ...(billing.billingType === BillingType.ESTIMATE && billing.signature?.signedAt ? [] : [BillingAction.MARK_AS_NOT_SIGNED]),
        ...(canCreateInvoice ? [BillingAction.CREATE_AN_INVOICE, BillingAction.CREATE_AN_ADVANCE] : []),
        ...([BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType) && billing.signature ? [BillingAction.DOWNLOAD_SIGNATURE_PROOF] : []),
      ],
      [BillingState.REFUSED]: [
        ...(billing.billingType === BillingType.ESTIMATE && billing.signature?.refusedAt ? [] : [BillingAction.MARK_AS_NOT_REFUSED]),
      ],
      [BillingState.PAID]: [],
    };

    const copySignature = [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType)
      && billing.signature
      && !billing.signature.signedAt
      && !billing.signature.refusedAt
      && !billing.signature.canceledAt
      && billing.billingState !== BillingState.DRAFT;

    return [
      ...defaultActions,
      ...actionsByState[billing.billingState],
      ...(copySignature
        ? [
            BillingAction.COPY_SIGNATURE_URL,
          ]
        : []),
    ];
  }

  // POUR LE RESTE : Si on est dans le cas d'une facture, avance ou d'un avoir
  const payAction = billing.billingState === BillingState.PAID
    ? BillingAction.MARK_AS_UN_PAID
    : BillingAction.MARK_AS_PAID;

  const invoiceActions = {
    [BillingState.FINALIZED]: [
      ...billing.tiersPrestationIsActivatedForThisBilling ? [BillingAction.CHECK_URSSAF_TP_STATUT] : [],
      ...!isCancelled(billing) ? [BillingAction.CANCEL_BILLING, BillingAction.CREATE_AN_ASSET] : [],
      ...billing.billingType !== BillingType.ADVANCE && !(billing.createdFromEstimateId || billing.createdFromPurchaseOrderId) && !billing.tiersPrestationIsActivatedForThisBilling ? [BillingAction.MANAGE_FREQUENCY] : [],
      BillingAction.UPDATE_REMINDER,
      ...([BillingType.INVOICE, BillingType.ADVANCE].includes(billing.billingType) && !billing.useStripePayment && !billing.tiersPrestationIsActivatedForThisBilling
        ? [BillingAction.ACTIVATE_ONLINE_PAYMENT]
        : []),
    ],
    [BillingState.PAID]: [
      ...!isCancelled(billing) ? [BillingAction.CANCEL_BILLING, BillingAction.CREATE_AN_ASSET] : [],
      ...billing.billingType !== BillingType.ADVANCE && !(billing.createdFromEstimateId || billing.createdFromPurchaseOrderId) && !billing.tiersPrestationIsActivatedForThisBilling ? [BillingAction.MANAGE_FREQUENCY] : [],
    ],
    // aucune action dispo pour ces états, mais obligé de les mettre pour le typage
    [BillingState.DRAFT]: [],
    [BillingState.SIGNED]: [],
    [BillingState.REFUSED]: [],
  };

  return [
    payAction,
    ...defaultActions,
    ...billing.useStripePayment ? [BillingAction.COPY_STRIPE_PAYMENT_URL] : [],
    ...billing.billingType !== BillingType.ASSET
      ? invoiceActions[billing.billingState]
      : [],
    ...dueDateIsPassed(billing)
      ? [BillingAction.UNPAID_INVOICE]
      : [],
  ];
};

export const checkIfRequestedBillingActionIsAllowed = (billing: IBilling, requestedAction: BillingAction, admin?: boolean): boolean => {
  const availableActions = getAvailableBillingActions(billing, admin);
  return !!availableActions.includes(requestedAction);
};

export const isOverdue = (paymentDelay: PaymentDelayItems, dateBillingDocument: Date): boolean => {
  if (!paymentDelay?.value || !dateBillingDocument) return false;
  if (paymentDelay.other && paymentDelay.other !== '') return false;

  const dueDate = getDueDate(paymentDelay, dateBillingDocument);
  return dayjs(dueDate).isBefore(dayjs().subtract(1, 'd').endOf('d').toDate());
};

export const cleanBillingDocument = (item: Overwrite<IBilling, { id?: string }>): CreateBilling => {
  const _item = { ...item };
  delete _item._id;
  delete _item.id;
  delete _item.__v;
  delete _item.history;
  delete _item.activity;
  // @ts-expect-error
  delete _item.billingState;
  delete _item.companyId;
  delete _item.createdAt;
  delete _item.updatedAt;
  delete _item.dueDate;
  delete _item.paymentIntent;
  delete _item.token;
  delete _item.customEmail;
  // @ts-expect-error
  delete _item.draftFile;
  delete _item.opportunity;
  delete _item.finalizedFile;
  delete _item.paidAt;
  delete _item.paymentMethodUsed;
  delete _item.paidFile;
  delete _item.frequency;
  delete _item.frequencyId;
  delete _item.recurring;
  delete _item.tiersPrestationActivated;
  delete _item.tiersPrestationCredentials;
  delete _item.customEmailEstimate;
  delete _item.displayTextEmail;
  delete _item.displayTextPhoneNumber;

  delete _item.refusedAt;
  delete _item.refusedFile;
  delete _item.signedFile;
  delete _item.signedAt;
  delete _item.childBilling;
  delete _item.createdFromEstimateId;
  delete _item.createdFromEstimate;
  delete _item.createdFromInvoiceId;
  delete _item.createdFromInvoice;
  delete _item.createdFromPurchaseOrderId;
  delete _item.createdFromPurchaseOrder;
  delete _item.logo;
  delete _item.signatureEmail;
  delete _item.colors;
  delete _item.warning;
  delete _item.opportunity;

  delete _item.signatureId;
  delete _item.signature;

  delete _item.tiersPrestationValidated;
  delete _item.customAdditionalLogosUploaded;

  delete _item.finalizedAt;

  // Si il y a un problème avec cette ligne contacter @Corentin au 07 71 07 28 96
  delete _item.remainingReconciliateAmount;

  if (![BillingType.INVOICE, BillingType.ADVANCE].includes(_item.billingType)) {
    delete _item.useStripePayment;
  }

  if (![BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(_item.billingType)) {
    delete _item.useSignature;
  }

  if (_item.advances?.list) {
    // @ts-expect-error
    _item.advances = _item.advances.list.map(advance => ({
      value: advance.value,
      mode: advance.mode,
      paymentCondition: advance.paymentCondition,
      customPaymentCondition: advance.customPaymentCondition,
    }));
  }

  return _item as any as CreateBilling;
};

export const removeILineProductVat = (data: ILineProduct | ILineProduct[]) => {
  const products = toArray(data);
  return products.map((p) => {
    delete p.vatId;

    delete p.vatPercentage;
    return p;
  });
};

export const getBillingTypeEmail = (billingType: BillingType, emailType: string) => {
  switch (billingType) {
    case BillingType.ESTIMATE:
    case BillingType.PURCHASE_ORDER:
      return EmailType.ESTIMATE_SIMPLE;
    case BillingType.INVOICE:
      if (emailType === 'simple') {
        return EmailType.INVOICE_SIMPLE;
      }
      if (emailType === 'reminder') {
        return EmailType.INVOICE_REMINDER;
      }
      return EmailType.INVOICE_RECURRING;
    case BillingType.ADVANCE:
      if (emailType === 'simple') {
        return EmailType.INVOICE_SIMPLE;
      }
      if (emailType === 'reminder') {
        return EmailType.INVOICE_REMINDER;
      }
      return EmailType.INVOICE_SIMPLE;
    default: return EmailType.INVOICE_SIMPLE;
  }
};

export const cleanBillingConfiguration = (item: Partial<IBillingConfiguration>): UpdateBillingConfiguration => {
  const _item: Partial<IBillingConfiguration> = { ...item };
  delete _item?.createdAt;
  delete _item?.updatedAt;
  return _item as UpdateBillingConfiguration;
};

export const redirectRoute = (type: BillingType) => {
  switch (type) {
    case BillingType.ESTIMATE: return '/billing/estimates';
    case BillingType.ADVANCE: return '/billing';
    case BillingType.INVOICE: return '/billing';
    case BillingType.ASSET: return '/billing/assets';
    case BillingType.PURCHASE_ORDER: return '/billing/purchaseOrders';
    default: return '/billing';
  }
};

export const customerName = (customer?: ICustomer, onlyCompany = false): string | null => {
  if (!customer) {
    return null;
  }
  const hasCustomerCompany = !!customer.customerCompany?.name;
  if (onlyCompany && !hasCustomerCompany) {
    return null;
  }
  const name = hasCustomerCompany ? customer?.customerCompany?.name || '' : `${customer?.client?.[0]?.firstname || ''} ${customer?.client?.[0]?.lastname || ''}`.trim();
  return name === '' ? null : name;
};

export const contactFullName = (contact: ReadContact): string | null => {
  const fullName = `${contact?.firstname || ''} ${contact?.lastname || ''}`.trim();
  return fullName === '' ? null : fullName;
};

export const customerClientFullName = (customer?: ICustomer): string | null => {
  if (!customer) {
    return null;
  }
  const fullName = `${customer?.client?.[0]?.firstname || ''} ${customer?.client?.[0]?.lastname || ''}`.trim();
  return fullName === '' ? null : fullName;
};

export const customerFirstname = (customer?: ICustomer): string | null => {
  if (!customer) {
    return null;
  }
  return `${customer?.client?.[0]?.firstname || ''}`.trim();
};

export const customerLastname = (customer?: ICustomer): string | null => {
  if (!customer) {
    return null;
  }
  return `${customer?.client?.[0]?.lastname || ''}`.trim();
};

export const customerEmail = (customer: ICustomer): string | null => {
  if (!customer) {
    return null;
  }
  return customer?.client?.[0]?.email || null;
};

export const customerPhone = (customer: ICustomer): string | null => {
  if (!customer) {
    return null;
  }
  return customer?.client?.[0]?.phone || null;
};

export const contentEmailBilling = (billing: IBilling, user: IUser, baseUrl: string): string => {
  let number = '{{ numero du document }}';
  let isHt = true;
  let amount = '{{MONTANT_FACTURE}}';
  let paymentLink = '';
  const fullName = `${user?.lastname || ''} ${user?.firstname || ''}`.trim();

  if (billing) {
    number = billing.number || '{{ numero du document }}';
    const totalAmount = getTotalAmount(billing.product, { discountMode: billing.discountMode, discountAmount: billing.discountAmount });
    const { priceTotalTax, priceWithoutTax } = totalAmount;
    isHt = priceTotalTax === priceWithoutTax;
    amount = formatCents(priceTotalTax).text;

    const link = billing.token ? `${baseUrl}/payment?id=${billing._id}&token=${billing.token}` : `${baseUrl}/clientPortal?id=${billing.customer._id}&billing=${billing._id}&token=${billing.customer.token}`;
    paymentLink = billing.useStripePayment && [BillingType.INVOICE, BillingType.ADVANCE].includes(billing.billingType)
      ? `<p>Vous pouvez payer votre facture en ligne en <b><a href="${link}" rel="noopener noreferrer nofollow">cliquant ici</a></b>.</p><p><br></p>`
      : '';
  }

  return `${'<p>Bonjour,</p>'
    + '<p><br></p>'
    + `<p>Vous trouverez le document <b>${number}</b> d'un montant ${isHt ? 'HT' : 'TTC'} de <b>${amount}</b> en pièce jointe.</p>`
    + '<p><br></p>'}${
    paymentLink
  }<p>Cordialement,</p>`
  + `<p><b>${fullName}</b></p>`;
};

export const contentRemindEmail = (billing: IBilling, user: IUser, baseUrl: string): string => {
  const _amount = getTotalAmount(billing.product, { discountMode: billing.discountMode, discountAmount: billing.discountAmount }).priceTotalTax;
  const amount = formatCents(_amount).text;
  const dueDate = getDueDate(billing.paymentDelay as PaymentDelayItems, billing.date || dayjs().toDate());
  const date = dayjs(billing.date || dayjs()).format('DD/MM/YYYY');
  const fullName = `${user.firstname || ''} ${user.lastname || ''}`.trim();
  const billingTypeText = billing.billingType === BillingType.INVOICE ? 'la facture' : 'l\'acompte';

  const link = billing.token ? `${baseUrl}/payment?id=${billing._id}&token=${billing.token}` : `${baseUrl}/clientPortal?id=${billing.customer._id}&billing=${billing._id}&token=${billing.customer.token}`;

  const paymentLink = billing.useStripePayment && [BillingType.INVOICE, BillingType.ADVANCE].includes(billing.billingType)
    ? `<p>Vous pouvez payer votre facture en ligne en <b><a href="${link}" rel="noopener noreferrer nofollow">cliquant ici</a></b>.</p>`
    + '<p><br></p>'
    : '';

  return (
    `${'<p>Bonjour,</p>'
    + `<p>Le ${date}, ${fullName} vous a adressé ${billingTypeText} ${billing.number}. <br> Sauf erreur de notre part, le réglement de cette facture n'a pas encore été effectué.</p>`
    + '<p><br></p>'
    + `<p>Date d'échéance : <span style="font-weight: bold">${dueDate}</span></p>`
    + `<p>Montant: <span style="font-weight: bold">${amount}</span></p>`
    + '<p><br></p>'}${
      paymentLink
    }<p>Si vous avez déjà effectué ce paiement, veuillez accepter nos excuses et ignorer ce rappel.</p>`
  );
};

export const signatureNotAvailable = (billing: IBilling) => {
  const client = billing?.customer?.client?.[0];

  if (billing?.customer?.organizationId && !billing?.customer?.contactId) {
    return {
      hasError: true,
      errorYousignClient: 'Vous devez sélectionner un contact à votre organisation cliente pour pouvoir activer la signature en ligne.',
    };
  }

  const phone = PhoneNumber.parsePhoneNumber(client?.phone || '');
  const isPhoneValid = phone.valid && phone.typeIsMobile;
  const email = client?.email || '';
  const hasError = !email || !client?.phone || !isPhoneValid
    || !isHumanNameValidYousign(client?.firstname)
    || !isHumanNameValidYousign(client?.lastname)
    || !isMailValidYousign(email);

  let errorYousignClient = '';
  if (hasError) {
    errorYousignClient += '<p>Les informations suivantes du client ne sont pas complètes pour la signature en ligne :</p><ul class="as-bullet-list">';
  }
  if (!isHumanNameValidYousign(client?.firstname || '')) {
    errorYousignClient += '<li>Le prénom du client est manquant ou invalide. Vérifiez qu\'il ne contient pas de caractères spéciaux.</li>';
  }
  if (!isHumanNameValidYousign(client?.lastname || '')) {
    errorYousignClient += '<li>Le nom du client est manquant ou invalide. Vérifiez qu\'il ne contient pas de caractères spéciaux.</li>';
  }
  if (!isMailValidYousign(email)) {
    errorYousignClient += '<li>L\'adresse e-mail du client est manquante ou invalide. Attention, vous ne pouvez renseigner qu\'une adresse e-mail.</li>';
  }
  if (!client?.phone) {
    errorYousignClient += '<li>Le numéro de téléphone du client est manquant.</li>';
  }
  else if (!isPhoneValid) {
    errorYousignClient += '<li>Le numéro de téléphone du client n\'est pas valide. Vérifiez notamment que le numéro commence bien par +336, +337, 06 ou 07 s\'il vient de France métropolitaine.</li>';
  }
  errorYousignClient += '</ul>';
  return {
    hasError,
    errorYousignClient,
  };
};

export const isAttached = (billing: IBilling) => (billing.customer || billing.opportunity);

export const hasValidProducts = (billing: IBilling) => billingHasValidProducts(billing, billing.createdFromEstimate || billing.createdFromPurchaseOrder || billing.createdFromInvoice);

export const hasProducts = (billing: IBilling) => billing.product && billing.product.length;

export const isValidForTP = (billing: IBilling) => !billing.tiersPrestationIsActivatedForThisBilling || (billingIsValidForTP(billing) && billing?.paymentRequest?.dateDebutEmploi && billing?.paymentRequest?.dateFinEmploi);

export const isValidForSignature = (billing: IBilling) => !billing.useSignature || (billing.useSignature && !signatureNotAvailable(billing).hasError);

export const isAdvancesValid = (billing: any) => {
  if (!billing.advances?.length) {
    return true;
  }
  return billing.advances.every((line: any) => line.value > 0)
    && billing.advances.every(
      (line: any) => line.paymentCondition !== 'OTHER' || (line.paymentCondition === 'OTHER' && line.customPaymentCondition?.trim().length),
    ) && (billing.advances?.remainingTotalAmountWithoutTax || 0) > 0;
};

export const hasValidDates = (billing: IBilling) => {
  if ([BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType)) {
    return dayjs(billing.date).isBefore(billing.validityDate);
  }
  return dayjs(billing.date).isBefore(billing.dueDate);
};

export const isOnlinePaymentEnabled = (stripeAccount?: ReadStripeAccount | null) => !stripeAccount?.requirements || (stripeAccount?.requirements && !stripeAccount?.requirements.disabled_reason);

export const isPriceLowerThanZero = (billing: IBilling) => {
  const totalAmount = getCompleteTotalAmount(billing, billing.createdFromEstimate || billing.createdFromPurchaseOrder, 1);
  return totalAmount.priceWithoutTax < 0 || totalAmount.priceTotalTax < 0;
};

const hasCustomerAValidEmail = (email?: string) => {
  if (!email?.length) {
    return false;
  }
  const emailRegex = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  return emailRegex.test(email);
};

export const isFrequencyClientEmailValid = (billing: IBilling) => {
  return (!!billing?.frequency && !billing?.frequency.sendEmail)
    || (!!billing?.frequency && billing?.frequency.sendEmail && !!billing?.customer?.client?.[0]?.email && hasCustomerAValidEmail(billing?.customer?.client?.[0]?.email))
    || !billing?.frequency;
};

export const messageForDisabledAction = (billing: IBilling, stripeAccount: ReadStripeAccount | null) => {
  let _messageForDisabledAction = '<p>Vous pouvez modifier le document pour corriger ces problèmes :</p>';
  _messageForDisabledAction += '<ul class="as-bullet-list">';
  if (!isAttached(billing)) {
    _messageForDisabledAction += '<li>Aucun client n\'est attaché à ce document</li>';
  }
  if (!hasValidDates(billing) && [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType)) {
    _messageForDisabledAction += '<li>La date de création du document est postérieure à la date de validité</li>';
  }
  else if (!hasValidDates(billing)) {
    _messageForDisabledAction += '<li>La date de création du document est postérieure à la date d\'échéance</li>';
  }

  if (hasProducts(billing) && !hasValidProducts(billing)) {
    _messageForDisabledAction += '<li>Certaines lignes d\'articles ne sont pas correctes</li>';
  }
  if (!hasProducts(billing)) {
    _messageForDisabledAction += '<li>Le document n\'a pas ligne d\'articles</li>';
  }
  if (!isValidForTP(billing)) {
    _messageForDisabledAction += '<li>Le document n\'est pas valide pour être utilisé avec l\'avance immédiate de crédit d\'impôts (dates, montants, lignes d\'articles)</li>';
  }
  if (!isValidForSignature(billing)) {
    _messageForDisabledAction += '<li>Des informations sur le client sont incorrectes pour utiliser la signature en ligne</li>';
  }
  if (isPriceLowerThanZero(billing)) {
    _messageForDisabledAction += '<li>Le montant de votre document ne peut pas être inférieur à 0. Si vous souhaitez faire un remboursement ou une remise, préférez la création d\'un avoir.</li>';
  }
  if (!isAdvancesValid(billing)) {
    _messageForDisabledAction += '<li>Une ou plusieurs lignes d\'acomptes ne sont pas valides. Veuillez vérifier que les montants sont supérieurs à 0 et que les conditions de paiement sont correctes.</li>';
  }
  if (billing.useStripePayment && !isOnlinePaymentEnabled(stripeAccount) && !stripeAccount?.requirements?.disabled_reason?.includes('rejected.')) {
    _messageForDisabledAction += '<li>Vous devez mettre à jour la configuration de votre paiement en ligne avant de pouvoir l\'utiliser de nouveau ou contacter le support dans le cas où votre paiement en ligne est inutilisable.</li>';
  }
  if (!isFrequencyClientEmailValid(billing)) {
    _messageForDisabledAction += '<li>La récurrence est active avec l\'option d\'envoi d\'e-mail mais l\'adresse e-mail de votre client n\'est pas valide.<br>Désactivez cette option ou assurez-vous que votre client ait une adresse e-mail valide.</li>';
  }
  _messageForDisabledAction += '</ul>';
  return _messageForDisabledAction;
};

export const billingHasAlreadyHadFinalizedState = (billing: IBilling) => [BillingState.FINALIZED, BillingState.PAID, BillingState.SIGNED, BillingState.REFUSED].includes(billing.billingState);

export const isFinalizable = (billing: IBilling, stripeAccount: ReadStripeAccount | null) => (billing.customer || billing.opportunity)
  && (billingHasValidProducts(billing, billing.createdFromEstimate || billing.createdFromPurchaseOrder))
  && (billing.product && billing.product.length)
  && !isPriceLowerThanZero(billing)
  && (!billing.useStripePayment || (billing.useStripePayment && isOnlinePaymentEnabled(stripeAccount)))
  && (!billing.tiersPrestationIsActivatedForThisBilling || (billingIsValidForTP(billing) && billing?.paymentRequest?.dateDebutEmploi && billing?.paymentRequest?.dateFinEmploi))
  && (!billing.useSignature || (billing.useSignature && !signatureNotAvailable(billing).hasError));

export const billingIsSignedOnline = (billing: IBilling) => billing.billingState === BillingState.SIGNED
  && [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billing.billingType)
  && billing.signature && !billing.signature.canceledAt;

/**
 * Récupérer le document courant en fonction de l'état du fichier
 */
export const getCurrentFile = (billingDocument: IBilling): { id: string; url: string; name: string; relativeUrl: string } => {
  const filesByState = {
    [BillingState.FINALIZED]: billingDocument.finalizedFile,
    [BillingState.PAID]: billingDocument.paidFile,
    [BillingState.SIGNED]: billingDocument.signedFile,
    [BillingState.REFUSED]: billingDocument.refusedFile,
    [BillingState.DRAFT]: billingDocument.draftFile,
  };

  return {
    id: filesByState[billingDocument.billingState]?.id || '',
    url: filesByState[billingDocument.billingState]?.url || '',
    relativeUrl: filesByState[billingDocument.billingState]?.relativeUrl || '',
    name: billingDocument.number || 'Brouillon',
  };
};
