import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'

export const useMarkAsNotRefused = () => {
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    await billingRepository.markAsNotRefused(billing.id)
  }
}
