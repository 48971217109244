import type { ReadTimeRecord, IBilling } from '@abby/core-legacy'
import {
  BillingType,
  calculateDuration,
  calculateQuantity,
  formatCents,
  getLineAmount,
  ProductUnit,
} from '@abby/core-legacy'
import { useStore } from '@nuxtjs/composition-api'
import { useTimeTrackingSelectionStore } from '../useTimeTrackingSelection.store'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'

export const useCreateInvoicesFromTimeRecords = () => {
  const store = useStore()
  const toasterManager = useToasterManager()
  const { reset } = useTimeTrackingSelectionStore()

  return async (timeRecords: ReadTimeRecord[]) => {
    try {
      const opportunityIds = timeRecords.map(t => t.opportunity?.id).filter(t => t)
      const uniqueValues = new Set(opportunityIds)
      const hasDifferentOpportunities = uniqueValues.size > 1
      const firstOpportunityFound = timeRecords.find(t => t.opportunity)?.opportunity

      const billingRelatesTo = {
        ...hasDifferentOpportunities
          ? { customer: firstOpportunityFound?.customer }
          : {
            opportunity: firstOpportunityFound || null,
            opportunityId: firstOpportunityFound?.id || null,
          },
      }

      const billingDocument: Partial<IBilling> = {
        ...billingRelatesTo,
        billingType: BillingType.INVOICE,
        product: timeRecords.map((timeRecord) => {
          const duration = calculateDuration(timeRecord.startDate, timeRecord.endDate)
          const quantity = calculateQuantity(duration, timeRecord.unit || ProductUnit.HEURE)
          const lineAmount = getLineAmount({ quantity, unitPrice: timeRecord.unitRate, designation: '' })

          return {
            designation: timeRecord.name || '',
            quantity,
            productUnit: timeRecord.unit || ProductUnit.HEURE,
            unitPrice: timeRecord.unitRate,
            priceWithoutTaxBeforeDiscount: formatCents(lineAmount.priceWithoutTaxBeforeDiscount).text,
            priceWithoutTax: formatCents(lineAmount.priceWithoutTax).text,
            priceTotalTax: formatCents(lineAmount.priceTotalTax).text,
            discount: lineAmount.discount ? formatCents(-lineAmount.discount).text : undefined,
          }
        }),
      }

      await store.dispatch('billingCreation/openCreationBilling', { billingDocument, createFromTimeRecords: timeRecords }, { root: true })
    } catch (e: any) {
      toasterManager.autoError(e)
    } finally {
      reset()
    }
  }
}
