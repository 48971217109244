import { styles } from './style';
import { header } from './header';
import { lines } from './lines';
import { total } from './total';
import { footer } from './footer';
import { background } from './background';
import { BillingDocumentPDFData, BillingTemplate, GeneratePdfOptions } from '../../../types/Interface/api/pdf/PDF';

export const injectItem = (condition: boolean, item: any) => {
  if (typeof item === 'function') {
    return condition ? item() : [];
  }
  return condition ? [item] : [];
};

export const preprocessPDFMakeHTML = (html: string) => html

/* Preprocess des styles bold, em, s, u pour gérer leur affichage avec des couleurs */

  .replace(/<span style="color: (#[A-Fa-f0-9]+)">((?:<(?:strong|em|s|u)>)*)(.*?)((?:<\/(?:strong|em|s|u)>)*)<\/span>/g,
    (_, color, openTags, text) => {
      const styles: Record<string, any> = { color };

      if (openTags.includes('<strong>')) styles.bold = true;
      if (openTags.includes('<em>')) styles.italics = true;
      if (openTags.includes('<s>')) styles.decoration = 'lineThrough';
      if (openTags.includes('<u>')) styles.decoration = 'underline';

      return `<span data-pdfmake='${JSON.stringify(styles)}'>${text}</span>`;
    })
  .replace(/<(strong|em|s|u)>((?:<(?:strong|em|s|u)>)*)(.*?)((?:<\/(?:strong|em|s|u)>)*)<\/\1>/g,
    (_, tag, otherOpenTags, text) => {
      const styles: Record<string, any> = {};

      const tagMap = {
        strong: { bold: true },
        em: { italics: true },
        s: { decoration: 'lineThrough' },
        u: { decoration: 'underline' },
      };

      // Appliquer le style principal
      Object.assign(styles, tagMap[tag as keyof typeof tagMap]);

      // Appliquer les styles imbriqués
      if (otherOpenTags) {
        if (otherOpenTags.includes('<strong>')) styles.bold = true;
        if (otherOpenTags.includes('<em>')) styles.italics = true;
        if (otherOpenTags.includes('<s>')) styles.decoration = 'lineThrough';
        if (otherOpenTags.includes('<u>')) styles.decoration = 'underline';
      }

      return `<span data-pdfmake='${JSON.stringify(styles)}'>${text}</span>`;
    })

/* Preprocess des checkbox lists */

  .replace(/data-type="taskList"/gm, `data-pdfmake='${JSON.stringify({ type: 'taskList' })}'`)
  .replace(/<p/g, '<p style="white-space:break-spaces"')
  .replace(/<li[^>]*data-checked="true"[^>]*>(.*?)<input\s+type="checkbox"\s*([^>]*)\/?>/g, (match, p1, p2) => {
    return match.replace(/<input\s+type="checkbox"\s*([^>]*)\/?>/, `<input type="checkbox" checked="checked" ${p2} />`);
  })
  .replace(/<li[^>]*data-checked="true"[^>]*>/g, (match) => {
    return match.replace(/data-checked="true"/, '');
  })
  .replace(/checked="checked"/g, `data-pdfmake='${JSON.stringify({ type: 'checked' })}'`);
;

export const postprocessPDFMakeHTML = (data: any[]) => {
  const output = [];
  for (let i = 0; i < data.length; i += 1) {
    const result = data[i];

    if (result.text && Array.isArray(result.text)) {
      result.text = result.text.map((textItem: any) => {
        if (textItem.text && textItem['data-pdfmake']) {
          const styles = JSON.parse(textItem['data-pdfmake']);
          delete textItem['data-pdfmake'];
          return { ...textItem, ...styles };
        }
        return textItem;
      });
    }

    if (result.type === 'taskList') {
      const { ul, ..._result } = result;
      const taskList = {
        ..._result,
        stack: ul.map((ulItem: any) => {
          const isChecked = ulItem?.stack?.[0]?.text?.[0]?.text?.[0]?.type === 'checked' || ulItem?.stack?.[0]?.type === 'checked';
          const checkboxText = isChecked ? 'c' : 'e';
          const additionalText = ulItem?.stack?.[1]?.stack ?? [{ text: ulItem?.stack?.[2]?.text ?? '' }];

          return {
            text: [
              { text: checkboxText, font: 'Fontello', fontSize: 8 },
              { text: ' ' },
              ...additionalText,
            ],
          };
        }),
      };
      output.push(taskList);
    }
    else if (result.nodeName === 'P' && result.text === '') {
      output.push({
        ...result,
        text: ' ',
      });
    }
    else {
      output.push(result);
    }
  }
  return output;
};

export enum BillingText {
  EMISSION_DATE = 'emissionDate',
  SIRET_NUMBER = 'siretNumber',
  SIRET_ATTRIBUTION = 'siretAttribution',
  APPROVAL = 'approval',
  VAT_NUMBER = 'vatNumber',
  RCS_NUMBER = 'rcsNumber',
  STATUS = 'status',
  DOCUMENT_NUMBER = 'documentNumber',
  PAYMENT_DUE_DATE = 'paymentDueDate',
  VALIDITY_DATE = 'validityDate',
  DELIVERY_DATE = 'deliveryDate',
  DELIVERY_ADDRESS = 'deliveryAddress',
  PAYMENT_DATE = 'paymentDate',
  DRAFT_DOCUMENT = 'draftDocument',
  PAID = 'paid',
  DRAFT = 'draft',
  SIGNED = 'signed',
  REFUSED = 'refused',
  PAYMENT_TERM = 'paymentTerm',
  LATE_PAYMENT_PENALTY = 'latePaymentPenalty',
  FLAT_RATE_COLLECTION_FEES = 'flatRateCollectionFees',
  DISCOUNT_ADVANCE_PAYMENT = 'discountAdvancePayment',
  PAYMENT_METHODS = 'paymentMethod',
  BANK_DETAILS = 'bankDetails',
  BANK = 'bank',
  TOTAL_WITH_OUT_TAX = 'totalWithoutTax',
  TOTAL_WITH_TAX = 'totalWithTax',
  FINAL_TOTAL_WITH_OUT_TAX = 'finalTotalWithoutTax',
  TOTAL_DISCOUNT = 'totalDiscount',
  VAT = 'vat',
  SIGN_DOCUMENT_ONLINE = 'signDocumentOnline',
  GO_TO_DOCUMENT_SIGNATURE_PAGE = 'goToDocumentSignaturePage',
  PAY_DOCUMENT_ONLINE = 'payDocumentOnline',
  GO_TO_DOCUMENT_PAYMENT_PAGE = 'goToDocumentPaymentPage',
  WHICH = 'which',
  GOOD_FOR_APPROVAL = 'goodForApproval',
  SIGNED_AT = 'signedAt',
  TO = 'to',
  ADVANCES_PAID = 'advancesPaid',
  REMAINING_TOTAL_WITH_OUT_TAX = 'remainingTotalWithoutTax',
  REMAINING_TOTAL_WITH_TAX = 'remainingTotalWithTax',
  APPLIED_EXCHANGE_RATE = 'appliedExchangeRate',
  WITH_TAX = 'withTax',
  WITHOUT_TAX = 'withoutTax',
  AT_SIGNATURE = 'atSignature',
  AT_ORDER = 'atOrder',
  ADVANCE = 'advance',
  PAYMENT_TERMS = 'paymentTerms',
  TITLE_OF_THE_DOCUMENT = 'titleOfTheDocument',
}

export type BillingLocalText = {
  [K in BillingText]: string
};

export type GenerateBillingPdfOptions = {
  memberOfAnApprovedAssociation: string;
  data: BillingDocumentPDFData;
  localeText: BillingLocalText;
  documentTypeText: string;
  options: GeneratePdfOptions['options'];
  columns: { text: string; type: string }[];
  logo: { image: string; isPreset: boolean; dimensions: { width: number; height: number } };
  defaultLogo: string;
  base64Shape?: string;
  footerLogos: { image: string; dimensions: { width: number; height: number } }[];
  isLogoSVG?: boolean;
  products: BillingDocumentPDFData['products'];
};

export const generateBillingPdf = ({
  data,
  columns,
  logo,
  defaultLogo,
  base64Shape,
  products,
  documentTypeText,
  localeText,
  footerLogos,
  memberOfAnApprovedAssociation,
  isLogoSVG,
  options,
}: GenerateBillingPdfOptions) => ({
  ...(options?.specimen
    ? {
        watermark: { text: 'SPECIMEN', bold: true, opacity: 0.2 },
      }
    : {}),
  styles: styles(data.style),
  content: [
    ...header(documentTypeText, data, logo, localeText),
    ...injectItem(!!data.headerNote, () => ([
      data.headerNote,
      '\n',
    ])),
    ...lines(columns, products, data),
    total(data, localeText),
    ...injectItem(!!data.mentionMediator, () => ([
      data.mentionMediator,
      '\n',
    ])),
    '\n',
    ...injectItem(!!data.footerNote, () => ([data.footerNote])),
    ...injectItem(data.displayMemberOfAnApprovedAssociation, {
      margin: [0, 5, 0, 0],
      fontSize: 8,
      style: 'baseColor',
      unbreakable: true,
      text: memberOfAnApprovedAssociation,
    }),
    ...injectItem(!!data.generalTermsAndConditionsOfSale, () => ([
      {
        pageBreak: 'before',
        text: '',
      },
      data.generalTermsAndConditionsOfSale,
    ])),
  ],
  images: {
    ...logo
      ? {
          logo: logo.image,
        }
      : {},
  },
  footer: (currentPage: number, pageCount: number) => footer(currentPage, pageCount),
  background: (currentPage: number, pageSize: { width: number; height: number }) => background(currentPage,
    pageSize,
    footerLogos,
    logo && data.displayAbbyLogo ? defaultLogo : null,
    isLogoSVG,
    data.template !== BillingTemplate.TEMPLATE_1 ? base64Shape : null),
  pageBreakBefore(currentNode: any, followingNodesOnPage: any) {
    return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
  },
  pageMargins: [25, base64Shape ? 60 : 17.5, 25, 50],
});
