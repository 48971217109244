import {
  InvalidUrssafCustomerRegistrationReason,
  SapAddress,
  UrssafError,
} from '@abby/shared'
import pickBy from 'lodash/pickBy'
import { ContactTPForm } from '~/services/client/contact/forms/ContactTP.form'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useClientStore } from '~/composables/client/_common/useClient.store'
import {
  useCreateContactFormFromContactTPForm,
} from '~/composables/client/sap/action/useCreateContactFromContactTPForm'

const AllUrssafErrors = {
  ...UrssafError,
  ...InvalidUrssafCustomerRegistrationReason,
}
type TypeOfAllUrssafErrors =
  | InvalidUrssafCustomerRegistrationReason
  | UrssafError;

export const useUpdateContactTP = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()
  const clientStore = useClientStore()
  const createContactFormFromContactTPForm = useCreateContactFormFromContactTPForm()

  return async (contactTP: ContactTPForm) => {
    const updatedContact = await contactRepository.updateContact(
      createContactFormFromContactTPForm(contactTP),
    )
    if (!updatedContact) {
      alertManager.autoError('Erreur inconnue')
      return
    }
    try {
      await contactRepository.registerTierPrestation({
        ...contactTP,
        address: pickBy(contactTP.address, value => !!value) as SapAddress,
        contactId: updatedContact.id,
      })
      alertManager.success('Le contact a bien été mis à jour')
      clientStore.toggleClientFormSidePanel(false)
    } catch (e: any) {
      if (
        Array.isArray(e.response.data?.data) &&
        e.response.data.data.map((e: string) =>
          Object.values(AllUrssafErrors).includes(e as TypeOfAllUrssafErrors),
        )
      ) {
        clientStore.urssafTPErrors.value = e.response.data.data
      } else {
        clientStore.toggleClientFormSidePanel(false)
      }
    }
  }
}
