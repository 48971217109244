import { defineStore, storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useStockManagementRepository } from '~/composables/product/stock/useStockManagement.repository'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'

export const useStockManagement = () => {
  const useStockManagement = defineStore('stockManagement', () => {
    const stockManagementRepository = useStockManagementRepository()
    const planManager = usePlanManager()

    const { data: stockConfiguration } = stockManagementRepository.fetchStockConfiguration({
      enabled: planManager.hasAccessToFeature(planManager.features.stock_management),
    })
    const isStockManagementEnabled = computed<boolean>(() => !!stockConfiguration.value?.isActivated)

    const enableStockManagement = async () => {
      await stockManagementRepository.enableStockManagement()
    }

    const disableStockManagement = async () => {
      await stockManagementRepository.disableStockManagement()
    }

    return {
      stockConfiguration,
      isStockManagementEnabled,
      enableStockManagement,
      disableStockManagement,
    }
  })

  const stockManagementStore = useStockManagement()
  const { isStockManagementEnabled, stockConfiguration } = storeToRefs(stockManagementStore)
  const { enableStockManagement, disableStockManagement } = stockManagementStore

  return {
    isStockManagementEnabled,
    stockConfiguration,
    enableStockManagement,
    disableStockManagement,
  }
}
