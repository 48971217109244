import { BillingLocale, BillingState, BillingType, CurrencyCode } from '@abby/shared'
import * as PhoneNumber from 'awesome-phonenumber'
import { BillingAction } from '~/services/billing/_common/enums/BillingAction.enum'
import { BillingPaymentRequest } from '~/services/billing/_common/entities/BillingPaymentRequest.entity'
import { BillingDate } from '~/services/billing/_common/enums/BillingDate.enum'
import { BillingSignatureErrors } from '~/services/billing/_common/enums/BillingSignatureErrors.enum'
import {
  BillingAmount,
  BillingAmountProps,
} from '~/services/billing/_common/valueObjects/BillingAmount.valueObject'
import { BillingReminderActivity } from '~/services/billing/_common/valueObjects/BillingReminderActivity.valueObject'
import { BillingCustomer } from '~/services/billing/_common/entities/BillingCustomer.entity'

export type BillingLabel = {
  icon: string;
  label: string;
}

export type BillingDateDetail = {
  date: Date;
  type: BillingDate
}

type Parent = {
  id: string;
  number: string;
  type: BillingType;
}

type BillingItemProps = {
  id: string;
  emittedAt: Date;
  paidAt?: Date;
  signedAt?: Date;
  refusedAt?: Date;
  expiredAt?: Date;
  archivedAt?: Date;
  dueAt?: Date;
  number?: string;
  title?: string;
  type: BillingType;
  customer: BillingCustomer;
  state: BillingState;
  finalizable: boolean;
  amount: BillingAmountProps;
  currencyAmount?: BillingAmountProps;
  currency?: CurrencyCode;
  createdFromEstimate?: string;
  createdFromPurchaseOrder?: string;
  createdFromInvoice?: string;
  lastSendByEmailAt?: Date;
  opportunityId?: string;
  opportunity?: { id: string; name: string; };
  locked?: boolean;
  parent?: Parent;
  withElectronicSignature?: boolean;
  useOnlinePayment?: boolean;
  nextBillingAt?: Date;
  adminActionsActivated?: boolean;
  locale?: BillingLocale;
  isReminderActivated: boolean;
  hasAssociatedTransaction: boolean;
  isEditable?: boolean;
  lastDownloadAt?: Date;
  lastRemindedAt?: Date;
  paymentRequest?: BillingPaymentRequest;
  tiersPrestationIsActivatedForThisBilling?: boolean;
  electronicSignature?: {
    id: string
  };
}

const isHumanNameValidYousign = (name: string): boolean => {
  if (!name || name.length > 150) { return false }
  return /^(?!\s)[0-9A-Za-zĄÀÁÂÃÄÅÇĆÈÉÊËĘÌÍÎÏŁÑŃÒÓÔÕÖŚÙÚÛÜÝŹŻąàáâãäåæçćèéêëęìíîïłñńòóôõöśùúûüýÿźżß`'()\- ]+(?!\s)$/.test(name)
}

const isMailValidYousign = (mail: string): boolean => {
  if (!mail) { return false }
  return /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(mail)
}

const isPhoneValid = (phone: string): boolean => {
  const phoneNumber = PhoneNumber.parsePhoneNumber(phone || '')
  return phoneNumber.valid && phoneNumber.typeIsMobile
}

export class BillingItem {
  readonly id: string;
  readonly emittedAt: Date;
  readonly number: string;
  readonly type: BillingType;
  readonly isDraft: boolean = false;
  readonly labels: BillingLabel[] = [];
  readonly dateDetails: BillingDateDetail[] = [];
  readonly customer!: BillingCustomer;
  readonly title?: string;
  readonly paidAt?: Date;
  signedAt?: Date;
  readonly refusedAt?: Date;
  readonly expiredAt?: Date;
  archivedAt?: Date;
  readonly state: BillingState;
  readonly finalizable: boolean;
  readonly dueAt?: Date;
  readonly createdFromInvoice?: string;
  readonly lastSendByEmailAt?: Date;
  readonly opportunityId?: string;
  readonly opportunity?: { id: string; name: string; };
  readonly locked?: boolean = false;
  readonly parent?: Parent;
  readonly withElectronicSignature?: boolean;
  readonly useOnlinePayment?: boolean;
  readonly nextBillingAt?: Date;
  readonly adminActionsActivated: boolean = false;
  readonly isReminderActivated: boolean;
  readonly hasAssociatedTransaction: boolean;
  readonly isEditable?: boolean;
  lastDownloadAt?: Date;
  readonly lastRemindedAt?: Date;
  readonly paymentRequest?: BillingPaymentRequest;

  readonly locale: BillingLocale;
  readonly amount: BillingAmount;
  readonly currencyAmount?: BillingAmount;
  readonly currency?: CurrencyCode;
  readonly tiersPrestationIsActivatedForThisBilling?: boolean;
  readonly electronicSignature?: {
    id: string
  };

  reminders: BillingReminderActivity[] = [];

  actions: BillingAction[] = [
    BillingAction.DOWNLOAD,
    BillingAction.VIEW_DETAILS,
    BillingAction.EDIT_TITLE,
  ];

  private constructor (props: BillingItemProps) {
    this.id = props.id
    this.emittedAt = props.emittedAt
    this.number = props.number || 'Brouillon'
    this.type = props.type
    this.isDraft = props.state === BillingState.DRAFT
    this.customer = props.customer
    this.title = props.title
    this.state = props.state
    this.createdFromInvoice = props.createdFromInvoice
    this.paidAt = props.paidAt
    this.signedAt = props.signedAt
    this.refusedAt = props.refusedAt
    this.expiredAt = props.expiredAt
    this.dueAt = props.dueAt
    this.lastSendByEmailAt = props.lastSendByEmailAt
    this.opportunityId = props.opportunityId
    this.opportunity = props.opportunity
    this.archivedAt = props.archivedAt
    this.finalizable = props.finalizable
    this.locked = props.locked
    this.parent = props.parent
    this.withElectronicSignature = props.withElectronicSignature
    this.useOnlinePayment = props.useOnlinePayment
    this.nextBillingAt = props.nextBillingAt
    this.adminActionsActivated = props.adminActionsActivated || false
    this.locale = props.locale || BillingLocale.FR
    this.currency = props.currency || CurrencyCode.EUR
    this.isReminderActivated = props.isReminderActivated || false
    this.hasAssociatedTransaction = props.hasAssociatedTransaction || false
    this.lastDownloadAt = props.lastDownloadAt
    this.lastRemindedAt = props.lastRemindedAt
    this.paymentRequest = props.paymentRequest
    this.isEditable = props.isEditable || false
    this.tiersPrestationIsActivatedForThisBilling = props.tiersPrestationIsActivatedForThisBilling
    this.electronicSignature = props.electronicSignature

    this.amount = BillingAmount.create(props.amount)
    if (props.currencyAmount) {
      this.currencyAmount = BillingAmount.create(props.currencyAmount)
    }

    this.computeActions()
    this.computeDateDetails()
  }

  static create (props: BillingItemProps): BillingItem {
    return new BillingItem(props)
  }

  get isQuote () {
    return [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(this.type)
  }

  get isAdvance () {
    return this.type === BillingType.ADVANCE
  }

  get isInvoice () {
    return this.type === BillingType.INVOICE
  }

  isFinalized () {
    return this.state === BillingState.FINALIZED
  }

  get isLate () {
    return this.dueAt && this.dueAt < new Date()
  }

  get isExpired () {
    return this.expiredAt && this.expiredAt < new Date()
  }

  hasCustomerOrganizationWithoutContact (): boolean {
    return !!this.customer.organizationId && !this.customer.contactId
  }

  setReminders (reminders: BillingReminderActivity[]) {
    this.reminders = reminders
  }

  get remainingAmount (): number | undefined {
    return this.isInvoice ? this.amount.remainingAmountWithTax : this.amount.totalAmountWithTaxAfterDiscount
  }

  hasPartialPayment () {
    return !!(this.amount?.remainingReconciliateAmount &&
      this.remainingAmount &&
      this.state === BillingState.FINALIZED &&
      this.amount.remainingReconciliateAmount.value !== this.remainingAmount)
  }

  computeActions () {
    if (this.isDraft) {
      this.actions.push(
        BillingAction.EDIT,
        BillingAction.DELETE,
      )
    }

    if (this.isDraft && this.finalizable) {
      this.actions.push(BillingAction.FINALIZE, BillingAction.FINALIZE_AND_SEND)
    }

    if (this.locale !== BillingLocale.FR) {
      this.actions.push(BillingAction.DOWNLOAD_FRENCH_LOCALE)
    }

    if (!this.isDraft && this.isQuote && !this.locked) {
      this.actions.push(BillingAction.CREATE_FINAL_INVOICE, BillingAction.CREATE_ADVANCE)
    }

    if (!this.isDraft) {
      this.actions.push(BillingAction.SEND)
    }

    if (!this.isDraft && !this.archivedAt) {
      this.actions.push(BillingAction.ARCHIVE)
    }

    if (this.archivedAt) {
      this.actions.push(BillingAction.UNARCHIVE)
    }

    if (!this.isDraft &&
      !this.isQuote &&
      this.type !== BillingType.ASSET &&
      this.amount.cancelledAmountWithoutTax <= this.amount.totalAmountWithoutTaxAfterDiscount
    ) {
      this.actions.push(BillingAction.CREATE_ASSET, BillingAction.CANCEL)
    }

    if ([BillingType.INVOICE, BillingType.ADVANCE].includes(this.type) && [BillingState.FINALIZED, BillingState.DRAFT].includes(this.state)) {
      this.actions.push(BillingAction.MARK_AS_PAID)
    }
    if (this.type === BillingType.ASSET && [BillingState.FINALIZED, BillingState.DRAFT].includes(this.state)) {
      this.actions.push(BillingAction.MARK_AS_PAID_ASSET)
    }
    if ([BillingType.INVOICE, BillingType.ADVANCE, BillingType.ASSET].includes(this.type) && this.state === BillingState.PAID) {
      this.actions.push(
        BillingAction.MARK_AS_UNPAID,
      )
    }

    if (this.state !== BillingState.DRAFT) {
      this.actions.push(BillingAction.SEND)
    }
    if (this.state === BillingState.FINALIZED && this.isQuote) {
      this.actions.push(BillingAction.MARK_AS_SIGNED, BillingAction.MARK_AS_REFUSED)
    }
    if (this.state === BillingState.SIGNED && this.isQuote && !this.electronicSignature) {
      this.actions.push(BillingAction.MARK_AS_NOT_SIGNED)
    }
    if (this.state === BillingState.REFUSED && this.isQuote) {
      this.actions.push(BillingAction.MARK_AS_NOT_REFUSED)
    }

    if (this.isQuote && this.isEditable) {
      this.actions.push(
        BillingAction.EDIT,
      )
    }

    if (this.useOnlinePayment) {
      this.actions.push(
        BillingAction.GET_PAYMENT_LINK,
      )
    }

    if (this.withElectronicSignature && !this.signedAt) {
      this.actions.push(
        BillingAction.GET_SIGNATURE_LINK,
      )
    }

    if (this.withElectronicSignature && this.signedAt) {
      this.actions.push(
        BillingAction.DOWNLOAD_SIGNATURE_PROOF,
      )
    }

    if (!this.isQuote &&
        this.type !== BillingType.ASSET &&
        this.state === BillingState.FINALIZED &&
        !this.tiersPrestationIsActivatedForThisBilling) {
      this.actions.push(
        BillingAction.MANAGE_REMINDER,
      )
    }

    if (this.tiersPrestationIsActivatedForThisBilling) {
      this.actions.push(
        BillingAction.CHECK_URSSAF_TP_STATUT,
      )
    }

    if (this.type === BillingType.INVOICE && this.nextBillingAt) {
      this.actions.push(
        BillingAction.MANAGE_RECURRING,
      )
    }

    if (this.isQuote && this.state !== BillingState.SIGNED && !this.withElectronicSignature) {
      this.actions.push(BillingAction.ACTIVATE_ONLINE_SIGNATURE)
    }

    if (this.type === BillingType.INVOICE &&
        this.state === BillingState.FINALIZED &&
        !this.useOnlinePayment
    ) {
      this.actions.push(BillingAction.ACTIVATE_ONLINE_PAYMENT)
    }

    if (this.type === BillingType.INVOICE && this.state !== BillingState.DRAFT) {
      this.actions.push(BillingAction.MIGRATE_SAP_PRODUCTS)
    }

    if (!this.isAdvance) {
      this.actions.push(
        BillingAction.DUPLICATE_TO_INVOICE,
        BillingAction.DUPLICATE_TO_PURCHASE_ORDER,
        BillingAction.DUPLICATE_TO_ESTIMATE,
      )
    }

    if (!this.adminActionsActivated) { return }
    this.actions.push(
      BillingAction.ADMIN_DELETE_DOCUMENT,
      BillingAction.ADMIN_COPY_DOCUMENT_ID,
      BillingAction.ADMIN_COPY_BILLING_CONFIGURATION_ID,
      BillingAction.ADMIN_REGENERATE_DOCUMENT,
      BillingAction.ADMIN_FIX_CUSTOMER_ADDRESS,
      BillingAction.ADMIN_RESOLVE_NUMBER,
    )
  }

  computeDateDetails () {
    if (!this.paidAt && !this.signedAt && !this.refusedAt) {
      this.dateDetails.push({
        date: this.emittedAt,
        type: BillingDate.EMITTED_AT,
      })
    }
    if (this.paidAt) {
      this.dateDetails.push({
        date: this.paidAt,
        type: BillingDate.PAID_AT,
      })
    }
    if (this.signedAt) {
      this.dateDetails.push({
        date: this.signedAt,
        type: BillingDate.SIGNED_AT,
      })
    }
    if (this.refusedAt) {
      this.dateDetails.push({
        date: this.refusedAt,
        type: BillingDate.REFUSED_AT,
      })
    }
    if (this.isQuote && this.expiredAt && !this.signedAt) {
      this.dateDetails.push({
        date: this.expiredAt,
        type: BillingDate.EXPIRED_AT,
      })
    }
    if (!this.isQuote && !this.paidAt && this.dueAt) {
      this.dateDetails.push({
        date: this.dueAt,
        type: BillingDate.DUE_AT,
      })
    }
  }

  get electronicSignable () {
    const errors: BillingSignatureErrors[] = []
    if (this.hasCustomerOrganizationWithoutContact()) {
      errors.push(BillingSignatureErrors.ORGANIZATION_WITHOUT_CONTACT)
    }

    if (!this.customer.firstname) {
      errors.push(BillingSignatureErrors.FIRST_NAME_REQUIRED)
    } else if (!isHumanNameValidYousign(this.customer.firstname)) {
      errors.push(BillingSignatureErrors.FIRST_NAME_INVALID)
    }

    if (!this.customer.lastname) {
      errors.push(BillingSignatureErrors.LAST_NAME_REQUIRED)
    } else if (!isHumanNameValidYousign(this.customer.lastname)) {
      errors.push(BillingSignatureErrors.LAST_NAME_INVALID)
    }

    if (!this.customer.email) {
      errors.push(BillingSignatureErrors.EMAIL_REQUIRED)
    } else if (!isMailValidYousign(this.customer.email)) {
      errors.push(BillingSignatureErrors.EMAIL_INVALID)
    }

    if (!this.customer.phone) {
      errors.push(BillingSignatureErrors.PHONE_REQUIRED)
    } else if (!isPhoneValid(this.customer.phone)) {
      errors.push(BillingSignatureErrors.PHONE_INVALID)
    }

    return {
      isSignable: !errors.length,
      errors,
    }
  }
}
