
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import AbbyAlertManager from '~/components/abby/managers/AbbyAlertManager.vue'
import MarketingPlansModal from '~/components/marketing/modals/MarketingPlansModal.vue'

@Component({
  middleware: ['redirection', 'auth'],
  components: {
    MarketingPlansModal,
    AbbyAlertManager,
  },
})
export default class empty extends Vue {
  @Getter('app/isContainerFullHeight')
  isContainerFullHeight!: boolean

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  head () {
    return this.$brandManager.head()
  }
}
