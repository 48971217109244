
import Vue from 'vue'
import { Component, Getter } from 'nuxt-property-decorator'
import { AbbyPlans, CampaignType, ICreationCompany, IUser, StripeProductType } from '@abby/core-legacy'
import { WebinarType, Action } from '@abby/shared'
import TimeTrackingTimer from '~/components/productivity/timeTracking/inputs/TimeTrackingTimer.vue'
import UserNavigationMenu from '~/components-legacy/layout/UserNavigationMenu.vue'
import { CampaignTrigger } from '~/plugins/campaignsManager'
import { CommunityEvent } from '~/store/app'
import MarketingProActionNewCard from '~/components/marketing/cards/MarketingProActionNewCard.vue'
import { IAuthUser } from '~/store/auth'
import AbbyCalendarEvent from '~/components/abby/cards/AbbyCalendarEvent.vue'
import { useLayout } from '~/composables/abby/manager/action/useLayout.manager'
import { useOnboardingManager } from '~/composables/abby/manager/action/useOnboarding.manager'
import { useTrackingManager } from '~/composables/abby/manager/action/useTracking.manager'
import { useTimeTrackingStore } from '~/composables/productivity/timeTracking/useTimeTracking.store'

@Component({
  components: {
    CalendarEvent: AbbyCalendarEvent,
    MarketingProActionNewCard,
    AbbyGuideMenu: () => import('~/components/guide/menus/AbbyGuideMenu.vue'),
    TimeTrackingTimer,
    UserNavigationMenu,
  },
  setup () {
    const { offsetTop } = useLayout()
    const onboardingManager = useOnboardingManager()
    const trackingManager = useTrackingManager()
    const { timerIsRunning } = useTimeTrackingStore()
    return {
      offsetTop,
      onboardingManager,
      trackingManager,
      timerIsRunning,
    }
  },
})
export default class Navigation extends Vue {
  @Getter('app/appLoading') appLoading!: boolean;

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  @Getter('webinar/webinar')
  webinar!: WebinarType

  @Getter('webinar/queryLoading')
  webinarLoading!: boolean

  @Getter('auth/user')
  currentUser!: IUser

  @Getter('company/isInCreation')
  isInCreation!: boolean

  @Getter('company/isCompanyCreated')
  isCompanyCreated!: boolean

  @Getter('company/creation')
  creation!: ICreationCompany;

  @Getter('auth/user') user!: IAuthUser;

  @Getter('guide/countNotCompleted') countNotCompleted!: number

  @Getter('app/communityEvents') _communityEvents!: CommunityEvent[]
  @Getter('app/communityEventInNotification') communityEventInNotification!: CommunityEvent

  offsetTop!: number
  onboardingManager!: any
  trackingManager!: any
  timerIsRunning!: boolean

  async created () {
    const group = this.onboardingManager.getOnboardingGroup()
    await this.$store.dispatch('guide/setGuideType', group)
  }

  get displayGuideMenu () {
    return this.countNotCompleted && this.$campaignsManager.isDisplayable(CampaignType.START_3_GESTION)
  }

  get displayLearnOnHomepageButton () {
    return !this.$campaignsManager.isDisplayable(CampaignType.START_3_GESTION) && this.$route.path !== '/'
  }

  get communityEvents () {
    const query = {
      email: this.user?.email,
      name: this.user?.fullName,
      phone_number: this.user?.phone,
    }

    return [
      ...this._communityEvents.filter(e => e.menu),
    ]
  }

  get miniAbbyLogo () {
    return this.$brandManager.miniLogoUrl()
  }

  get fullName () {
    return `${this.currentUser?.firstname} ${this.currentUser?.lastname}`.trim()
  }

  get hasAbbyCreationStartBusiness () {
    return this.$planManager.has(StripeProductType.ABBY_CREATION_START_BUSINESS)
  }

  get isPaidAndMonthly () {
    return [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_START].includes(this.$planManager.whichPlanCompanyHas()) && this.$planManager.whichFrequencyPlanCompanyHas() === 'month'
  }

  get displayUpgradePlans () {
    return (!this.$planManager.has(StripeProductType.ABBY_BUSINESS) || this.$planManager.isTrial() || this.isPaidAndMonthly) && !this.hasAbbyCreationStartBusiness
  }

  displayIdeaBadge = false
  displayChangelogBadge = false
  displayCommunityEventsBadge = false

  get changelogBadgeCanBeDisplayed () {
    return this.$campaignsManager.isDisplayable(CampaignType.CHANGELOG_MENU, {
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70,
    })
  }

  get ideaBadgeCanBeDisplayed () {
    return this.$campaignsManager.isDisplayable(CampaignType.IDEAS_MENU, {
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70,
    })
  }

  get communityCanBeDisplayed () {
    return this.$campaignsManager.isDisplayable(CampaignType.COMMUNITY_EVENTS_MENU, {
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70,
    })
  }

  async openIdeasFrill () {
    // @ts-ignore
    const widget = await window.Frill('widget', {
      key: 'dfe92aaa-7b6e-4203-96ec-d01f0f407552',
    })
    widget.open()
    this.$campaignsManager.register({
      type: CampaignType.IDEAS_MENU,
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70, // 2 semaines
      priority: 3,
      action: () => {},
    })
    this.trackingManager.track({
      action: Action.ROADMAP_OPENED,
    })
    this.displayIdeaBadge = false
  }

  async openChangelogFrill () {
    // @ts-ignore
    const widget = await window.Frill('widget', {
      key: '70f5f094-ea4b-41c2-a77e-56a17bf1094a',
    })
    widget.open()
    this.$campaignsManager.register({
      type: CampaignType.CHANGELOG_MENU,
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70, // 2 semaines
      priority: 3,
      action: () => {},
    })
    this.trackingManager.track({
      action: Action.CHANGELOG_OPENED,
    })
    this.displayChangelogBadge = false
  }

  communityEventsMenuOpened (value: true) {
    if (value && this.displayCommunityEventsBadge) {
      this.$campaignsManager.register({
        type: CampaignType.COMMUNITY_EVENTS_MENU,
        trigger: CampaignTrigger.INSTANT,
        maximumDisplayFrequency: 70, // 2 semaines
        // maximumDisplayFrequency: 20_160, // 2 semaines
        priority: 3,
        action: () => {},
      })
    }
    this.displayCommunityEventsBadge = false
  }

  logoutAccountFromDashboard () {
    const id = this.currentUser.id
    this.logout()
    let baseURL
    if (this.$config.nodeEnv === 'development') {
      baseURL = 'http://dashboard.localhost:3000'
    } else if (this.$config.nodeEnv === 'staging') {
      baseURL = 'https://dashboard-next.abby.fr'
    } else {
      baseURL = 'https://dashboard.abby.fr'
    }
    window.open(`${baseURL}/clients/${id}`, '_self')
  }

  openMobileMenu () {
    this.$store.dispatch('app/openMobileMenu')
  }

  get adminFullName () {
    return this.connectedFromDashboard?.fullName
  }

  async logout () {
    try {
      await this.$store.dispatch('auth/logout')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }

  mounted () {
    setTimeout(() => {
      this.$campaignsManager.register({
        type: CampaignType.EVENT_INVITATION,
        trigger: CampaignTrigger.INSTANT,
        maximumDisplayFrequency: 720,
        priority: 3,
        action: () => {
          const eventToNotify = this.communityEvents.find((e) => {
            return !e.banner && this.$dayjs(e.date).isSameOrAfter(this.$dayjs().subtract(2, 'd')) && this.$dayjs(e.date).isSameOrBefore(this.$dayjs().add(2, 'd'))
          })
          if (eventToNotify) {
            this.$store.dispatch('app/openCommunityEventInNotification', eventToNotify)
          }
        },
      })
    }, 45_000)
    this.displayCommunityEventsBadge = this.communityCanBeDisplayed
    this.displayIdeaBadge = this.ideaBadgeCanBeDisplayed
    this.displayChangelogBadge = this.changelogBadgeCanBeDisplayed
  }

  closeEventNotification () {
    this.$store.dispatch('app/closeCommunityEventInNotification')
  }

  openCommunityEvent (event: CommunityEvent) {
    this.closeEventNotification()
    this.$store.dispatch('app/openCommunityEventInModal', event)
  }
}

