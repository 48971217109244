/* eslint-disable @typescript-eslint/no-shadow */
import { AxiosInstance } from 'axios'
import { resources } from './resources'

export * from './resources'
export const Api = (params: { url: string, loginWith?: any, axios: AxiosInstance }) => {
  const wrapAxios = (instance: any) => ({
    ...instance,
    async $get (...params: any[]) {
      const { data } = await instance.get(...params)
      return data
    },
    async $put (...params: any[]) {
      const { data } = await instance.put(...params)
      return data
    },
    async $post (...params: any[]) {
      const { data } = await instance.post(...params)
      return data
    },
    async $patch (...params: any[]) {
      const { data } = await instance.patch(...params)
      return data
    },
    async $delete (...params: any[]) {
      const { data } = await instance.delete(...params)
      return data
    },
  })

  const _resources = Object.entries(resources)
    .reduce((acc, [name, value]: [string, any]) => ({
      ...acc,
      // eslint-disable-next-line new-cap
      [name]: new value(wrapAxios(params.axios)),
    }), {})

  return {
    getToken (): string {
      // @ts-ignore
      return params.axios.defaults.headers.authorization
    },
    setToken (token: string) {
      // @ts-ignore
      params.axios.defaults.headers.authorization = `Bearer ${token}`
    },
    ..._resources,
  }
}
