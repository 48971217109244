export enum CronJob {
  BILLING_FREQUENCY = 'billingfrequency-generatenextbilling',
  COMPANY_UPDATE_DECLARATION_DASHBOARD_STEPS = 'company-updatedeclarationdashboardsteps',
  INVOICE_FETCH_INVOICES_LATE = 'invoice-fetchinvoiceslate',
  INVOICE_FETCH_ESTIMATE_LATE = 'invoice-fetchestimateslate',
  INVOICE_LAUNCH_REMINDER = 'invoice-launchreminder',
  JOBS_DESTROY_TRANSACTIONS = 'jobs-destroy-transactions',
  JOBS_REFRESH_BRIDGE_ACCOUNTS = 'jobs-refresh-bridge-accounts',
  EMAIL_UNLOCK_ALL_SENDINBLUE_CONTACT = 'email-unlockallsendinbluecontact',
  SIRENE_IMPORT_SIRENE_DATABASE = 'sirene-importsirenedatabase',
  TASK_LATE_TASK_REMINDER = 'task-latetaskreminder',
  URSSAF_TP_CHECK_PAYMENT_REQUEST = 'urssaf-tp-check-payment-request',
  URSSAF_REMIND_DECLARATION_IS_AVAILABLE = 'urssaf-reminddeclarationisavailable',
  HAPPYPAL_DISABLE_CE_ACCOUNT = 'happypal-disableceaccounts',
  TIER_PRESTATION_UPDATE_CUSTOMERS_STATUS = 'tierprestation-updatecustomersstatus',
  BANK_UPDATE_BANKS_AVAILABLE = 'bank-updatebanksavailable',
  DELETE_UNUSED_BANK_ITEMS = 'delete-unused-bank-items',
  TEMP_UPDATE_STRIPE_PERIOD = 'temp-update-stripe-period',
  TEMP_UPDATE_STRIPE_COMPANY_NAME = 'temp-update-stripe-company-name',
  TEMP_CLEAN_ALL_BROKEN_EMITTER = 'temp-clean-all-broken-emitter',
  TEMP_CLEAN_ALL_BROKEN_COMPANY_NAME_AS_FULL_NAME = 'temp-clean-all-broken-company-name-as-full-name',
  TEMP_CLEAN_ALL_VAT_ID_AS_VAT_ITEM = 'temp-clean-all-vat-id-as-vat-item',
  TEMP_MISSING_SAP_COMPLIANCE = 'temp-missing-sap-compliance',
  TEMP_CLEAN_ALL_CANCELED_AT = 'temp-clean-all-canceled-at',
  TEMP_CLEAN_ALL_SAP_COMPANY_WITHOUT_PERSONA = 'temp-clean-all-sap-company-without-persona',
  // TEMP_MISSING_VAT_IDS = 'temp-missing-vat-ids',
  // MIGRATE_CORRUPTED_SEALS = 'migrate-corrupted-seals',
}

export const cronJobs: {
  [key in CronJob]: string;
} = {
  [CronJob.BILLING_FREQUENCY]: '0 10,21 * * ? *',
  [CronJob.COMPANY_UPDATE_DECLARATION_DASHBOARD_STEPS]: '0 22 * * ? *',
  [CronJob.INVOICE_FETCH_INVOICES_LATE]: '0 6 * * ? *',
  [CronJob.INVOICE_FETCH_ESTIMATE_LATE]: '0 5 * * ? *',
  [CronJob.INVOICE_LAUNCH_REMINDER]: '30 3 * * ? *',
  [CronJob.JOBS_DESTROY_TRANSACTIONS]: '0 1 * * ? *',
  [CronJob.JOBS_REFRESH_BRIDGE_ACCOUNTS]: '0 4 * * ? *',
  [CronJob.EMAIL_UNLOCK_ALL_SENDINBLUE_CONTACT]: '0 3 * * ? *',
  [CronJob.SIRENE_IMPORT_SIRENE_DATABASE]: '0 0 2 * ? *',
  [CronJob.TASK_LATE_TASK_REMINDER]: '0 4 * * ? *',
  [CronJob.URSSAF_TP_CHECK_PAYMENT_REQUEST]: '0 23 * * ? *',
  [CronJob.URSSAF_REMIND_DECLARATION_IS_AVAILABLE]: '0 12 1,23,28 * ? *',
  [CronJob.HAPPYPAL_DISABLE_CE_ACCOUNT]: '0 1 * * ? *',
  [CronJob.TIER_PRESTATION_UPDATE_CUSTOMERS_STATUS]: '0 12 * * ? *',
  [CronJob.BANK_UPDATE_BANKS_AVAILABLE]: '0 1 * * ? *',
  [CronJob.DELETE_UNUSED_BANK_ITEMS]: '0 16 * * ? *',
  [CronJob.TEMP_UPDATE_STRIPE_PERIOD]: '0 14 * * ? *',
  [CronJob.TEMP_UPDATE_STRIPE_COMPANY_NAME]: '0 14 * * ? *',
  [CronJob.TEMP_CLEAN_ALL_BROKEN_EMITTER]: '0 13 * * ? *',
  [CronJob.TEMP_CLEAN_ALL_BROKEN_COMPANY_NAME_AS_FULL_NAME]: '0 13 * * ? *',
  [CronJob.TEMP_MISSING_SAP_COMPLIANCE]: '0 13 * * ? *',
  [CronJob.TEMP_CLEAN_ALL_CANCELED_AT]: '0 12 * * ? *',
  [CronJob.TEMP_CLEAN_ALL_SAP_COMPANY_WITHOUT_PERSONA]: '0 15 * * ? *',
  // [CronJob.TEMP_MISSING_VAT_IDS]: '55 16 * * ? *',
  // [CronJob.MIGRATE_CORRUPTED_SEALS]: '10 21 * * ? *',
  [CronJob.TEMP_CLEAN_ALL_VAT_ID_AS_VAT_ITEM]: '0 16 * * ? *',
};
