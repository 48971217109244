import { ContactForm } from '~/services/client/contact/forms/Contact.form'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useUpdateContact = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (contact: ContactForm) => {
    const newContact = await contactRepository.updateContact(contact)
    alertManager.success('Le contact a bien été mis à jour')
    busManager.emit('contactUpdated', newContact)
    return newContact
  }
}
