import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'
import { AbbyError } from '~/plugins/toasterManager'
import { useApi } from '~/composables/abby/manager/action/useApi.manager'

export const useActivityRepository = () => {
  const queryClient = useQueryClient()
  const toasterManager = useToasterManager()
  const api = useApi()

  const fetchActivities = () => useQuery({
    queryClient,
    queryKey: ['activities'],
    queryFn: async () => {
      return await api.activity.getAll()
    },
    onError: (error: AbbyError) => {
      toasterManager.autoError(error)
    },
  })

  const fetchParentActivities = () => useQuery({
    queryClient,
    queryKey: ['parentActivities'],
    queryFn: async () => {
      return await api.activity.getAllParentActivities()
    },
    onError: (error: AbbyError) => {
      toasterManager.autoError(error)
    },
  })

  return {
    fetchActivities,
    fetchParentActivities,
  }

}
