import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { QueryHelpCenterUseCase, SegmentEvent } from '@abby/core-legacy'
import type { IHelpCenterUseCase } from '@abby/core-legacy'
import { IHelpdeskArticlePreview } from '~/plugins/help'

export interface HelpCenterState {
  helpCenterIsOpen: boolean
  helpCenterButtonIsHidden: boolean
  useCases: IHelpCenterUseCase[]
  helpDeskArticles: IHelpdeskArticlePreview[] | null
  search: string
  unreadMessages: number,
}

export const state = (): HelpCenterState => ({
  helpCenterIsOpen: false,
  useCases: [],
  helpDeskArticles: null,
  helpCenterButtonIsHidden: false,
  unreadMessages: 0,
  search: '',
})

export const getters: GetterTree<HelpCenterState, HelpCenterState> = {
  helpCenterIsOpen: state => state.helpCenterIsOpen,
  useCases: state => state.useCases,
  helpDeskArticles: state => state.helpDeskArticles,
  helpCenterButtonIsHidden: state => state.helpCenterButtonIsHidden,
  search: state => state.search,
  unreadMessages: state => state.unreadMessages,
}

export const mutations: MutationTree<HelpCenterState> = {
  RESET (_currentState: HelpCenterState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_HELP_CENTER_IS_OPEN (state, value: boolean) {
    state.helpCenterIsOpen = value
  },
  SET_USE_CASES (state, value: IHelpCenterUseCase[]) {
    state.useCases = value
  },
  SET_SEARCH (state, value: string) {
    state.search = value
  },
  SET_HELP_DESK_ARTICLES (state, value: IHelpdeskArticlePreview[]) {
    state.helpDeskArticles = value
  },
  SET_HELP_CENTER_BUTTON_IS_HIDDEN (state, value: boolean) {
    state.helpCenterButtonIsHidden = value
  },
  SET_UNREAD_MESSAGES (state, value: number) {
    state.unreadMessages = value
  },
}

export const actions: ActionTree<HelpCenterState, HelpCenterState> = {
  openHelpCenter ({ commit }) {
    commit('SET_HELP_CENTER_IS_OPEN', true)
  },
  closeHelpCenter ({ commit, dispatch }) {
    commit('SET_HELP_CENTER_IS_OPEN', false)
    commit('SET_SEARCH', '')
    dispatch('setHelpDeskArticles', { query: '', results: null })
  },
  async fetchUseCases ({ commit }, query: QueryHelpCenterUseCase) {
    const usesCases = await this.$api.helpCenter.getUseCases(query)
    commit('SET_USE_CASES', usesCases)
  },
  setHelpDeskArticles ({ commit }, results: IHelpdeskArticlePreview[]) {
    commit('SET_HELP_DESK_ARTICLES', results)
  },
  hideHelpCenterButton ({ commit }) {
    commit('SET_HELP_CENTER_BUTTON_IS_HIDDEN', true)
  },
  showHelpCenterButton ({ commit }) {
    commit('SET_HELP_CENTER_BUTTON_IS_HIDDEN', false)
  },
  updateUnreadMessages ({ commit }, value: number) {
    commit('SET_UNREAD_MESSAGES', value)
  },
  openSupportPhoneModal ({ commit }) {
    commit('SET_IS_SUPPORT_PHONE_MODAL_OPENED', true)
  },
  closeSupportPhoneModal ({ commit }) {
    commit('SET_IS_SUPPORT_PHONE_MODAL_OPENED', false)
  },
}
