import { SetDefaultContact } from '@abby/shared'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useUpdateDefineAsPrimaryContact = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()

  return async (organizationId: string, payload: SetDefaultContact) => {
    const editedContact = await contactRepository.updateDefineAsPrimaryContact(organizationId, payload)
    alertManager.success('Le contact est désormais le contact par défaut de son organisation associée.')
    return editedContact
  }
}
