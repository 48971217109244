import { IDefaultTemplateEmailCustomisation } from '../Interface/models/IDefaultTemplateEmailCustomisation';
import { EmailType } from './EmailType';

export const DefaultTemplateEmailCustomisation: Array<IDefaultTemplateEmailCustomisation> = [

  // INVOICES

  {
    type: EmailType.INVOICE_SIMPLE,
    forVat: false,
    subject: 'Document disponible : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Vous trouverez le document {{ numero du document }} d\'un montant de {{ montant HT }} en pièce jointe.</p>'
      + '<p></p>'
      + '{{ page de paiement }}'
      + '<p></p>',
  },
  {
    type: EmailType.INVOICE_SIMPLE,
    forVat: true,
    subject: 'Document disponible : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Vous trouverez le document {{ numero du document }} d\'un montant de {{ montant TTC }} TTC ({{ montant HT }} HT) en pièce jointe.</p>'
      + '<p></p>'
      + '{{ page de paiement }}'
      + '<p></p>',
  },

  {
    type: EmailType.INVOICE_RECURRING,
    forVat: false,
    subject: 'Document disponible : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Vous trouverez le document {{ numero du document }} d\'un montant de {{ montant HT }} en pièce jointe.</p>'
      + '<p></p>'
      + '{{ page de paiement }}'
      + '<p></p>',
  },
  {
    type: EmailType.INVOICE_RECURRING,
    forVat: true,
    subject: 'Document disponible : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Vous trouverez le document {{ numero du document }} d\'un montant de {{ montant TTC }} TTC ({{ montant HT }} HT) en pièce jointe.</p>'
      + '<p></p>'
      + '{{ page de paiement }}'
      + '<p></p>',
  },

  {
    type: EmailType.INVOICE_REMINDER,
    forVat: false,
    subject: 'Relance pour paiement du document : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Le {{ date emission }}, je vous ai adressé {{ type de document }} {{ numero du document }}. '
      + 'Sauf erreur de ma part, le règlement de cette facture n\'a pas encore été effectué.</p>'
      + '<p></p>'
      + '<p>Date d\'échéance : {{ date echeance }}</p>'
      + '<p>Montant : {{ montant HT }}</p>'
      + '<p></p>'
      + '{{ page de paiement }}'
      + '<p></p>'
      + '<p>Si vous avez déjà effectué ce paiement, veuillez accepter mes excuses et ignorer ce rappel.</p>',
  },
  {
    type: EmailType.INVOICE_REMINDER,
    forVat: true,
    subject: 'Relance pour paiement du document : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Le {{ date emission }}, je vous ai adressé {{ type de document }} {{ numero du document }}. '
      + 'Sauf erreur de ma part, le règlement de cette facture n\'a pas encore été effectué.</p>'
      + '<p></p>'
      + '<p>Date d\'échéance : {{ date echeance }}</p>'
      + '<p>Montant HT : {{ montant HT }}</p>'
      + '<p>Montant TTC : {{ montant TTC }}</p>'
      + '<p></p>'
      + '{{ page de paiement }}'
      + '<p></p>'
      + '<p>Si vous avez déjà effectué ce paiement, veuillez accepter mes excuses et ignorer ce rappel.</p>',
  },

  // ESTIMATES

  {
    type: EmailType.ESTIMATE_SIMPLE,
    forVat: false,
    subject: 'Document disponible : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Vous trouverez le document {{ numero du document }} d\'un montant de {{ montant HT }} en pièce jointe.</p>'
      + '<p></p>'
      + '{{ page de signature du devis }}'
      + '<p></p>',
  },
  {
    type: EmailType.ESTIMATE_SIMPLE,
    forVat: true,
    subject: 'Document disponible : {{ numero du document }}',
    email: '<p>Bonjour,</p>'
      + '<p></p>'
      + '<p>Vous trouverez le document {{ numero du document }} d\'un montant de {{ montant TTC }} TTC ({{ montant HT }} HT) en pièce jointe.</p>'
      + '<p></p>'
      + '{{ page de signature du devis }}'
      + '<p></p>',
  },
];
