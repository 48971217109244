import debounce from 'lodash/debounce'
import {
  getCacheByAxiosConfig,
  requestInterceptor,
  isCacheableMethod,
  getUUIDByAxiosConfig,
  resetCache,
} from '~/plugins/etag'
import { getHeaderCaseInsensitive } from '~/plugins/etag/utils'
import { Cache } from '~/plugins/etag/Cache'

// 1) Import your unified token logic
//    - This can be from the "configureAuth" file or directly from the TokenManager if you prefer.
import { configureAuth } from '~/plugins/auth/authToken' // or wherever your new logic resides

// Screen dimension state management
let cachedScreenHeight: number = window.innerHeight
let cachedScreenWidth: number = window.innerWidth

// Debounced dimension update function
const updateScreenDimensions = debounce(() => {
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth
}, 250)

export default function ({ $axios, $fire, $api, $sdk, store }: any) {
  // 2) Set up dimension tracking
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth
  window.addEventListener('resize', updateScreenDimensions)

  // 3) Get auth helpers from your centralized logic
  const { handleTokenRefresh, logout: authLogout } = configureAuth({
    app: null, // If your configureAuth expects a full Nuxt Context, pass it in
    store,
    $fire,
    // ...any other pieces it needs
  } as any)

  // 4) Define local logout, if you need extra steps like clearing caches
  const logout = async () => {
    resetCache()
    // Optionally call the centralized logout too
    await authLogout()
  }

  // 5) (Optional) Keep your existing ETag request interceptor
  $axios.interceptors.request.use(requestInterceptor)

  // 6) Configure response caching
  $axios.onResponse((response: any) => {
    if (isCacheableMethod(response.config)) {
      const responseETAG = getHeaderCaseInsensitive('etag', response.headers)
      if (responseETAG) {
        const uuid = getUUIDByAxiosConfig(response.config)
        if (!uuid) { return null }
        Cache.set(uuid, responseETAG, response.data)
      }
    }
    return response
  })

  // 7) Main request hook: Ensure we have a valid token and set headers
  $axios.onRequest(async (config: any) => {
    // Set screen dimension headers from cached values
    config.headers['screen-height'] = cachedScreenHeight
    config.headers['screen-width'] = cachedScreenWidth
    config.headers.support = 'web-app'

    try {
      // Call your SINGLE concurrency-safe refresh method
      // This function will internally check expiration and refresh only if needed
      const token = await handleTokenRefresh()

      // If we have a valid token, apply it to $axios, $api, $sdk, etc.
      if (token) {
        $api.setToken(token)
        config.headers.authorization = `Bearer ${token}`
      }

      return config
    } catch (error) {
      // If refresh or token retrieval fails, log out to clear everything
      await logout()
      // Then propagate the error
      throw error
    }
  })

  // 8) Response error hook: if you get a 401, force logout
  $axios.onResponseError(async (error: any) => {
    if (error.config && error.response && error.response.status === 401) {
      await logout()
      throw error
    }

    // Handle cached responses (304)
    if (error.response && error.response.status === 304) {
      const getCachedResult = getCacheByAxiosConfig(error.response.config)
      if (!getCachedResult) {
        throw error
      }
      const newResponse = error.response
      newResponse.status = 200
      newResponse.data = getCachedResult.value
      return newResponse
    }

    throw error
  })
}
