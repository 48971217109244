import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { Loading } from '~/store/app'
import { useBillingSignatureStore } from '~/composables/billing/_common/useBillingSignature.store'
import { useAlertManagerStore } from '~/composables/abby/manager/useAlertManager.store'
import { useConfirmManagerStore } from '~/composables/abby/manager/useConfirmManager.store'
import { useActivityStore } from '~/composables/abby/activity/useActivity.store'
import { useVatsStore } from '~/composables/accounting/_common/useVats.store'
import { useTimeTrackingSelectionStore } from '~/composables/productivity/timeTracking/useTimeTrackingSelection.store'
import { useTimeTrackingStore } from '~/composables/productivity/timeTracking/useTimeTracking.store'

export interface RootState {}

export const state = (): RootState => ({})

export const getters: GetterTree<RootState, RootState> = {}

export const mutations: MutationTree<RootState> = {}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtClientInit ({ dispatch, commit }) {
    dispatch('app/setAppLoading', { key: Loading.NUXT_CLIENT_LOADING, value: true })
    commit('app/SET_BASE_URL', process.env.baseAppUrl)
    commit('app/SET_INIT_TIMESTAMP', Date.now())
    commit('app/SET_API_HOST', process.env.apiHost)
    commit('app/SET_STRAPI_API_HOST', process.env.strapiApiHost)
    commit('app/SET_STRAPI_API_HOST', '/strapi')

    await dispatch('initializeUserRessources', null, { root: true })
    dispatch('app/setAppLoading', { key: Loading.NUXT_CLIENT_LOADING, value: false })
  },
  cleanupAction ({ commit }) {
    /**
     * Encapsulate all the resets in a try catch to avoid errors
     */
    try {
      const billingSignatureStore = useBillingSignatureStore()
      const alertManagerStore = useAlertManagerStore()
      const confirmManager = useConfirmManagerStore()
      const vatStore = useVatsStore()
      const timeTrackingSelectionStore = useTimeTrackingSelectionStore()
      const timeTrackingStore = useTimeTrackingStore()

      alertManagerStore.reset()
      confirmManager.reset()
      billingSignatureStore.reset()
      vatStore.reset()
      timeTrackingSelectionStore.reset()
      timeTrackingStore.reset()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }

    commit('academy/RESET')
    commit('accounting/RESET')
    commit('accountingAccounts/RESET')
    commit('accountingPage/RESET')
    commit('accounts/RESET')
    commit('signup2/RESET')
    commit('startGestion/RESET')
    commit('auth/RESET')
    commit('billing/RESET')
    commit('billingConfiguration/RESET')
    commit('billingCreation/RESET')
    commit('creationForm/RESET')
    commit('company/RESET')
    commit('creation/RESET')
    commit('urssaf/RESET')
    commit('gestion/RESET')
    commit('products/RESET')
    commit('sponsorship/RESET')
    commit('statistics/RESET')
    commit('incomeBook/RESET')
    commit('purchaseRegister/RESET')
    commit('user/RESET')
    commit('opportunity/RESET')
    commit('payment/RESET')
    commit('declarations/RESET')
    commit('experience/RESET')
    commit('providerDetails/RESET')
    commit('providers/RESET')
    commit('guide/RESET')
    commit('helpCenter/RESET')
    commit('upsells/RESET')
    commit('task/RESET')
    commit('vat/RESET')
    commit('frequency/RESET')
  },
  initializeCommonRessources ({ dispatch }) {
    useActivityStore() // This line allows to fetch activities and parent activities
  },
  async initializeUserRessources ({ dispatch, getters }) {
    await Promise.all([
      await dispatch('auth/fetchUser'),
      await dispatch('auth/fetchUserPreferences'),
      await dispatch('company/fetchPlans'),
      await dispatch('company/fetchCompany'),
      await dispatch('company/fetchCreation'),
      await dispatch('accounts/fetchBankAccounts'),
      await dispatch('vat/fetchVatConfiguration'),
      await dispatch('guide/fetchGuideSteps'),
      await dispatch('campaigns/fetchCampaigns'),
      await dispatch('guide/fetchPersona'),
    ])
    if (process.env.NODE_ENV !== 'development') {
      this.$sentry.setUser({
        lastname: getters['auth/user']?.firstname,
        firstname: getters['auth/user']?.lastname,
        email: getters['auth/user']?.email,
        id: getters['auth/user']?.id,
      })
    }
  },
}
