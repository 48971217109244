import { useClientStore } from '~/composables/client/_common/useClient.store'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'

export const useOpenContactTaxReturnModal = () => {
  const clientStore = useClientStore()
  const billingRepository = useBillingRepository()

  return async (contactId: string) => {
    const billingCustomer = await billingRepository.fetchCustomerFromContact(contactId)
    clientStore.toggleContactTaxReturnModal(true, billingCustomer)
  }
}
