import { PersonaFeature } from '@abby/core-legacy'
import { computed, useStore } from '@nuxtjs/composition-api'

export const useUserHasSapActivity = () => {
  const store = useStore()

  const userHasSapActivity = computed<boolean>(() => {
    const persona = store.getters['guide/persona']
    const hasImmediateAdvance = store.getters['billingConfiguration/billingConfiguration']?.tiersPrestationActivated as boolean
    return !!persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || hasImmediateAdvance
  })

  return userHasSapActivity
}
