import { OrganizationContactForm } from '~/services/client/organization/forms/OrganizationContact.form'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useCreateOrganizationContact = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organizationId: string, contact: OrganizationContactForm) => {
    const newContact = await organizationRepository.createContact({ organizationId, payload: contact })
    alertManager.success("Le contact d'organisation a bien été créé")
    busManager.emit('organizationContactCreated', newContact)
    return newContact
  }
}
