
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import AbbyPageSkeleton from '~/components/abby/skeletons/AbbyPageSkeleton.vue'

@Component({
  components: {
    AbbyPageSkeleton,
  },
})
export default class Page extends Vue {
  @Prop({ default: 'fluid' })
  width!: 'fluid' | 'xlarge' | 'large' | 'medium' | 'small' | 'full-height'

  @Prop({ default: null })
  backTo!: string | null

  @Prop({ default: 'Retour' })
  backText!: string | null

  @Prop({ default: false })
  loading!: boolean

  @Prop({ default: false })
  fullHeight!: boolean

  get isFullHeight () {
    return (this.width === 'full-height' || this.fullHeight)
  }

  @Getter('app/appLoading') appLoading!: boolean;
}
