import { Contact } from '~/services/client/contact/entities/Contact.entity'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useClientStore } from '~/composables/client/_common/useClient.store'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useFetchContactStatistics = () => {
  const contactRepository = useContactRepository()
  const clientStore = useClientStore()
  const alertManager = useAlertManager()

  return async (contact: Contact) => {
    try {
      const contactStatistic = await contactRepository.getContactStatisticsById(contact.id)
      clientStore.setContactStatistics(contactStatistic)
    } catch (e) {
      alertManager.autoError(e)
    }
  }
}
