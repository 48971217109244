import dayjs from 'dayjs'
import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'
import {
  ClientCopyTiersPrestationMessageHttpMapper,
} from '~/services/client/_common/mappers/ClientCopyTiersPrestationEmailMessage.mapper'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useClientStore } from '~/composables/client/_common/useClient.store'

export const useOpenCopyUrssafActionUpdatedEmailMessage = () => {
  const contactRepository = useContactRepository()
  const clientStore = useClientStore()

  return async (contact: ContactItem) => {
    const customer = await contactRepository.getContactSapData(contact.id)
    if (customer) {
      const result = ClientCopyTiersPrestationMessageHttpMapper.toDomain({
        customer,
        title: 'Demande de modification d\'adresse email',
        subject: 'Changement d\'adresse email du compte Urssaf',
        content: `Bonjour,<br><br>
        J'ai fait une erreur en enregistrant le particulier avec l'id : ${customer.id} au niveau de son adresse e-mail.<br>
        Il faudrait remplacer son ancienne adresse email ${customer.email} par cette adresse email : {{nouvel email}}<br>
        Voici les informations du particulier :<br>
        - Nom de naissance : ${customer.birthname}<br>
        - Nom d'usage : ${customer.lastname}<br>
        - Prénom : ${customer.firstname}<br>
        - Date de naissance : ${dayjs(customer.birthDate).format('DD/MM/YYYY')}<br><br>
        Merci d'avance,<br><br>
        Bien cordialement`,
        hint: 'Vérifiez que la nouvelle adresse email est correcte',
      })
      clientStore.toggleClientCopyTiersPrestationModal(true, result)
    }
  }
}
