
import { Component, ModelSync, Prop, PropSync, Ref, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { billingTPDateAreValid, ICustomer } from '@abby/core-legacy'
import { ClientState } from '@abby/shared'
import AsForm from '~/components-legacy/inputs/ASForm.vue'
import ASDatePicker from '~/components-legacy/inputs/ASDatePicker.vue'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import AbbyAlert from '~/components/abby/messaging/AbbyAlert.vue'

@Component({
  components: {
    AbbyAlert,
    ASDatePicker,
    AsForm,
    AsTextField,
  },
  computed: {
    ClientState () {
      return ClientState
    },
  },
})
export default class BillingPaymentRequest extends Vue {
  @Ref('form') refForm!: InstanceType<typeof ValidationProvider>;

  @Prop()
  customer!: ICustomer

  @Prop()
  customerRegisteredAt?: Date

  @Prop({ default: false })
  dateOnly?: boolean

  @Prop({ default: () => false })
  disable!: boolean

  @PropSync('isValidated')
  _isValidated?: boolean

  @ModelSync('value', 'input')
  paymentRequest!: { dateDebutEmploi: Date | string, dateFinEmploi: Date | string, advanceAlreadyPayed: number };

  async mounted () {
    if (this.dateDebutEmploi && this.dateFinEmploi) {
      // @ts-ignore
      await this.$refs.form.validate()
    }
  }

  get customerTPStatus () {
    return this.customer?.tiersPrestation?.status
  }

  get warningStatusMessage () {
    if (!this.customerTPStatus || this.customerTPStatus === ClientState.SYNCED) { return '' }
    return {
      [ClientState.PENDING]: 'L\'urssaf est en train de valider l\'association du particulier et du prestataire.',
      [ClientState.ERROR]: 'Le compte de votre client est bloqué du à un impayé. Il faut qu\'il contacte l\'urssaf pour débloquer son compte.',
      [ClientState.END_USER_ACTION_REQUIRED]: 'Votre client doit créer son compte particulier.urssaf.fr ou accepter l\'association sur son compte particulier.urssaf.fr.',
    }[this.customerTPStatus]
  }

  allowedDatesDebutEmploi (value: Date | string) {
    return this.$dayjs(value).startOf('d').isSameOrAfter(this.$dayjs(this.customerRegisteredAt).startOf('d')) &&
      this.$dayjs(value).startOf('d').isSameOrBefore(this.$dayjs().startOf('d'))
  }

  allowedDatesFinEmploi (value: Date | string) {
    return this.$dayjs(value).startOf('d').isSameOrAfter(this.$dayjs(this.dateDebutEmploi).startOf('d')) &&
      this.$dayjs(value).startOf('month').isSame(this.$dayjs(this.dateDebutEmploi).startOf('month')) &&
      this.$dayjs(value).startOf('d').isSameOrBefore(this.$dayjs().startOf('d'))
  }

  get isBillingTPDateAreValid () {
    const debutEmploi = this.$dayjs(this.dateDebutEmploi).startOf('d').toDate()
    const finEmploi = this.$dayjs(this.dateFinEmploi).startOf('d').toDate()
    return billingTPDateAreValid(debutEmploi, finEmploi, this.$dayjs(this.customerRegisteredAt).startOf('d').toDate())
  }

  updateValue (key: string, value: Date | string) {
    this.$emit('input', {
      ...this.paymentRequest,
      [key]: value,
    })

    this.$nextTick(async () => {
      this._isValidated = !!await this.refForm.validate()
    })
  }

  get dateRegisteredAt () {
    return this.$dayjs(this.customerRegisteredAt).format('DD/MM/YYYY')
  }

  get dateDebutEmploi () {
    return this.paymentRequest?.dateDebutEmploi || ''
  }

  get dateFinEmploi () {
    return this.paymentRequest?.dateFinEmploi || ''
  }

  get advanceAlreadyPayed () {
    return this.paymentRequest?.advanceAlreadyPayed || 0
  }
}

