
import Vue from 'vue'
import { Component, Getter } from 'nuxt-property-decorator'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import {
  AbbyPlans,
  CampaignType,
  CreationCompanyStep,
  GuideLevelItem,
  ICompany,
  ICreationCompany,
  IGuideLevel,
  PlanFeature,
  StripeProductType,
} from '@abby/core-legacy'
import { IAuthUser } from '~/store/auth'
import MarketingProActionCard from '~/components/marketing/cards/MarketingProActionCard.vue'
import SponsorshipTooltip from '~/components/more/sponsorship/tooltips/SponsorshipTooltip.vue'
import { GuideState } from '~/store/guide'
import { BillingConfigurationState } from '~/store/billingConfiguration'
import { useLayout } from '~/composables/abby/manager/action/useLayout.manager'
import { useUserHasSapActivity } from '~/composables/abby/activity/action/useUserHasSapActivity'

@Component({
  components: { MarketingProActionCard, SponsorshipTooltip, LottieAnimation },
  setup () {
    const { offsetTop } = useLayout()
    const userHasSapActivity = useUserHasSapActivity()
    return {
      offsetTop,
      userHasSapActivity,
    }
  },
})
export default class Drawer extends Vue {
  @Getter('company/isInCreation')
  isInCreation!: boolean

  @Getter('company/company')
  company!: ICompany

  @Getter('company/isEI')
  isEI!: boolean

  @Getter('auth/user')
  user!: IAuthUser;

  @Getter('company/creation')
  creation!: ICreationCompany;

  @Getter('guide/persona')
  persona!: GuideState['persona'];

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: BillingConfigurationState['billingConfiguration'];

  @Getter('guide/guideConfig') guideConfig!: () => IGuideLevel[]
  @Getter('app/mobileMenuOpened') mobileMenuOpened!: boolean

  offsetTop!: number
  userHasSapActivity!: boolean

  get hasAbbyCreationStartBusiness () {
    return this.$planManager.has(StripeProductType.ABBY_CREATION_START_BUSINESS)
  }

  get menuIsOpen () {
    return this.mobileMenuOpened
  }

  set menuIsOpen (value: boolean) {
    if (value) {
      this.$store.dispatch('app/openMobileMenu')
    } else {
      this.$store.dispatch('app/closeMobileMenu')
    }
  }

  get isPaidAndMonthly () {
    return [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_START, AbbyPlans.ABBY_BUSINESS].includes(this.$planManager.whichPlanCompanyHas()) && this.$planManager.whichFrequencyPlanCompanyHas() === 'month'
  }

  openAbbyPlansModal () {
    this.$planManager.openAbbyPlansModal({
      feature: 'action' as PlanFeature,
      openPlan: true,
    })
  }

  get buildVersion () {
    return process.env.buildVersion
  }

  isMenuItemActive (item: any) {
    if (item.exact) { return this.$route.path === item.path }
    return this.$route.path.startsWith(item.path)
  }

  get displayUpgradePlans () {
    return (!this.$planManager.has(StripeProductType.ABBY_BUSINESS) || this.$planManager.isTrial() || this.isPaidAndMonthly) && !this.hasAbbyCreationStartBusiness
  }

  isSubMenuItemActive (item: any) {
    if (item.exact) { return this.$route.path === item.path }
    return this.$route.path.startsWith(item.path)
  }

  get buttonWidth () {
    return 240
  }

  get drawerWidth () {
    return this.buttonWidth
  }

  get hasVat () {
    return this.company?.hasVat
  }

  remaining (guideLevelItem: GuideLevelItem) {
    const guideLevel = this.guideConfig().find(g => g.level === guideLevelItem)
    if (!guideLevel) { return }
    if (guideLevel.steps.filter(s => s.completedAt).length === 0) { return 'Commencer' }
    if (guideLevel.steps.filter(s => s.completedAt).length === guideLevel.steps.length) { return 'Terminé' }
    return `${guideLevel.steps.filter(s => s.completedAt).length} / ${guideLevel.steps.length}`
  }

  progressionPercentage (guideLevelItem: GuideLevelItem) {
    const guideLevel = this.guideConfig().find(g => g.level === guideLevelItem)
    if (!guideLevel) { return }
    return (guideLevel.steps.filter(s => s.completedAt).length / guideLevel.steps.length) * 100
  }

  get isCompanyCreated () {
    return this.creation?.steps[CreationCompanyStep.COMPANY_CREATED]?.finishedAt
  }

  get displayCreationFollowUp () {
    return this.isInCreation && this.$campaignsManager.isDisplayable(CampaignType.HIDE_CREATION_FOLLOWUP)
  }

  get menuItems () {
    return [
      ...this.displayCreationFollowUp
        ? [{
          title: 'Suivi création',
          tooltip: 'Suivi création',
          iconUrl: '/drawer/file_white.svg',
          activeIconUrl: '/drawer/file_white_plain.svg',
          path: '/company/creation',
          icon: 'contact_page',
        }]
        : [],
      {
        title: 'Analyses',
        tooltip: 'Analyses',
        iconUrl: '/drawer/analyse_white.svg',
        activeIconUrl: '/drawer/analyse_white_plain.svg',
        path: '/',
        exact: true,
        icon: 'insights',
        expandMenuOnLand: true,
      },
      {
        title: 'Facturation',
        tooltip: 'Facturation',
        iconUrl: '/drawer/billing_white.svg',
        activeIconUrl: '/drawer/billing_white_plain.svg',
        path: '/billing',
        icon: 'description',
        guide: {
          level: GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY,
          status: this.remaining(GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY),
          ended: this.remaining(GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY) === 'Terminé',
          progress: this.progressionPercentage(GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY),
        },
        subMenu: [
          {
            title: 'Documents',
            path: '/billing/documents/invoices',
          },
          {
            title: 'Articles',
            path: '/billing/products/articles',
          },
          ...(this.userHasSapActivity
            ? [{
              title: 'Attestations fiscales',
              path: '/billing/certificates',
            }]
            : []),
          {
            title: 'Factures récurrentes',
            path: '/billing/recurrences',
          },
          {
            title: 'Paiements en ligne',
            path: '/billing/online-payments',
          },
          {
            title: 'Signatures électroniques',
            path: '/billing/online-signatures',
          },
          {
            title: 'Relances automatiques',
            path: '/billing/reminders',
          },
          // {
          //   title: 'Réglages',
          //   path: '/billing/settings',
          //   links: [
          //     {
          //       title: 'Style des documents',
          //       path: '/billing/settings/style',
          //     },
          //     {
          //       title: 'Informations',
          //       path: '/billing/settings/information',
          //     },
          //     {
          //       title: 'Paiement',
          //       path: '/billing/settings/payment',
          //     },
          //     {
          //       title: 'Relances automatiques',
          //       path: '/billing/settings/reminders',
          //     },
          //     {
          //       title: 'Envoi d\'emails',
          //       path: '/billing/settings/emails',
          //     },
          //     {
          //       title: 'Devises et langues',
          //       path: '/billing/settings/internationalization',
          //     },
          //     {
          //       title: 'Numérotation',
          //       path: '/billing/settings/number-format',
          //     },
          //     {
          //       title: 'Avance immédiate',
          //       path: '/billing/settings/immediate-advance',
          //     },
          //   ],
          // },
        ],
      },
      {
        title: 'Productivité',
        tooltip: 'Productivité',
        iconUrl: '/drawer/productivity_white.svg',
        activeIconUrl: '/drawer/productivity_white_plain.svg',
        path: '/productivity',
        icon: 'view_kanban',
        guide: {
          level: GuideLevelItem.GET_ORGANIZE_EASILY,
          status: this.remaining(GuideLevelItem.GET_ORGANIZE_EASILY),
          ended: this.remaining(GuideLevelItem.GET_ORGANIZE_EASILY) === 'Terminé',
          progress: this.progressionPercentage(GuideLevelItem.GET_ORGANIZE_EASILY),
        },
        subMenu: [
          {
            title: 'Tableau de suivi',
            path: '/productivity/board',
          },
          {
            title: 'Tâches',
            path: '/productivity/tasks',
            links: [
              {
                title: 'Aujourd\'hui',
                path: '/productivity/tasks/today',
              },
              {
                title: 'En retard',
                path: '/productivity/tasks/late',
              },
              {
                title: 'Prochainement',
                path: '/productivity/tasks/upcoming',
              },
              {
                title: 'Toutes mes tâches',
                path: '/productivity/tasks/all',
              },
              {
                title: 'Terminées',
                path: '/productivity/tasks/done',
              },
            ],
          },
          {
            title: 'Suivi du temps',
            path: '/productivity/time-tracking',
          },
          // {
          //   title: 'Réglages',
          //   path: '/productivity/settings',
          //   links: [
          //     {
          //       title: 'Tableau de suivi',
          //       path: '/productivity/settings/board',
          //     },
          //   ],
          // },
        ],
      },
      {
        title: 'Contacts',
        tooltip: 'Contacts',
        iconUrl: '/drawer/contact_white.svg',
        activeIconUrl: '/drawer/contact_white_plain.svg',
        path: '/customers',
        icon: 'group',
      },
      {
        title: 'Comptabilité',
        tooltip: 'Comptabilité',
        iconUrl: '/drawer/accounting_white.svg',
        activeIconUrl: '/drawer/accounting_white_plain.svg',
        path: '/accounting/',
        icon: 'savings',
        guide: {
          level: GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY,
          status: this.remaining(GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY),
          ended: this.remaining(GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY) === 'Terminé',
          progress: this.progressionPercentage(GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY),
        },
        subMenu: [
          {
            title: 'Transactions',
            path: '/accounting/v2/operations',
          },
          {
            title: 'Livres comptables',
            path: '/accounting/books',
            links: [
              {
                title: 'Livres des recettes',
                path: '/accounting/books/incomes',
              },
              {
                title: 'Livres des achats',
                path: '/accounting/books/expenses',
              },
              {
                title: 'Résultats',
                path: '/accounting/books/results',
              },
              {
                title: 'Compte de résultat',
                path: '/accounting/books/income-statement',
              },
            ],
          },
          {
            title: 'Justificatifs en attente',
            path: '/accounting/attachments',
          },
          ...this.isEI
            ? [
              {
                title: 'Déclarations Urssaf',
                path: '/accounting/declarations/urssaf',
              }]
            : [],
          ...this.hasVat
            ? [{
              title: 'Déclarations TVA',
              path: '/accounting/declarations/vat',
            }]
            : [],
          ...this.isEI
            ? [
              { title: 'Déclaration 2042-C-PRO', path: '/accounting/declarations/declarations-2042-c-pro' },
            ]
            : [],
          ...(this.userHasSapActivity
            ? [{
              title: 'Déclarations EMA (Nova)',
              path: '/accounting/declarations/EMA-declarations',
            }]
            : []),
        ],
      },
      {
        title: 'Aller plus loin',
        tooltip: 'Aller plus loin',
        iconUrl: '/drawer/more_white.svg',
        activeIconUrl: '/drawer/more_white_plain.svg',
        path: '/more',
        icon: 'dashboard_customize',
        subMenu: [
          {
            title: 'Mon CE',
            path: '/more/committee',
          },
          {
            title: 'Ressources',
            path: '/more/ressources',
          },
          {
            title: 'Académie',
            path: '/more/academy',
          },
          {
            title: 'Parrainage',
            path: '/more/sponsorship',
          },
          {
            title: 'API & Intégrations',
            path: '/more/integrations',
          },
          {
            title: 'Exports',
            path: '/more/exports',
          },
          {
            title: 'Communauté',
            path: '/more/community',
          },
        ],
      },
    ]
  }

  get version () {
    return process.env.version
  }

  get isAbbyBusiness () {
    return this.$planManager?.whichPlanCompanyHas() === AbbyPlans.ABBY_BUSINESS
  }

  get abbyLogo () {
    return this.$brandManager.logoUrl({ white: true })
  }
}

