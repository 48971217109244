
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-select',
  components: {
    ValidationProvider,
  },
})
export default class ASSelect extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: false })
  persistentHint!: boolean;

  @Prop({ default: [] })
  items!: Array<any>;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop()
  dataTestid?: string;

  scrollables: Array<any> = []

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  get selectElement () {
    return (this.$refs.element as any).$el as HTMLElement
  }

  get hasBottomLabelSlot () {
    return !!this.$slots.bottomLabel || !!this.$scopedSlots.bottomLabel
  }

  onFocus () {
    const scrollableParents = this.findScrollableParent(this.selectElement)
    this.scrollables = scrollableParents
    scrollableParents.forEach((scrollableParent) => {
      scrollableParent.style.overflow = 'hidden'
    })
  }

  onBlur () {
    this.scrollables.forEach((scrollableParent) => {
      scrollableParent.style.overflow = 'auto'
    })
  }

  isScrollable (element: HTMLElement) {
    if (!element) { return false }
    const hasScrollableContent = element.scrollHeight > element.clientHeight
    const overflowStyle = window.getComputedStyle(element).overflow
    const isScrollable = overflowStyle === 'auto' || overflowStyle === 'scroll'
    return hasScrollableContent && isScrollable
  }

  findScrollableParent (element: HTMLElement) {
    const scrollables = [
      // Add body and html elements by default
      document.body,
      document.documentElement,
    ]
    let currentElement : HTMLElement | null = element
    while (currentElement) {
      if (this.isScrollable(currentElement)) {
        scrollables.push(currentElement)
      }
      currentElement = currentElement.parentElement
    }
    return scrollables
  }

  @ModelSync('value', 'input')
  readonly innerValue: any;
}
