import { BillingType } from '@abby/shared'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'

export const useOpenDuplicateBillingModal = () => {
  const billingStore = useBillingStore()

  return async (billing: BillingItem, billingType: BillingType) => {
    return await billingStore.openDuplicateBillingModal(billing.id, billingType)
  }
}
