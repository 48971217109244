import { PlanFeature } from '@abby/core-legacy'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'

export const useOpenBillingReminderModal = () => {
  const planManager = usePlanManager()
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    if (!planManager.hasAccessToFeature(PlanFeature.BILLING_REMINDER)) {
      planManager.openPlansModal(PlanFeature.BILLING_REMINDER)
      return
    }
    await billingStore.openBillingReminderModal(billing)
  }
}
