import { GuideStepItem } from '@abby/core-legacy'
import { useStore } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'
import { useHelpManager } from '~/composables/abby/manager/action/useHelp.manager'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useDisableBillingTestMode = () => {
  const confirmManager = useConfirmManager()
  const helpManager = useHelpManager()
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const store = useStore()

  return async () => {
    const disableFromBilling = !!store.getters['guide/disableFromBilling']
    if (disableFromBilling) {
      const fromDocumentConfirm = await confirmManager.ask({
        title: 'Désactiver le mode test depuis ce document',
        content: '<b>Ce document de test en cours d\'édition</b> ainsi que les autres documents créés en mode test <b>disparaitront</b> une fois désactivé. Souhaitez-vous continuer ?',
        confirmText: 'C\'est parti !',
        confirmButton: { color: 'primary', depressed: true },
      })

      if (!fromDocumentConfirm) {
        return
      }
    } else {
      const confirm = await confirmManager.ask({
        title: 'Désactiver le mode test',
        content: '<p>Êtes-vous sûr de vouloir désactiver le mode test du logiciel de facturation ?</p><p class="mb-0">Si vous éditez un document, cette action fermera le document en cours d\'édition.</p>',
        confirmText: 'C\'est parti !',
        confirmButton: { depressed: true, color: 'primary' },
      })
      if (!confirm) {
        return
      }
    }

    await billingRepository.disableTestMode()
    await helpManager.completeGuideStep(GuideStepItem.DISABLE_TEST_MODE)
    alertManager.success('Le mode test du logiciel de facturation a été désactivé')
  }
}
