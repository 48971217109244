import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'

export const useOpenOpportunityPanel = () => {
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    await billingStore.openOpportunityPanel(billing)
  }
}
