export enum TaxSystem {
  // Régimes de la micro-entreprise
  MICRO_BIC = 'micro_bic',
  MICRO_BNC = 'micro_bnc',
  MICRO_BIC_OR_BNC = 'micro_bic_or_bnc',
  MICRO_BA = 'micro_ba',

  // Régime micro-fonciers
  MICRO_FONCIER = 'micro_foncier',
  MICRO_REVENU_FONCIER_IR_2072_2044 = 'micro_revenu_foncier_ir_2072_2044',

  // Régime réel à l'IR
  REEL_SIMPLIFIE_IR_2033_2031 = 'reel_simplifie_ir_2033_2031',
  REEL_NORMAL_IR_2033_2031 = 'reel_normal_ir_2033_2031',

  // Régime réel à l'IS
  REEL_SIMPLIFIE_IS_2033_2065 = 'reel_simplifie_is_2033_2065',
  REEL_NORMAL_IS_2050_2065 = 'reel_normal_is_2050_2065',

  // Régime de la déclaration contrôlée
  DECLARATION_CONTROLEE_2035 = 'declaration_controlee_2035',

  // Association
  ASSOCIATION_NON_LUCRATIF = 'association_non_lucratif',
}
