import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'
import {
  useAccountingBillingRepository,
} from '~/composables/billing/_common/useAccountingBilling.repository'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'

export const useMarkAsUnpaid = () => {
  const busManager = useBusManager()
  const accountingBillingRepository = useAccountingBillingRepository()
  const confirmManager = useConfirmManager()

  return async (billing: BillingItem) => {
    const confirm = await confirmManager.ask({
      title: 'Marquer comme non payé',
      content: `Êtes-vous certain de vouloir marquer le document ${billing.number} comme non payé ?`,
      confirmText: 'Confirmer',
      confirmButton: { depressed: true, color: 'primary' },
    })
    if (!confirm) { return }
    await accountingBillingRepository.markBillingAsUnpaid({ id: billing.id, type: billing.type })
    busManager.emit('billingUpdated', billing)
  }
}
