import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import type { ReadTimeRecord } from '@abby/core-legacy'

export const useTimeTrackingSelectionStore = () => {
  const useSelectionStore = defineStore('timeTrackingSelection', () => {
    const isActivated = ref(false)
    const timeRecords = ref<ReadTimeRecord[]>([])

    const toggle = (timeRecord: ReadTimeRecord) => {
      const index = timeRecords.value.findIndex(t => t.id === timeRecord.id)
      if (index === -1) {
        timeRecords.value.push(timeRecord)
      } else {
        timeRecords.value.splice(index, 1)
      }
    }

    const activeSelectionMode = (timeRecord?: ReadTimeRecord) => {
      isActivated.value = true
      if (timeRecord) {
        toggle(timeRecord)
      }
    }

    const reset = () => {
      isActivated.value = false
      timeRecords.value = []
    }

    return {
      // State
      isActivated,
      timeRecords,

      // Actions
      toggle,
      activeSelectionMode,
      reset,
    }
  })

  const selectionStore = useSelectionStore()
  const { isActivated, timeRecords } = storeToRefs(selectionStore)

  return {
    // State refs
    isActivated,
    timeRecords,

    // Actions
    toggle: selectionStore.toggle,
    activeSelectionMode: selectionStore.activeSelectionMode,
    reset: selectionStore.reset,
  }
}
