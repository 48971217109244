import { set, ref, watchEffect, Ref, watch, computed } from 'vue'
import { createGlobalState } from '@vueuse/core'
import { useLayout } from './useLayout.manager'

export const useSidepanelState = createGlobalState(() => {
  const sidePanelsOpened = ref(0)
  const modalOpened = ref(0)
  const widthValues = ref<number[]>([])

  // @TODO: legacy implementation to work with the old dialog system
  const highestZIndex = ref(300)

  return { sidePanelsOpened, highestZIndex, modalOpened, widthValues }
})

export const useSidepanel = (isOpen: Ref<boolean>, sidePanelWidth: Ref<number>) => {
  const currentLayer = ref(0)
  const maxZIndex = ref(0)
  const currentZIndex = computed(() => maxZIndex.value + currentLayer.value)
  const state = useSidepanelState()
  const { offsetTop } = useLayout()

  // got from vuetify utils source
  const getZIndex = (el?: Element | null): number => {
    if (!el || el.nodeType !== Node.ELEMENT_NODE) { return 0 }

    const index = +window.getComputedStyle(el).getPropertyValue('z-index')

    if (!index) { return getZIndex(el.parentNode as Element) }
    return index
  }

  // got from vuetify utils source
  const getMaxZIndex = (exclude: Element[] = []) => {
    const zis = [200, state.highestZIndex.value]

    // Convert the NodeList to an array to
    // prevent an Edge bug with Symbol.iterator
    // https://github.com/vuetifyjs/vuetify/issues/2146
    const activeElements = [
      ...document.getElementsByClassName('v-menu__content--active'),
      ...document.getElementsByClassName('v-dialog__content--active'),
    ]

    // Get z-index for all active dialogs
    for (let index = 0; index < activeElements.length; index++) {
      if (!exclude.includes(activeElements[index])) {
        zis.push(getZIndex(activeElements[index]))
      }
    }

    return Math.max(...zis)
  }

  const getOffsetValue = (layer: number): number => {
    const nextPanelValue = state.widthValues.value[layer + 1]
    const currentValue = state.widthValues.value[layer]
    if (!nextPanelValue) {
      return 0
    } else {
      return nextPanelValue - currentValue + getOffsetValue(layer + 1)
    }
  }

  const offsetValue = computed(() => {
    const offset = getOffsetValue(currentLayer.value - 1)
    return (200 * (state.sidePanelsOpened.value - currentLayer.value)) + offset
  })

  const offsetStyle = computed(() => {
    if (isOpen.value) {
      return { transform: `translateX(${offsetValue.value * -1}px)` }
    }
    return {}
  })

  const sidePanelStyle = computed(() => {
    return {
      'z-index': currentZIndex.value,
      top: `${offsetTop.value}px`,
    }
  })

  watch(sidePanelWidth, (newWidth) => {
    set(state.widthValues.value, currentLayer.value - 1, newWidth)
  })

  watch(
    isOpen,
    (newValue, oldValue) => {
      if (!newValue && oldValue) {
        setTimeout(() => {
          currentLayer.value = 0
        }, 300)
        state.sidePanelsOpened.value = Math.max(state.sidePanelsOpened.value - 1, 0)
        set(state.widthValues.value, currentLayer.value - 1, 0)
      } else if (oldValue === false) {
        state.sidePanelsOpened.value++
        currentLayer.value = state.sidePanelsOpened.value + state.modalOpened.value
        maxZIndex.value = getMaxZIndex()
        set(state.widthValues.value, currentLayer.value - 1, sidePanelWidth.value)
      }
    },
    {
      immediate: true,
    },
  )
  return {
    ...state,
    currentLayer,
    currentZIndex,
    offsetStyle,
    sidePanelStyle,
  }
}
