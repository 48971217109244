import { BillingLocale } from '@abby/core-legacy'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'

export const useDownloadBilling = () => {
  const busManager = useBusManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem, options?: { locale: BillingLocale }) => {
    await billingRepository.downloadBilling(billing, options)
    billing.lastDownloadAt = new Date()
    busManager.emit('billingDownloaded', billing)
  }
}
