import { Money } from '@abby/shared'
import { BillingType } from '@abby/core-legacy'
import { useContext } from '@nuxtjs/composition-api'
import { useQueryClient } from '@tanstack/vue-query'
import {
  useAccountingBillingRepository,
} from '~/composables/billing/_common/useAccountingBilling.repository'
import { BillingAccountingForm } from '~/services/billing/_common/forms/BillingAccounting.form'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'
import { useBillingStore } from '~/composables/billing/_common/useBilling.store'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useReconciliateBilling = () => {
  const accountingBillingRepository = useAccountingBillingRepository()
  const toasterManager = useToasterManager()
  const billingStore = useBillingStore()
  const { $api } = useContext()
  const queryClient = useQueryClient()
  const refreshBillingEventLogs = async () => {
    await queryClient.invalidateQueries(['billingEventLogs'])
  }

  const busManager = useBusManager()

  return async (
    { id, number, type, remainingAmount, accountingBillings, test }:
    { id: string, number: string, type: BillingType, remainingAmount: Money, accountingBillings: BillingAccountingForm[], test: boolean }) => {

    if (test) {
      toasterManager.warning({
        title: 'Enregistrement impossible',
        message: 'Il est actuellement impossible d\'encaisser et de passer en payé un document lorsque le mode test est activé sur Abby.',
      })
      billingStore.toggleMarkAsPaidModal(false)
      return
    }

    let payload = {
      payments: accountingBillings.map((accountingBilling) => {
        return {
          transactionId: accountingBilling?.transactionId,
          method: accountingBilling.method,
          amount: accountingBilling.amount.value,
          receivedAt: accountingBilling.receivedAt,
          regulations: accountingBilling.regulations,
          ...(accountingBilling.otherMethod ? { otherMethod: accountingBilling.otherMethod } : {}),
        }
      }),
    }
    const accountingBillingsAmount = accountingBillings.reduce((acc, accountingBilling) => acc + accountingBilling.amount.value, 0)
    const partialReconciliate = (accountingBillingsAmount + remainingAmount.value) !== accountingBillingsAmount
    const hasZeroTotalAmount = accountingBillingsAmount === 0 && remainingAmount.value === 0
    if (hasZeroTotalAmount) {
      payload = { payments: [] }
    }
    await accountingBillingRepository.reconciliateBilling({
      id,
      number,
      type,
      partialReconciliate,
      hasZeroTotalAmount,
      payload,
    })

    const billingUpdated = await $api.billing.get(id)
    busManager.emit('billingUpdated', billingUpdated)
    await refreshBillingEventLogs()
  }
}
