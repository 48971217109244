import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query'
import { sdk, OrderDirection, ContactOrderBy } from '@abby/sdk'
import axios from 'axios'
import {
  CreateContact,
  RegisterCustomer,
  UpdateContactNotes,
  UpdateContact,
  SetDefaultContact,
} from '@abby/shared'

import { Ref } from 'vue'
import { ContactItemMapper } from '~/services/client/contact/mappers/ContactItem.mapper'
import { ClientStatisticsMapper } from '~/services/client/_common/mappers/ClientStatistics.mapper'
import { ContactMapper } from '~/services/client/contact/mappers/Contact.mapper'
import { ContactForm } from '~/services/client/contact/forms/Contact.form'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'
import { AbbyError } from '~/plugins/toasterManager'
import { useBackend } from '~/composables/abby/manager/action/useBackend.manager'

export type ContactFilterQuery = {
  search: Ref<string | undefined>,
  archived: Ref<boolean>,
  orderBy?: Ref<ContactOrderBy>,
  orderDirection?: Ref<OrderDirection>,
  forCertificate?: Ref<boolean>,
}

export type ContactPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
} & ContactFilterQuery

export const useContactRepository = () => {
  const queryClient = useQueryClient()
  const alertManager = useAlertManager()
  const toasterManager = useToasterManager()
  const backend = useBackend()

  const paginate = ({
    page,
    limit,
    search,
    archived,
    orderBy,
    orderDirection,
    forCertificate,
  }: ContactPaginateQuery) => {
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['contact', { page, limit, search, archived, orderBy, orderDirection, forCertificate }],
      queryFn: async ({ signal }) => {
        const { data } = await sdk.Contact.retrieveContacts({
          signal,
          query: {
            page: page.value,
            search: search.value?.length ? search.value : undefined,
            limit: limit.value,
            archived: archived.value,
            orderBy: orderBy?.value,
            orderDirection: orderDirection?.value,
            forCertificate: forCertificate?.value,
          },
        })
        return {
          ...data,
          docs: data?.docs.map(ContactItemMapper.toDomain),
        }
      },
      onError: (error) => {
        alertManager.autoError(error)
      },
      keepPreviousData: true,
    })
  }

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries(['contact'])
  }

  const { mutateAsync: archiveContact } = useMutation({
    mutationFn: (id: string) => backend.contact.archive(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: unarchiveContact } = useMutation({
    mutationFn: (id: string) => backend.contact.unarchive(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: removeContact } = useMutation({
    mutationFn: (id: string) => backend.contact.remove(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const getContactById = (id: string) => {
    return backend.contact.fetchContact(id)
  }

  const getContactStatisticsById = async (id: string) => {
    const result = await backend.contact.fetchContactStatistics(id)
    return ClientStatisticsMapper.toDomain(result)
  }

  const getContactSapData = async (id: string) => {
    return await backend.contact.fetchContactSapData(id)
  }

  const updateContactNotes = async (id: string, notes: UpdateContactNotes) => {
    return await backend.contact.updateContactNotes(id, notes)
  }

  const updateDefineAsPrimaryContact = async (
    organizationId: string,
    payload: SetDefaultContact,
  ) => {
    const editedContact = await backend.contact.updateDefineAsPrimaryContact(
      organizationId,
      payload,
    )
    await refreshPaginate()
    return ContactMapper.toDomain(editedContact)
  }

  const { mutateAsync: createContact } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: async (payload: ContactForm) => {
      const newContact: CreateContact = {
        firstname: payload.firstname,
        lastname: payload.lastname,
        emails: payload.emails,
        jobTitle: payload.jobTitle,
        phone: payload.phone,
        billingAddress: payload.billingAddress,
        deliveryAddress: payload.deliveryAddress,
        bankInformations: payload.bankInformations,
        preferences: payload.preferences,
      }
      const result = await backend.contact.create(newContact)
      return ContactMapper.toDomain(result)
    },
    onError: (error) => {
      toasterManager.autoError(error as AbbyError, 'warning')
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })
  const { mutateAsync: updateContact } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationKey: ['contact'],
    mutationFn: async (contact: ContactForm) => {
      if (!contact?.id) {
        return
      }
      const updateContact: UpdateContact = {
        firstname: contact.firstname,
        lastname: contact.lastname,
        emails: contact.emails,
        jobTitle: contact.jobTitle,
        phone: contact.phone,
        billingAddress: contact.billingAddress,
        deliveryAddress: contact.deliveryAddress,
        bankInformations: contact.bankInformations,
        preferences: contact.preferences,
      }
      const result = await backend.contact.update(contact.id, updateContact)
      return ContactMapper.toDomain(result)
    },
    onError: (error) => {
      alertManager.autoError(error)
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: registerTierPrestation } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationKey: ['contactTP'],
    mutationFn: async (newContact: RegisterCustomer) => {
      await backend.tierPrestation.registerCustomer(newContact)
    },
    onError: (error) => {
      alertManager.autoError(error)
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  return {
    paginate,
    getContactById,
    getContactSapData,
    getContactStatisticsById,
    updateContactNotes,
    updateDefineAsPrimaryContact,
    archiveContact,
    unarchiveContact,
    removeContact,
    createContact,
    updateContact,
    registerTierPrestation,
  }
}
