import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'

export const useUnlinkBilling = () => {
  const billingRepository = useBillingRepository()
  const confirmManager = useConfirmManager()

  return async (billing: BillingItem) => {
    const confirm = await confirmManager.ask({
      title: 'Enlever les liens de création entre les documents',
      content: 'Cette action est irréversible. Voulez-vous vraiment enlever les liens entre une facture, un acompte et son devis ?',
      confirmText: 'Continuer',
    })
    if (!confirm) {
      return
    }

    await billingRepository.unlinkBilling(billing)
  }
}
