import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'

export const useOpenBillingTitleModal = () => {
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    return await billingStore.openBillingTitleModal(billing.id)
  }
}
