import { PlanFeature } from '@abby/core-legacy'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'
import { useMoveManager } from '~/composables/abby/manager/action/useMove.manager'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'

export const useActivateOnlinePayment = () => {
  const confirmManager = useConfirmManager()
  const moveManager = useMoveManager()
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    if (!billingStore.retrieveOnlinePaymentIsActivated()) {
      moveManager.move('/settings/billing/payment')
      return
    }
    const confirm = await confirmManager.ask({
      title: 'Activer le paiement en ligne',
      confirmText: 'Activer',
      content: `Souhaitez-vous activer le paiement en ligne pour le document ${billing.number} ?`,
      confirmButton: { color: 'primary', depressed: true },
      feature: PlanFeature.BILLING_ONLINE_PAYMENT,
    })
    if (!confirm) { return }

    await billingRepository.activateOnlinePayment(billing)
    alertManager.success('Le paiement en ligne a été activé avec succès sur votre document')
  }
}
