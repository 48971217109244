import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type {
  CreateInvitationSponsorship,
  CreateSponsorship,
  CreateSponsorshipBalance,
  IReadSponsorship,
  QuerySponsorship,
  ReadSponsorships,
} from '@abby/core-legacy'
import { useContext } from '@nuxtjs/composition-api'
import { useApi } from '~/composables/abby/manager/action/useApi.manager'

export const useSponsorshipStore = () => {
  const useSponsorshipStore = defineStore('sponsorship', () => {
    const api = useApi()
    const godSons = ref<ReadSponsorships | null>(null)
    const paginationQuery = ref<QuerySponsorship>({
      page: 1,
      limit: 10,
    })
    const amountToWin = ref(0)
    const amountWon = ref(0)
    const amountWithdrew = ref(0)
    const { $api } = useContext()

    const setGodSons = (value: ReadSponsorships) => {
      godSons.value = value
    }

    const updateGodSon = (godSon: Partial<IReadSponsorship>) => {
      if (!godSon.id || !godSons.value?.data) { return }
      const index = godSons.value.data.findIndex(gs => gs.godSons.id === godSon.id)
      if (index === -1) { return }
      const { id, ...rest } = godSon
      godSons.value.data[index] = { ...godSons.value.data[index], ...rest }
    }

    const removeGodSon = (id: string) => {
      if (!godSons.value?.data) { return }
      const index = godSons.value.data.findIndex(godSon => godSon.godSons.id === id)
      if (index === -1) { return }
      godSons.value.data.splice(index, 1)
    }

    const reset = () => {
      godSons.value = null
      paginationQuery.value = { page: 1, limit: 10 }
      amountToWin.value = 0
      amountWon.value = 0
      amountWithdrew.value = 0
    }

    const setPaginationQuery = (query: QuerySponsorship) => {
      paginationQuery.value = query
    }

    const setAmountToWin = (amount: number) => {
      amountToWin.value = amount
    }

    const setAmountWon = (amount: number) => {
      amountWon.value = amount
    }

    const setAmountWithdrew = (amount: number) => {
      amountWithdrew.value = amount
    }

    const fetchGodSons = async () => {
      const result = await api.sponsorship.getAll(paginationQuery.value)
      setGodSons(result)
      getTotalsAmounts()
    }

    const sendNotificationToGoSon = async (godSon: IReadSponsorship) => {
      if (!godSon.id) { return }
      await api.sponsorship.notify(godSon.id)
      updateGodSon({ id: godSon.id, isAlreadyRemindByEmail: true })
    }

    const sendInviteByEmail = async (emails: CreateInvitationSponsorship[]) => {
      await api.sponsorship.invite(emails)
    }

    const createAndSendInvitations = async (emails: CreateInvitationSponsorship[]) => {
      const result = await api.sponsorship.create(emails)
      setGodSons(result)
      getTotalsAmounts()
    }

    const removeSponsorship = async (id: string) => {
      await api.sponsorship.delete(id)
      removeGodSon(id)
      getTotalsAmounts()
    }

    const update = async (payload: CreateSponsorship) => {
      return await api.sponsorship.update(payload)
    }

    const getTotalsAmounts = async () => {
      const amount = await api.sponsorship.getTotalsAmounts()
      setAmountToWin(amount?.toWin)
      setAmountWon(amount?.won)
      setAmountWithdrew(amount?.withdrew)
    }

    const createBalanceLine = async (payload: CreateSponsorshipBalance) => {
      await api.sponsorship.createBalance(payload)
    }

    return {
      godSons,
      paginationQuery,
      amountToWin,
      amountWon,
      amountWithdrew,
      setGodSons,
      updateGodSon,
      removeGodSon,
      reset,
      setPaginationQuery,
      setAmountToWin,
      setAmountWon,
      setAmountWithdrew,
      fetchGodSons,
      sendNotificationToGoSon,
      sendInviteByEmail,
      createAndSendInvitations,
      removeSponsorship,
      update,
      getTotalsAmounts,
      createBalanceLine,
    }
  })

  const sponsorshipStore = useSponsorshipStore()
  const {
    setGodSons,
    updateGodSon,
    removeGodSon,
    reset,
    setPaginationQuery,
    setAmountToWin,
    setAmountWon,
    setAmountWithdrew,
    fetchGodSons,
    sendNotificationToGoSon,
    sendInviteByEmail,
    createAndSendInvitations,
    removeSponsorship,
    update,
    getTotalsAmounts,
    createBalanceLine,
  } = sponsorshipStore

  const {
    godSons,
    paginationQuery,
    amountToWin,
    amountWon,
    amountWithdrew,
  } = storeToRefs(sponsorshipStore)

  return {
    godSons,
    paginationQuery,
    amountToWin,
    amountWon,
    amountWithdrew,
    setGodSons,
    updateGodSon,
    removeGodSon,
    reset,
    setPaginationQuery,
    setAmountToWin,
    setAmountWon,
    setAmountWithdrew,
    fetchGodSons,
    sendNotificationToGoSon,
    sendInviteByEmail,
    createAndSendInvitations,
    removeSponsorship,
    update,
    getTotalsAmounts,
    createBalanceLine,
  }
}

export type SponsorshipStore = ReturnType<typeof useSponsorshipStore>
