import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b89ab82 = () => interopDefault(import('../pages/accounting/index.vue' /* webpackChunkName: "pages/accounting/index" */))
const _2be20132 = () => interopDefault(import('../pages/billing/index.vue' /* webpackChunkName: "pages/billing/index" */))
const _0a34297e = () => interopDefault(import('../pages/clientPortal.vue' /* webpackChunkName: "pages/clientPortal" */))
const _6972fee9 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _74223d80 = () => interopDefault(import('../pages/confirm-paypal-payment.vue' /* webpackChunkName: "pages/confirm-paypal-payment" */))
const _550d0a5a = () => interopDefault(import('../pages/connect.vue' /* webpackChunkName: "pages/connect" */))
const _7a09bd0b = () => interopDefault(import('../pages/create-contact-sap.vue' /* webpackChunkName: "pages/create-contact-sap" */))
const _7a909efe = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _34377b51 = () => interopDefault(import('../pages/customers/index/contacts.vue' /* webpackChunkName: "pages/customers/index/contacts" */))
const _30581212 = () => interopDefault(import('../pages/customers/index/organizations.vue' /* webpackChunkName: "pages/customers/index/organizations" */))
const _132c85d8 = () => interopDefault(import('../pages/customers/index/providers.vue' /* webpackChunkName: "pages/customers/index/providers" */))
const _b976f3ec = () => interopDefault(import('../pages/extend-trial.vue' /* webpackChunkName: "pages/extend-trial" */))
const _f09962ea = () => interopDefault(import('../pages/firebase-connect.vue' /* webpackChunkName: "pages/firebase-connect" */))
const _18fedae8 = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _5baa9089 = () => interopDefault(import('../pages/integrations/index.vue' /* webpackChunkName: "pages/integrations/index" */))
const _6fd7b008 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _f0cd8b82 = () => interopDefault(import('../pages/more/index.vue' /* webpackChunkName: "pages/more/index" */))
const _5c06587c = () => interopDefault(import('../pages/productivity/index.vue' /* webpackChunkName: "pages/productivity/index" */))
const _7b430315 = () => interopDefault(import('../pages/redirection.vue' /* webpackChunkName: "pages/redirection" */))
const _0a74f20d = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6a160a60 = () => interopDefault(import('../pages/settings/index/index.vue' /* webpackChunkName: "pages/settings/index/index" */))
const _55e1bf4a = () => interopDefault(import('../pages/settings/index/accounting/index.vue' /* webpackChunkName: "pages/settings/index/accounting/index" */))
const _00707e3c = () => interopDefault(import('../pages/settings/index/billing/index.vue' /* webpackChunkName: "pages/settings/index/billing/index" */))
const _3b5f4dde = () => interopDefault(import('../pages/settings/index/integrations.vue' /* webpackChunkName: "pages/settings/index/integrations" */))
const _702d909d = () => interopDefault(import('../pages/settings/index/productivity-board.vue' /* webpackChunkName: "pages/settings/index/productivity-board" */))
const _3de3782c = () => interopDefault(import('../pages/settings/index/profile/index.vue' /* webpackChunkName: "pages/settings/index/profile/index" */))
const _3cc06b91 = () => interopDefault(import('../pages/settings/index/accounting/bank-connect.vue' /* webpackChunkName: "pages/settings/index/accounting/bank-connect" */))
const _10786f3e = () => interopDefault(import('../pages/settings/index/accounting/plan.vue' /* webpackChunkName: "pages/settings/index/accounting/plan" */))
const _4bc70c30 = () => interopDefault(import('../pages/settings/index/accounting/social-contribution.vue' /* webpackChunkName: "pages/settings/index/accounting/social-contribution" */))
const _771cb08d = () => interopDefault(import('../pages/settings/index/accounting/transactions.vue' /* webpackChunkName: "pages/settings/index/accounting/transactions" */))
const _3f54d009 = () => interopDefault(import('../pages/settings/index/accounting/turnover.vue' /* webpackChunkName: "pages/settings/index/accounting/turnover" */))
const _01570b5a = () => interopDefault(import('../pages/settings/index/accounting/urssaf.vue' /* webpackChunkName: "pages/settings/index/accounting/urssaf" */))
const _5f15dbbe = () => interopDefault(import('../pages/settings/index/accounting/vat.vue' /* webpackChunkName: "pages/settings/index/accounting/vat" */))
const _a215a7f4 = () => interopDefault(import('../pages/settings/index/billing/email.vue' /* webpackChunkName: "pages/settings/index/billing/email" */))
const _4d2242d0 = () => interopDefault(import('../pages/settings/index/billing/immediate-advance.vue' /* webpackChunkName: "pages/settings/index/billing/immediate-advance" */))
const _73e66816 = () => interopDefault(import('../pages/settings/index/billing/information.vue' /* webpackChunkName: "pages/settings/index/billing/information" */))
const _0a8a779c = () => interopDefault(import('../pages/settings/index/billing/internationalization.vue' /* webpackChunkName: "pages/settings/index/billing/internationalization" */))
const _117319e5 = () => interopDefault(import('../pages/settings/index/billing/number-format.vue' /* webpackChunkName: "pages/settings/index/billing/number-format" */))
const _395a20f0 = () => interopDefault(import('../pages/settings/index/billing/payment.vue' /* webpackChunkName: "pages/settings/index/billing/payment" */))
const _104e24f8 = () => interopDefault(import('../pages/settings/index/billing/reminder.vue' /* webpackChunkName: "pages/settings/index/billing/reminder" */))
const _38e3a5e0 = () => interopDefault(import('../pages/settings/index/billing/stock-management.vue' /* webpackChunkName: "pages/settings/index/billing/stock-management" */))
const _c7a628ca = () => interopDefault(import('../pages/settings/index/billing/style.vue' /* webpackChunkName: "pages/settings/index/billing/style" */))
const _b1bfabd6 = () => interopDefault(import('../pages/settings/index/profile/company.vue' /* webpackChunkName: "pages/settings/index/profile/company" */))
const _4a45b150 = () => interopDefault(import('../pages/settings/index/profile/personal.vue' /* webpackChunkName: "pages/settings/index/profile/personal" */))
const _1a74f858 = () => interopDefault(import('../pages/settings/index/profile/security.vue' /* webpackChunkName: "pages/settings/index/profile/security" */))
const _0c2f63b5 = () => interopDefault(import('../pages/settings/index/profile/subscription.vue' /* webpackChunkName: "pages/settings/index/profile/subscription" */))
const _26781e20 = () => interopDefault(import('../pages/settings/index/profile/users.vue' /* webpackChunkName: "pages/settings/index/profile/users" */))
const _f16b76f8 = () => interopDefault(import('../pages/settings/index/accounting/v2/bank-connect.vue' /* webpackChunkName: "pages/settings/index/accounting/v2/bank-connect" */))
const _2938601c = () => interopDefault(import('../pages/settings/index/accounting/v2/banks.vue' /* webpackChunkName: "pages/settings/index/accounting/v2/banks" */))
const _1e6f5cce = () => interopDefault(import('../pages/start/index.vue' /* webpackChunkName: "pages/start/index" */))
const _efed2cfa = () => interopDefault(import('../pages/start_4/index.vue' /* webpackChunkName: "pages/start_4/index" */))
const _313381c6 = () => interopDefault(import('../pages/start_4/index/index.vue' /* webpackChunkName: "pages/start_4/index/index" */))
const _a4f99bba = () => interopDefault(import('../pages/start_4/index/index/gestion/index.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/index" */))
const _f3c75b34 = () => interopDefault(import('../pages/start_4/index/index/gestion/index/index.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/index/index" */))
const _2428f4c0 = () => interopDefault(import('../pages/start_4/index/index/gestion/index/information.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/index/information" */))
const _51f4f094 = () => interopDefault(import('../pages/start_4/index/index/gestion/customize.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/customize" */))
const _0b34755a = () => interopDefault(import('../pages/start_4/index/index/gestion/customize/activity.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/customize/activity" */))
const _7c47a2d1 = () => interopDefault(import('../pages/start_4/index/index/gestion/customize/legal-status.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/customize/legal-status" */))
const _beb9aba6 = () => interopDefault(import('../pages/start_4/index/index/gestion/customize/main-feature.vue' /* webpackChunkName: "pages/start_4/index/index/gestion/customize/main-feature" */))
const _1cee1c44 = () => interopDefault(import('../pages/start_5/index.vue' /* webpackChunkName: "pages/start_5/index" */))
const _fc454072 = () => interopDefault(import('../pages/start_5/index/index.vue' /* webpackChunkName: "pages/start_5/index/index" */))
const _7b280b38 = () => interopDefault(import('../pages/start_5/index/index/gestion/index.vue' /* webpackChunkName: "pages/start_5/index/index/gestion/index" */))
const _cc77b432 = () => interopDefault(import('../pages/start_5/index/index/gestion/index/index.vue' /* webpackChunkName: "pages/start_5/index/index/gestion/index/index" */))
const _096a6381 = () => interopDefault(import('../pages/start_5/index/index/gestion/index/information.vue' /* webpackChunkName: "pages/start_5/index/index/gestion/index/information" */))
const _8e649f56 = () => interopDefault(import('../pages/start_5/index/index/gestion/customize.vue' /* webpackChunkName: "pages/start_5/index/index/gestion/customize" */))
const _63be428e = () => interopDefault(import('../pages/start_5/index/index/gestion/customize/activity.vue' /* webpackChunkName: "pages/start_5/index/index/gestion/customize/activity" */))
const _93db76a0 = () => interopDefault(import('../pages/start_5/index/index/gestion/customize/legal-status.vue' /* webpackChunkName: "pages/start_5/index/index/gestion/customize/legal-status" */))
const _27f93a5d = () => interopDefault(import('../pages/accounting/attachments.vue' /* webpackChunkName: "pages/accounting/attachments" */))
const _5b4b5d4c = () => interopDefault(import('../pages/accounting/books/index.vue' /* webpackChunkName: "pages/accounting/books/index" */))
const _a7522746 = () => interopDefault(import('../pages/accounting/books/index/index.vue' /* webpackChunkName: "pages/accounting/books/index/index" */))
const _6958107d = () => interopDefault(import('../pages/accounting/books/index/expense-old.vue' /* webpackChunkName: "pages/accounting/books/index/expense-old" */))
const _d9fc0540 = () => interopDefault(import('../pages/accounting/books/index/expenses.vue' /* webpackChunkName: "pages/accounting/books/index/expenses" */))
const _d464d8e0 = () => interopDefault(import('../pages/accounting/books/index/income-statement.vue' /* webpackChunkName: "pages/accounting/books/index/income-statement" */))
const _f6ff4a96 = () => interopDefault(import('../pages/accounting/books/index/incomes.vue' /* webpackChunkName: "pages/accounting/books/index/incomes" */))
const _7b93c67e = () => interopDefault(import('../pages/accounting/books/index/results.vue' /* webpackChunkName: "pages/accounting/books/index/results" */))
const _3229885f = () => interopDefault(import('../pages/accounting/declarations/index.vue' /* webpackChunkName: "pages/accounting/declarations/index" */))
const _1ffef3e9 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _ce5f3a92 = () => interopDefault(import('../pages/billing/certificates.vue' /* webpackChunkName: "pages/billing/certificates" */))
const _6b8bb890 = () => interopDefault(import('../pages/billing/documents/index.vue' /* webpackChunkName: "pages/billing/documents/index" */))
const _9732924c = () => interopDefault(import('../pages/billing/documents/index/_type.vue' /* webpackChunkName: "pages/billing/documents/index/_type" */))
const _65dc753c = () => interopDefault(import('../pages/billing/online-payments.vue' /* webpackChunkName: "pages/billing/online-payments" */))
const _84a667ec = () => interopDefault(import('../pages/billing/online-signatures.vue' /* webpackChunkName: "pages/billing/online-signatures" */))
const _88b889bc = () => interopDefault(import('../pages/billing/products/index.vue' /* webpackChunkName: "pages/billing/products/index" */))
const _bc897a8c = () => interopDefault(import('../pages/billing/products/index/articles.vue' /* webpackChunkName: "pages/billing/products/index/articles" */))
const _eae7ac7a = () => interopDefault(import('../pages/billing/products/index/stock-movement.vue' /* webpackChunkName: "pages/billing/products/index/stock-movement" */))
const _09220f98 = () => interopDefault(import('../pages/billing/recurrences.vue' /* webpackChunkName: "pages/billing/recurrences" */))
const _03f60ed6 = () => interopDefault(import('../pages/billing/reminders.vue' /* webpackChunkName: "pages/billing/reminders" */))
const _ea240dd0 = () => interopDefault(import('../pages/company/creation.vue' /* webpackChunkName: "pages/company/creation" */))
const _2af7c1dc = () => interopDefault(import('../pages/more/academy/index.vue' /* webpackChunkName: "pages/more/academy/index" */))
const _6d346e2c = () => interopDefault(import('../pages/more/committee.vue' /* webpackChunkName: "pages/more/committee" */))
const _2221eb56 = () => interopDefault(import('../pages/more/community.vue' /* webpackChunkName: "pages/more/community" */))
const _7943d4ec = () => interopDefault(import('../pages/more/exports.vue' /* webpackChunkName: "pages/more/exports" */))
const _4c4ca27c = () => interopDefault(import('../pages/more/integrations.vue' /* webpackChunkName: "pages/more/integrations" */))
const _11d3dafb = () => interopDefault(import('../pages/more/ressources.vue' /* webpackChunkName: "pages/more/ressources" */))
const _00d267fa = () => interopDefault(import('../pages/more/sponsorship.vue' /* webpackChunkName: "pages/more/sponsorship" */))
const _1e9bcfda = () => interopDefault(import('../pages/oauth/authorize.vue' /* webpackChunkName: "pages/oauth/authorize" */))
const _2c6af490 = () => interopDefault(import('../pages/productivity/board.vue' /* webpackChunkName: "pages/productivity/board" */))
const _d0082590 = () => interopDefault(import('../pages/productivity/tasks.vue' /* webpackChunkName: "pages/productivity/tasks" */))
const _3abfa9bb = () => interopDefault(import('../pages/productivity/tasks/index.vue' /* webpackChunkName: "pages/productivity/tasks/index" */))
const _336e9b4a = () => interopDefault(import('../pages/productivity/tasks/all.vue' /* webpackChunkName: "pages/productivity/tasks/all" */))
const _170b0fa9 = () => interopDefault(import('../pages/productivity/tasks/done.vue' /* webpackChunkName: "pages/productivity/tasks/done" */))
const _7bba4ccd = () => interopDefault(import('../pages/productivity/tasks/late.vue' /* webpackChunkName: "pages/productivity/tasks/late" */))
const _0a675eac = () => interopDefault(import('../pages/productivity/tasks/today.vue' /* webpackChunkName: "pages/productivity/tasks/today" */))
const _a32f8e7a = () => interopDefault(import('../pages/productivity/tasks/upcoming.vue' /* webpackChunkName: "pages/productivity/tasks/upcoming" */))
const _0cd928e1 = () => interopDefault(import('../pages/productivity/time-tracking.vue' /* webpackChunkName: "pages/productivity/time-tracking" */))
const _387fc156 = () => interopDefault(import('../pages/start/creation/index.vue' /* webpackChunkName: "pages/start/creation/index" */))
const _a9094250 = () => interopDefault(import('../pages/start/creation/index/activity.vue' /* webpackChunkName: "pages/start/creation/index/activity" */))
const _176d78c1 = () => interopDefault(import('../pages/start/creation/index/declaration.vue' /* webpackChunkName: "pages/start/creation/index/declaration" */))
const _75f44010 = () => interopDefault(import('../pages/start/creation/index/informations.vue' /* webpackChunkName: "pages/start/creation/index/informations" */))
const _3afa9189 = () => interopDefault(import('../pages/start/creation/index/personal.vue' /* webpackChunkName: "pages/start/creation/index/personal" */))
const _5ef5aebe = () => interopDefault(import('../pages/start/creation/index/signup.vue' /* webpackChunkName: "pages/start/creation/index/signup" */))
const _a2b93e5a = () => interopDefault(import('../pages/accounting/declarations/declarations-2042-c-pro.vue' /* webpackChunkName: "pages/accounting/declarations/declarations-2042-c-pro" */))
const _6e0e85f0 = () => interopDefault(import('../pages/accounting/declarations/EMA-declarations.vue' /* webpackChunkName: "pages/accounting/declarations/EMA-declarations" */))
const _6b27be25 = () => interopDefault(import('../pages/accounting/declarations/impots.vue' /* webpackChunkName: "pages/accounting/declarations/impots" */))
const _5ceceba8 = () => interopDefault(import('../pages/accounting/declarations/urssaf/index.vue' /* webpackChunkName: "pages/accounting/declarations/urssaf/index" */))
const _1f34142b = () => interopDefault(import('../pages/accounting/declarations/urssaf/index/index.vue' /* webpackChunkName: "pages/accounting/declarations/urssaf/index/index" */))
const _35369112 = () => interopDefault(import('../pages/accounting/declarations/urssaf/index/current.vue' /* webpackChunkName: "pages/accounting/declarations/urssaf/index/current" */))
const _51453c6d = () => interopDefault(import('../pages/accounting/declarations/urssaf/index/current-urssaf.vue' /* webpackChunkName: "pages/accounting/declarations/urssaf/index/current-urssaf" */))
const _25d8de2a = () => interopDefault(import('../pages/accounting/declarations/urssaf/index/next.vue' /* webpackChunkName: "pages/accounting/declarations/urssaf/index/next" */))
const _40d61c76 = () => interopDefault(import('../pages/accounting/declarations/vat.vue' /* webpackChunkName: "pages/accounting/declarations/vat" */))
const _600a434c = () => interopDefault(import('../pages/accounting/v2/operations.vue' /* webpackChunkName: "pages/accounting/v2/operations" */))
const _8a7ec416 = () => interopDefault(import('../pages/oauth/gmail/callback.vue' /* webpackChunkName: "pages/oauth/gmail/callback" */))
const _2e9e520a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3b967c7c = () => interopDefault(import('../pages/more/academy/course/_slug.vue' /* webpackChunkName: "pages/more/academy/course/_slug" */))
const _5f7fb8f0 = () => interopDefault(import('../pages/more/academy/course/_slug/reviews.vue' /* webpackChunkName: "pages/more/academy/course/_slug/reviews" */))
const _370c1993 = () => interopDefault(import('../pages/more/academy/course/_slug/summary.vue' /* webpackChunkName: "pages/more/academy/course/_slug/summary" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounting",
    component: _4b89ab82,
    name: "accounting"
  }, {
    path: "/billing",
    component: _2be20132,
    name: "billing"
  }, {
    path: "/clientPortal",
    component: _0a34297e,
    name: "clientPortal"
  }, {
    path: "/company",
    component: _6972fee9,
    name: "company"
  }, {
    path: "/confirm-paypal-payment",
    component: _74223d80,
    name: "confirm-paypal-payment"
  }, {
    path: "/connect",
    component: _550d0a5a,
    name: "connect"
  }, {
    path: "/create-contact-sap",
    component: _7a09bd0b,
    name: "create-contact-sap"
  }, {
    path: "/customers",
    component: _7a909efe,
    name: "customers",
    children: [{
      path: "contacts",
      component: _34377b51,
      name: "customers-index-contacts"
    }, {
      path: "organizations",
      component: _30581212,
      name: "customers-index-organizations"
    }, {
      path: "providers",
      component: _132c85d8,
      name: "customers-index-providers"
    }]
  }, {
    path: "/extend-trial",
    component: _b976f3ec,
    name: "extend-trial"
  }, {
    path: "/firebase-connect",
    component: _f09962ea,
    name: "firebase-connect"
  }, {
    path: "/guide",
    component: _18fedae8,
    name: "guide"
  }, {
    path: "/integrations",
    component: _5baa9089,
    name: "integrations"
  }, {
    path: "/maintenance",
    component: _6fd7b008,
    name: "maintenance"
  }, {
    path: "/more",
    component: _f0cd8b82,
    name: "more"
  }, {
    path: "/productivity",
    component: _5c06587c,
    name: "productivity"
  }, {
    path: "/redirection",
    component: _7b430315,
    name: "redirection"
  }, {
    path: "/settings",
    component: _0a74f20d,
    children: [{
      path: "",
      component: _6a160a60,
      name: "settings-index"
    }, {
      path: "accounting",
      component: _55e1bf4a,
      name: "settings-index-accounting"
    }, {
      path: "billing",
      component: _00707e3c,
      name: "settings-index-billing"
    }, {
      path: "integrations",
      component: _3b5f4dde,
      name: "settings-index-integrations"
    }, {
      path: "productivity-board",
      component: _702d909d,
      name: "settings-index-productivity-board"
    }, {
      path: "profile",
      component: _3de3782c,
      name: "settings-index-profile"
    }, {
      path: "accounting/bank-connect",
      component: _3cc06b91,
      name: "settings-index-accounting-bank-connect"
    }, {
      path: "accounting/plan",
      component: _10786f3e,
      name: "settings-index-accounting-plan"
    }, {
      path: "accounting/social-contribution",
      component: _4bc70c30,
      name: "settings-index-accounting-social-contribution"
    }, {
      path: "accounting/transactions",
      component: _771cb08d,
      name: "settings-index-accounting-transactions"
    }, {
      path: "accounting/turnover",
      component: _3f54d009,
      name: "settings-index-accounting-turnover"
    }, {
      path: "accounting/urssaf",
      component: _01570b5a,
      name: "settings-index-accounting-urssaf"
    }, {
      path: "accounting/vat",
      component: _5f15dbbe,
      name: "settings-index-accounting-vat"
    }, {
      path: "billing/email",
      component: _a215a7f4,
      name: "settings-index-billing-email"
    }, {
      path: "billing/immediate-advance",
      component: _4d2242d0,
      name: "settings-index-billing-immediate-advance"
    }, {
      path: "billing/information",
      component: _73e66816,
      name: "settings-index-billing-information"
    }, {
      path: "billing/internationalization",
      component: _0a8a779c,
      name: "settings-index-billing-internationalization"
    }, {
      path: "billing/number-format",
      component: _117319e5,
      name: "settings-index-billing-number-format"
    }, {
      path: "billing/payment",
      component: _395a20f0,
      name: "settings-index-billing-payment"
    }, {
      path: "billing/reminder",
      component: _104e24f8,
      name: "settings-index-billing-reminder"
    }, {
      path: "billing/stock-management",
      component: _38e3a5e0,
      name: "settings-index-billing-stock-management"
    }, {
      path: "billing/style",
      component: _c7a628ca,
      name: "settings-index-billing-style"
    }, {
      path: "profile/company",
      component: _b1bfabd6,
      name: "settings-index-profile-company"
    }, {
      path: "profile/personal",
      component: _4a45b150,
      name: "settings-index-profile-personal"
    }, {
      path: "profile/security",
      component: _1a74f858,
      name: "settings-index-profile-security"
    }, {
      path: "profile/subscription",
      component: _0c2f63b5,
      name: "settings-index-profile-subscription"
    }, {
      path: "profile/users",
      component: _26781e20,
      name: "settings-index-profile-users"
    }, {
      path: "accounting/v2/bank-connect",
      component: _f16b76f8,
      name: "settings-index-accounting-v2-bank-connect"
    }, {
      path: "accounting/v2/banks",
      component: _2938601c,
      name: "settings-index-accounting-v2-banks"
    }]
  }, {
    path: "/start",
    component: _1e6f5cce,
    name: "start"
  }, {
    path: "/start_4",
    component: _efed2cfa,
    children: [{
      path: "",
      component: _313381c6,
      name: "start_4-index",
      children: [{
        path: "gestion",
        component: _a4f99bba,
        children: [{
          path: "",
          component: _f3c75b34,
          name: "start_4-index-index-gestion-index"
        }, {
          path: "information",
          component: _2428f4c0,
          name: "start_4-index-index-gestion-index-information"
        }]
      }, {
        path: "gestion/customize",
        component: _51f4f094,
        name: "start_4-index-index-gestion-customize",
        children: [{
          path: "activity",
          component: _0b34755a,
          name: "start_4-index-index-gestion-customize-activity"
        }, {
          path: "legal-status",
          component: _7c47a2d1,
          name: "start_4-index-index-gestion-customize-legal-status"
        }, {
          path: "main-feature",
          component: _beb9aba6,
          name: "start_4-index-index-gestion-customize-main-feature"
        }]
      }]
    }]
  }, {
    path: "/start_5",
    component: _1cee1c44,
    children: [{
      path: "",
      component: _fc454072,
      name: "start_5-index",
      children: [{
        path: "gestion",
        component: _7b280b38,
        children: [{
          path: "",
          component: _cc77b432,
          name: "start_5-index-index-gestion-index"
        }, {
          path: "information",
          component: _096a6381,
          name: "start_5-index-index-gestion-index-information"
        }]
      }, {
        path: "gestion/customize",
        component: _8e649f56,
        name: "start_5-index-index-gestion-customize",
        children: [{
          path: "activity",
          component: _63be428e,
          name: "start_5-index-index-gestion-customize-activity"
        }, {
          path: "legal-status",
          component: _93db76a0,
          name: "start_5-index-index-gestion-customize-legal-status"
        }]
      }]
    }]
  }, {
    path: "/accounting/attachments",
    component: _27f93a5d,
    name: "accounting-attachments"
  }, {
    path: "/accounting/books",
    component: _5b4b5d4c,
    children: [{
      path: "",
      component: _a7522746,
      name: "accounting-books-index"
    }, {
      path: "expense-old",
      component: _6958107d,
      name: "accounting-books-index-expense-old"
    }, {
      path: "expenses",
      component: _d9fc0540,
      name: "accounting-books-index-expenses"
    }, {
      path: "income-statement",
      component: _d464d8e0,
      name: "accounting-books-index-income-statement"
    }, {
      path: "incomes",
      component: _f6ff4a96,
      name: "accounting-books-index-incomes"
    }, {
      path: "results",
      component: _7b93c67e,
      name: "accounting-books-index-results"
    }]
  }, {
    path: "/accounting/declarations",
    component: _3229885f,
    name: "accounting-declarations"
  }, {
    path: "/auth/login",
    component: _1ffef3e9,
    name: "auth-login"
  }, {
    path: "/billing/certificates",
    component: _ce5f3a92,
    name: "billing-certificates"
  }, {
    path: "/billing/documents",
    component: _6b8bb890,
    name: "billing-documents",
    children: [{
      path: ":type?",
      component: _9732924c,
      name: "billing-documents-index-type"
    }]
  }, {
    path: "/billing/online-payments",
    component: _65dc753c,
    name: "billing-online-payments"
  }, {
    path: "/billing/online-signatures",
    component: _84a667ec,
    name: "billing-online-signatures"
  }, {
    path: "/billing/products",
    component: _88b889bc,
    name: "billing-products",
    children: [{
      path: "articles",
      component: _bc897a8c,
      name: "billing-products-index-articles"
    }, {
      path: "stock-movement",
      component: _eae7ac7a,
      name: "billing-products-index-stock-movement"
    }]
  }, {
    path: "/billing/recurrences",
    component: _09220f98,
    name: "billing-recurrences"
  }, {
    path: "/billing/reminders",
    component: _03f60ed6,
    name: "billing-reminders"
  }, {
    path: "/company/creation",
    component: _ea240dd0,
    name: "company-creation"
  }, {
    path: "/more/academy",
    component: _2af7c1dc,
    name: "more-academy"
  }, {
    path: "/more/committee",
    component: _6d346e2c,
    name: "more-committee"
  }, {
    path: "/more/community",
    component: _2221eb56,
    name: "more-community"
  }, {
    path: "/more/exports",
    component: _7943d4ec,
    name: "more-exports"
  }, {
    path: "/more/integrations",
    component: _4c4ca27c,
    name: "more-integrations"
  }, {
    path: "/more/ressources",
    component: _11d3dafb,
    name: "more-ressources"
  }, {
    path: "/more/sponsorship",
    component: _00d267fa,
    name: "more-sponsorship"
  }, {
    path: "/oauth/authorize",
    component: _1e9bcfda,
    name: "oauth-authorize"
  }, {
    path: "/productivity/board",
    component: _2c6af490,
    name: "productivity-board"
  }, {
    path: "/productivity/tasks",
    component: _d0082590,
    children: [{
      path: "",
      component: _3abfa9bb,
      name: "productivity-tasks"
    }, {
      path: "all",
      component: _336e9b4a,
      name: "productivity-tasks-all"
    }, {
      path: "done",
      component: _170b0fa9,
      name: "productivity-tasks-done"
    }, {
      path: "late",
      component: _7bba4ccd,
      name: "productivity-tasks-late"
    }, {
      path: "today",
      component: _0a675eac,
      name: "productivity-tasks-today"
    }, {
      path: "upcoming",
      component: _a32f8e7a,
      name: "productivity-tasks-upcoming"
    }]
  }, {
    path: "/productivity/time-tracking",
    component: _0cd928e1,
    name: "productivity-time-tracking"
  }, {
    path: "/start/creation",
    component: _387fc156,
    name: "start-creation",
    children: [{
      path: "activity",
      component: _a9094250,
      name: "start-creation-index-activity"
    }, {
      path: "declaration",
      component: _176d78c1,
      name: "start-creation-index-declaration"
    }, {
      path: "informations",
      component: _75f44010,
      name: "start-creation-index-informations"
    }, {
      path: "personal",
      component: _3afa9189,
      name: "start-creation-index-personal"
    }, {
      path: "signup",
      component: _5ef5aebe,
      name: "start-creation-index-signup"
    }]
  }, {
    path: "/accounting/declarations/declarations-2042-c-pro",
    component: _a2b93e5a,
    name: "accounting-declarations-declarations-2042-c-pro"
  }, {
    path: "/accounting/declarations/EMA-declarations",
    component: _6e0e85f0,
    name: "accounting-declarations-EMA-declarations"
  }, {
    path: "/accounting/declarations/impots",
    component: _6b27be25,
    name: "accounting-declarations-impots"
  }, {
    path: "/accounting/declarations/urssaf",
    component: _5ceceba8,
    children: [{
      path: "",
      component: _1f34142b,
      name: "accounting-declarations-urssaf-index"
    }, {
      path: "current",
      component: _35369112,
      name: "accounting-declarations-urssaf-index-current"
    }, {
      path: "current-urssaf",
      component: _51453c6d,
      name: "accounting-declarations-urssaf-index-current-urssaf"
    }, {
      path: "next",
      component: _25d8de2a,
      name: "accounting-declarations-urssaf-index-next"
    }]
  }, {
    path: "/accounting/declarations/vat",
    component: _40d61c76,
    name: "accounting-declarations-vat"
  }, {
    path: "/accounting/v2/operations",
    component: _600a434c,
    name: "accounting-v2-operations"
  }, {
    path: "/oauth/gmail/callback",
    component: _8a7ec416,
    name: "oauth-gmail-callback"
  }, {
    path: "/",
    component: _2e9e520a,
    name: "index"
  }, {
    path: "/more/academy/course/:slug?",
    component: _3b967c7c,
    name: "more-academy-course-slug",
    children: [{
      path: "reviews",
      component: _5f7fb8f0,
      name: "more-academy-course-slug-reviews"
    }, {
      path: "summary",
      component: _370c1993,
      name: "more-academy-course-slug-summary"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
