import { Resource } from '../Resource';
import {
  CreateContact,
  ReadContact,
  ReadCustomerInformations,
  ReadCustomerStatistics,
  ReadPaginatedContacts, SetDefaultContact,
  UpdateContact,
  UpdateContactNotes,
} from '../../dto';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

type ContactFilterQuery = {
  search?: string;
};

export class Contact extends Resource {
  paginate(query: QueryParams<{
    page: number;
    limit: number;
    archived: boolean;
    orderBy?: string;
    orderDirection?: string;
  } & ContactFilterQuery>): Promise<ReadPaginatedContacts> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/contacts', { params, cancelToken });
  }

  fetchContact(id: string): Promise<ReadContact> {
    return this.http.$get(`/contact/${id}`);
  }

  fetchContactStatistics(id: string): Promise<ReadCustomerStatistics> {
    return this.http.$get(`/v2/customer/contact/${id}/statistics`);
  }

  fetchContactSapData(id: string): Promise<ReadCustomerInformations | undefined> {
    return this.http.$get(`/v2/tier-prestation/customer/${id}`);
  }

  updateContactNotes(id: string, payload: UpdateContactNotes): Promise<ReadContact> {
    return this.http.$patch(`/contact/${id}/notes`, payload);
  }

  updateDefineAsPrimaryContact(organizationId: string, payload: SetDefaultContact): Promise<ReadContact> {
    return this.http.$patch(`/organization/${organizationId}/default-contact`, payload);
  }

  archive(id: string): Promise<void> {
    return this.http.$patch(`/contact/${id}/archive`);
  }

  unarchive(id: string): Promise<void> {
    return this.http.$patch(`/contact/${id}/unarchive`);
  }

  remove(id: string): Promise<void> {
    return this.http.$delete(`/contact/${id}`);
  }

  create(contact: CreateContact): Promise<ReadContact> {
    return this.http.$post('/contact', contact);
  }

  update(id: string, contact: UpdateContact): Promise<ReadContact> {
    return this.http.$put(`/contact/${id}`, contact);
  }
}
