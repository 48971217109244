import { OrganizationForm } from '~/services/client/organization/forms/Organization.form'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'

export const useUpdateOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organization: OrganizationForm) => {
    const editedOrganization = await organizationRepository.updateOrganization(organization)
    alertManager.success('Le contact a bien été mis à jour')
    busManager.emit('organizationUpdated', editedOrganization)
    return editedOrganization
  }
}
