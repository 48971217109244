import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingStore } from '~/composables/billing/_common/useBilling.store'
import { useApi } from '~/composables/abby/manager/action/useApi.manager'

export const useOpenMigrateSAPArticlesModal = () => {
  const billingStore = useBillingStore()
  const api = useApi()

  return async (item: BillingItem) => {
    const billing = await api.billing.get(item.id)
    billingStore.toggleMigrateSAPModal(true, { billing })
  }
}
