import { BillingType } from '@abby/shared'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'
import { OrganizationItem } from '~/services/client/organization/entities/OrganizationItem.entity'
import { OrganizationAction } from '~/services/client/organization/enums/OrganizationAction.enum'
import { ContactAction } from '~/services/client/contact/enums/ContactAction.enum'
import { useClientStore } from '~/composables/client/_common/useClient.store'
import { ClientType } from '~/services/client/_common/enums/ClientType.enum'
import { OrganizationDetailsTabs } from '~/services/client/organization/enums/OrganizationDetailsTabs.enum'
import { useCopyOrganizationID } from '~/composables/client/organization/action/useCopyOrganizationID'
import {
  useOpenOrganizationDetailsSidePanel,
} from '~/composables/client/organization/action/useOpenOrganizationDetailsSidePanel'
import { useOpenClientFormEditionSidePanel } from '~/composables/client/_common/action/useOpenClientFormEditionSidePanel'
import { useArchiveOrganization } from '~/composables/client/organization/action/useArchiveOrganization'
import { useUnarchiveOrganization } from '~/composables/client/organization/action/useUnarchiveOrganization'
import { useRemoveOrganization } from '~/composables/client/organization/action/useRemoveOrganization'
import { useGoToClientPortal } from '~/composables/client/_common/action/useGoToClientPortal'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'

export const useHandleOrganizationActions = () => {
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const clientStore = useClientStore()
  const billingStore = useBillingStore()

  const copyOrganizationID = useCopyOrganizationID()
  const openOrganizationDetailsSidePanel = useOpenOrganizationDetailsSidePanel()
  const openClientFormEditionSidePanel = useOpenClientFormEditionSidePanel()
  const openOrganizationInfoSidePanel = useOpenOrganizationDetailsSidePanel()
  const archiveOrganization = useArchiveOrganization()
  const unarchiveOrganization = useUnarchiveOrganization()
  const removeOrganization = useRemoveOrganization()
  const goToClientPortal = useGoToClientPortal()

  const openBillingCreationModalWithClient = async (organization: OrganizationItem, type?: BillingType) => {
    const customer = await billingRepository.fetchCustomerFromOrganization(organization.id)
    await billingStore.toggleBillingCreationModal(true, type, customer)
    clientStore.toggleOrganizationDetailsSidePanel(false)
  }

  const actions: {
    [key in OrganizationAction]: {
      run: (organization: OrganizationItem, options?: any) => Promise<any> | any;
    }
  } = {
    [OrganizationAction.ADMIN_COPY_ORGANIZATION_ID]: {
      run: organization => copyOrganizationID(organization),
    },
    [OrganizationAction.VIEW_DETAILS]: {
      run: organization => openOrganizationDetailsSidePanel(organization.id),
    },
    [OrganizationAction.EDIT]: {
      run: organization => openClientFormEditionSidePanel(organization.id, ClientType.ORGANIZATION),
    },
    [OrganizationAction.EDIT_NOTES]: {
      run: organization => openOrganizationInfoSidePanel(organization.id, { defaultTab: OrganizationDetailsTabs.INFORMATION }),
    },
    [OrganizationAction.ARCHIVE]: {
      run: organization => archiveOrganization(organization),
    },
    [OrganizationAction.UNARCHIVE]: {
      run: organization => unarchiveOrganization(organization),
    },
    [OrganizationAction.DELETE]: {
      run: organization => removeOrganization(organization),
    },

    [ContactAction.CREATE_BILLING]: {
      run: organization => openBillingCreationModalWithClient(organization),
    },
    [ContactAction.CREATE_INVOICE]: {
      run: organization => openBillingCreationModalWithClient(organization, BillingType.INVOICE),
    },
    [ContactAction.CREATE_ESTIMATE]: {
      run: organization => openBillingCreationModalWithClient(organization, BillingType.ESTIMATE),
    },
    [ContactAction.CREATE_ASSET]: {
      run: organization => openBillingCreationModalWithClient(organization, BillingType.ASSET),
    },
    [ContactAction.CREATE_PURCHASE_ORDER]: {
      run: organization => openBillingCreationModalWithClient(organization, BillingType.PURCHASE_ORDER),
    },

    [OrganizationAction.GO_TO_CLIENT_PORTAL]: {
      run: organization => goToClientPortal(organization.id, ClientType.ORGANIZATION),
    },
  }

  return async ({ action, organization, options }: { action: OrganizationAction, organization: OrganizationItem, options?: any }) => {
    try {
      await actions[action].run(organization, options)
    } catch (error) {
      alertManager.autoError(error)
    }
  }
}
