import type {
  CA,
  CreateDeclaration,
  CreateMandat,
  CreateMandatSepa,
  CreatePayment,
  DeleteMandatSepa,
  QueryDeclarationTotals,
  QueryEstimations,
  ReadDeclarationTotals,
  ReadMandatSepa,
  ReadMandatState,
  ReadOutputEstimations,
  ReadUrssafJourneyDocument,
  BasePaginateMongo,
  IUrssafDeclarationItem, Periode, QueryDeclarations,
  QueryUrssaf, ReadIdentification, UpdateRateConfiguration, UpdateUrssafJourney,
  DeleteMandat,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Urssaf extends Resource {
  // URSSAF API: 0.20
  getIdentification (query: QueryUrssaf): Promise<ReadIdentification> {
    return this.axios.$get('/urssaf/identification', { params: query })
  }

  // False estimation request in order to know company's mandat's state
  hasMandatWithUs (query: QueryUrssaf): Promise<ReadMandatState> {
    return this.axios.$get('/urssaf/hasMandatWithUs', { params: query })
  }

  // URSSAF API: 0.30
  createMandat (body: CreateMandat): Promise<boolean> {
    return this.axios.$post('/urssaf/mandat', body)
  }

  // URSSAF API: 0.31
  revokeMandat (query: DeleteMandat): Promise<boolean> {
    return this.axios.$delete('/urssaf/mandat', { params: query })
  }

  // URSSAF API: 0.40
  getEstimations (query: QueryEstimations): Promise<ReadOutputEstimations & { ca: CA }> {
    return this.axios.$post('/urssaf/estimations', query)
  }

  // URSSAF API: 0.50
  createDeclaration (query: { siret: string }, body: CreateDeclaration): Promise<IUrssafDeclarationItem> {
    return this.axios.$post(`/urssaf/declaration?siret=${query.siret}`, body)
  }

  // URSSAF API: 0.60
  createPayment (query: QueryUrssaf, body: CreatePayment): Promise<IUrssafDeclarationItem> {
    return this.axios.$post(`/urssaf/payment?siret=${query.siret}`, body)
  }

  // URSSAF API: 0.70
  createMandatSepa (body: CreateMandatSepa): Promise<ReadMandatSepa> {
    return this.axios.$post('/urssaf/mandatSepa', body)
  }

  // URSSAF API: 0.71
  revokeMandatSepa (query: DeleteMandatSepa): Promise<boolean> {
    return this.axios.$delete('/urssaf/mandatSepa', { params: query })
  }

  // URSSAF API: 0.75
  getMandatsSepaList (query: QueryUrssaf): Promise<ReadMandatSepa[]> {
    return this.axios.$get('/urssaf/mandatsSepa', { params: query })
  }

  get (): Promise<ReadUrssafJourneyDocument> {
    return this.axios.$get('/urssaf')
  }

  getTotals (query: QueryDeclarationTotals): Promise<ReadDeclarationTotals> {
    return this.axios.$post('/urssaf/totals', query)
  }

  changeBasedOn (payload: UpdateUrssafJourney): Promise<ReadUrssafJourneyDocument> {
    return this.axios.$put('/urssaf/basedOn', payload)
  }

  updateUrssafJourney (payload: UpdateUrssafJourney): Promise<ReadUrssafJourneyDocument> {
    return this.axios.$put('/urssaf', payload)
  }

  changeRateConfig (payload: UpdateRateConfiguration): Promise<ReadUrssafJourneyDocument> {
    return this.axios.$put('/urssaf/rateConfiguration', payload)
  }

  getAll (query: QueryDeclarations): Promise<BasePaginateMongo<IUrssafDeclarationItem>> {
    return this.axios.$get('/urssaf/declarations', { params: query })
  }

  setHasAlreadyDeclareElsewhere (periode: Periode): Promise<IUrssafDeclarationItem> {
    return this.axios.$post('urssaf/setHasAlreadyDeclareElsewhere', { periode })
  }

  getByPeriod (periode: Periode): Promise<IUrssafDeclarationItem> {
    return this.axios.$post('urssaf/declarationByPeriod', { periode })
  }
}
