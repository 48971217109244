import { useClientStore } from '~/composables/client/_common/useClient.store'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { OrganizationMapper } from '~/services/client/organization/mappers/Organization.mapper'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'

export const useOpenOrganizationDetailsSidePanel = () => {
  const organizationRepository = useOrganizationRepository()
  const billingRepository = useBillingRepository()
  const clientStore = useClientStore()

  return async (organizationId: string, options?: any) => {
    const readOrganization = await organizationRepository.getOrganizationById(organizationId)
    const organization = OrganizationMapper.toDomain(readOrganization)

    const billingCustomer = await billingRepository.fetchCustomerFromOrganization(organizationId)
    organization.customerId = billingCustomer.id
    clientStore.toggleOrganizationDetailsSidePanel(true, organization, options)
  }
}
