import { ClientType } from '~/services/client/_common/enums/ClientType.enum'
import { BillingCustomer } from '~/services/billing/_common/entities/BillingCustomer.entity'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'

export const useGoToClientPortal = () => {
  const billingRepository = useBillingRepository()

  return async (id: string, clientType: ClientType) => {
    let billingCustomer: BillingCustomer
    if (clientType === ClientType.ORGANIZATION) {
      billingCustomer = await billingRepository.fetchCustomerFromOrganization(id)
    } else {
      billingCustomer = await billingRepository.fetchCustomerFromContact(id)
    }
    window.open(`${process.env.baseAppUrl}/clientPortal?id=${billingCustomer.legacyId}&token=${billingCustomer.token}`, '_blank')
  }
}
