
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  components: {
    AbbyCountryFlag: () => import('~/components/abby/icons/AbbyCountryFlag.vue'),
  },
})
export default class BillingLocaleSelect extends Vue {
  @ModelSync('value', 'input')
  innerValue!: any;

  search = ''

  isFocused = false

  @Prop({ default: false })
  hideLabel!: boolean | string;

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  @Prop({ required: false })
  tooltip!: string;

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get items () {
    return [{
      country: 'fr',
      value: 'fr',
      text: 'Français',
    }, {
      country: 'gb',
      value: 'en',
      text: 'Anglais',
    }, {
      country: 'es',
      value: 'es',
      text: 'Espagnol',
    }, {
      country: 'de',
      value: 'de',
      text: 'Allemand',
    }, {
      country: 'it',
      value: 'it',
      text: 'Italien',
    }, {
      country: 'nl',
      value: 'nl',
      text: 'Néerlandais',
    }, {
      country: 'pt',
      value: 'pt',
      text: 'Portugais',
    }]
  }

  selectLocale () {
    this.isFocused = false
    // @ts-ignore
    this.$refs.select.blur()
  }

  get text () {
    return this.items.find(item => item.value === this.innerValue)?.text || ''
  }

  get country () {
    return this.items.find(item => item.value === this.innerValue)?.country || ''
  }

  filterCountry (_: string, queryText: string, itemText: string) {
    const hasValue = (val: string | null) => val !== null ? val : ''
    const text = hasValue(itemText)
    const query = hasValue(queryText)

    return text.toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase())
  }
}
