import { Middleware } from '@nuxt/types'
import { TokenManager } from '~/plugins/auth/authToken'

const register: Middleware = async ({ redirect, route, store, $updateManager }) => {
  await $updateManager.checkForUpdate()

  const isAuthenticationInitialized = store.getters['auth/expirationTime'] !== null
  const isConnected = TokenManager.getInstance().getToken()

  const isPathOnboarding = route.path.includes('/start_') || route.path.includes('/start/creation')
  if (isConnected && !isAuthenticationInitialized && !isPathOnboarding) {
    redirect((route.query?.redirect_to as string) || '/')
  }

  if (isConnected && isPathOnboarding && !store.getters['auth/user']) {
    await store.dispatch('initializeUserRessources')
  }

  const isOnboardingFinished = store.getters['auth/user']?.onboardingAt !== null
  if (isConnected && isOnboardingFinished) {
    redirect((route.query?.redirect_to as string) || '/')
  }
}

export default register
