export const mainBanks = [
  {
    name: 'BoursoBank',
    img: '/home/onboarding/banks/boursobank.png',
    id: 71,
  },
  {
    name: 'Crédit Mutuel',
    img: '/home/onboarding/banks/credit-mutuel.png',
    isParent: true,
  },
  {
    name: 'CIC',
    img: '/home/onboarding/banks/cic.png',
    isParent: true,
  },
  {
    name: 'Qonto',
    img: '/home/onboarding/banks/qonto.png',
    id: 86,
  },
  {
    name: 'Shine',
    img: '/home/onboarding/banks/shine.png',
    id: 42,
  },
  {
    name: 'LCL',
    img: '/home/onboarding/banks/lcl.png',
    idParent: true,
  },
  {
    name: 'BNP Paribas',
    img: '/home/onboarding/banks/bnp.png',
    isParent: true,
  },
]
