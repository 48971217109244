import { Plugin } from '@nuxt/types'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  connectAuthEmulator,
  Auth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  sendPasswordResetEmail,
  updatePassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  browserLocalPersistence,
} from 'firebase/auth'
import firebaseConfig from '../../../configs/firebase/firebaseConfig.json'
import { retrieveToken } from '~/middleware/auth'

// ugly fix
const retrieveAndSaveToken = () => {
  localStorage.removeItem('firebase:authUser:toDelete')
  const payload = retrieveToken()
  if (!payload.token) {
    return
  }
  localStorage.setItem('firebase:authUser:toDelete', JSON.stringify({
    stsTokenManager: {
      accessToken: payload.token,
      refreshToken: payload.refreshToken,
      expirationTime: payload.expirationTime,
    },
  }))
  setTimeout(() => {
    localStorage.removeItem('firebase:authUser:toDelete')
  }, 10_000)
}

const shouldConnectFirebaseEmulator = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const E2ETests = urlParams.get('E2ETests')
  if (E2ETests) {
    localStorage.setItem('E2ETests', 'TRUE')
  }
  return localStorage.getItem('E2ETests') === 'TRUE'
}

const FirebasePlugin: Plugin = (_, inject) => {
  if (window.location.pathname !== '/connect/') {
    retrieveAndSaveToken()
  }
  if (shouldConnectFirebaseEmulator()) {
    firebaseConfig.projectId = 'abby-test'
  }
  const firebaseApp = initializeApp(firebaseConfig)
  const auth = getAuth(firebaseApp)
  if (shouldConnectFirebaseEmulator()) {
    // eslint-disable-next-line no-console
    console.log('Connecting Firebase Auth Emulator')
    connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  }
  auth.setPersistence(browserLocalPersistence)

  inject('fire', {
    auth,
    signInWithCustomToken: (token: string) => signInWithCustomToken(auth, token),
    signInWithEmailAndPassword: (email: string, password: string) => signInWithEmailAndPassword(auth, email, password),
    signInWithPopup: (provider: any) => signInWithPopup(auth, provider),
    signInWithRedirect: (provider: any) => signInWithRedirect(auth, provider),
    sendPasswordResetEmail: (email: string) => sendPasswordResetEmail(auth, email),
    updatePassword: (password: string) => auth.currentUser ? updatePassword(auth.currentUser, password) : null,
    createUserWithEmailAndPassword: (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password),
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
  })
}

export interface FirebaseInstance {
  auth: Auth,
  signInWithCustomToken: (token: string) => Promise<any>,
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>
  signInWithPopup: (provider: any) => Promise<any>,
  signInWithRedirect: (provider: any) => Promise<any>,
  sendPasswordResetEmail: (email: string) => Promise<any>,
  updatePassword: (password: string) => Promise<any>,
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>,
  GoogleAuthProvider: typeof GoogleAuthProvider,
  FacebookAuthProvider: typeof FacebookAuthProvider,
  OAuthProvider: typeof OAuthProvider,
}

declare module 'vue/types/vue' {
  interface Vue {
    $fire: FirebaseInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $fire: FirebaseInstance
  }
  interface Context {
    $fire: FirebaseInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $fire: FirebaseInstance
  }
}

export default FirebasePlugin
