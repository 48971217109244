import { computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useActivityRepository } from '~/composables/abby/activity/useActivity.repository'

export const useActivityStore = () => {
  const useActivitiesStore = defineStore('activities', () => {
    const activityRepository = useActivityRepository()

    const { data: activitiesFetched } = activityRepository.fetchActivities()
    const { data: parentActivitiesFetched } = activityRepository.fetchParentActivities()

    const activities = computed(() => activitiesFetched.value || [])
    const parentActivities = computed(() => parentActivitiesFetched.value || [])

    return {
      activities,
      parentActivities,
    }
  })

  const activitiesStore = useActivitiesStore()
  const { activities, parentActivities } = storeToRefs(activitiesStore)

  return {
    activities,
    parentActivities,
  }
}
