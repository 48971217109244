import { useBillingStore } from '~/composables/billing/_common/useBillingVuex.store'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'

export const useOpenFinalInvoiceCreationModal = () => {
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    await billingStore.openFinalInvoiceCreationModal(billing)
  }
}
