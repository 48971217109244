import { Plugin } from '@nuxt/types'

export interface UpdateManagerInstance {
  checkForUpdate: () => Promise<void>
  initRemoteCheckForUpdate: () => void
  updateApp: () => void
}

const UpdateManagerPlugin: Plugin = ({ store, $dayjs, route, redirect, $config }, inject) => {
  let updateInterval: any = -1
  let maintenanceInterval: any = -1
  let versionWaiting = ''
  const checkForUpdate = () => {
    const currentVersion = localStorage.getItem('version')
    const version = process.env.buildVersion || ''
    if (version !== currentVersion) {
      // eslint-disable-next-line no-console
      console.log('new version available:', version)
      localStorage.setItem('version', version)
      window.location.reload()
    }
  }

  const remoteCheckForUpdate = async () => {
    if (process.env.NODE_ENV === 'development') { return }
    try {
      const response = await fetch('/version.txt', {
        cache: 'no-cache',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      if (!response.ok) {
        // eslint-disable-next-line no-console
        console.warn('Failed to fetch version.txt:', response.status)
        return
      }
      const buildVersion = await response.text()
      const currentVersion = localStorage.getItem('version')
      if (buildVersion === currentVersion) { return }
      await store.dispatch('app/setUpdateIsAvailable', true)
      versionWaiting = buildVersion
      clearInterval(updateInterval)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error checking for updates:', error)
    }
  }

  const checkForMaintenanceState = () => {
    const maintenanceStart = '2024-03-20T19:00'
    const maintenanceEnd = '2024-03-21T02:00'
    const isMaintenanceWindow = $dayjs().isBetween($dayjs(maintenanceStart), $dayjs(maintenanceEnd))
    const currentlyOnMaintenance = route.path.includes('/maintenance')
    if (!currentlyOnMaintenance && isMaintenanceWindow && $config.nodeEvn === 'production') {
      redirect('/maintenance')
    }
    clearInterval(maintenanceInterval)
  }

  const updateApp = () => {
    localStorage.setItem('version', versionWaiting)
    window.location.reload()
  }

  const initRemoteCheckForUpdate = () => {
    // if (process.env.NODE_ENV === 'development') { return }
    updateInterval = setInterval(remoteCheckForUpdate, 1000 * 60 * 5) // check every 5 minutes
    // TODO à déplacer dans un maintenance manager
    maintenanceInterval = setInterval(checkForMaintenanceState, 1000 * 60) // check every 1 minute
  }

  inject('updateManager', {
    checkForUpdate,
    initRemoteCheckForUpdate,
    updateApp,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $updateManager: UpdateManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $updateManager: UpdateManagerInstance
  }
  interface Context {
    $updateManager: UpdateManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $updateManager: UpdateManagerInstance
  }
}

export default UpdateManagerPlugin
