import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { Time } from '@abby/time'
import {
  type ReadTimeRecord,
} from '@abby/core-legacy'
import { TimeTrackingCurrentTimer } from '~/services/productivity/timeTracking/interfaces/TimeTrackingCurrentTimer'

export const useTimeTrackingStore = () => {
  const useStore = defineStore('timeTracking', () => {

    // STATE

    const timeInSeconds = ref(0)
    const intervalId = ref<number | null>(null)
    const timeRecordToEdit = ref<ReadTimeRecord | null>(null)
    const currentTimer = ref<TimeTrackingCurrentTimer>({
      name: '',
      startDate: null,
    })
    const editOrUpdatePanel = ref(false)

    // GETTERS

    const timerIsRunning = computed(() => !!currentTimer.value.startDate)

    // ACTIONS

    const startTimer = (params?: TimeTrackingCurrentTimer) => {
      if (intervalId.value || currentTimer.value.startDate) { return }

      const newIntervalId = setInterval(() => {
        const startTime = Time.fromDate(currentTimer.value.startDate as Date)
        const now = Time.now()
        timeInSeconds.value = Math.floor(now.difference(startTime).toSeconds())
      }, 300)

      intervalId.value = Number(newIntervalId)
      currentTimer.value = { ...currentTimer.value, ...(params || { startDate: new Date() }) }
      localStorage.setItem('currentTimeTracking', JSON.stringify(currentTimer.value))
    }

    const updateTimer = (timer: TimeTrackingCurrentTimer) => {
      currentTimer.value = timer
      localStorage.setItem('currentTimeTracking', JSON.stringify(currentTimer.value))
    }

    const resetTimer = () => {
      currentTimer.value = { startDate: null, name: '' }
      localStorage.removeItem('currentTimeTracking')
      if (intervalId.value) {
        clearInterval(intervalId.value)
        intervalId.value = null
      }
      timeInSeconds.value = 0
    }

    const resumeTimer = () => {
      const savedTimer = JSON.parse(localStorage.getItem('currentTimeTracking') || '{}')
      if (savedTimer?.startDate) {
        startTimer(savedTimer)
      }
    }

    const openCreateOrEditPanel = (record?: ReadTimeRecord) => {
      editOrUpdatePanel.value = true
      timeRecordToEdit.value = record || null
    }

    const closeCreateOrEditPanel = () => {
      editOrUpdatePanel.value = false
      timeRecordToEdit.value = null
    }

    const reset = () => {
      resetTimer()
      editOrUpdatePanel.value = false
      timeRecordToEdit.value = null
    }

    return {
      // State
      timeInSeconds,
      timeRecordToEdit,
      currentTimer,
      editOrUpdatePanel,
      intervalId,

      // Getters
      timerIsRunning,

      // Actions
      startTimer,
      updateTimer,
      resetTimer,
      resumeTimer,
      openCreateOrEditPanel,
      closeCreateOrEditPanel,
      reset,
    }
  })

  const store = useStore()
  const {
    timeInSeconds,
    timeRecordToEdit,
    currentTimer,
    editOrUpdatePanel,
    timerIsRunning,
    intervalId,
  } = storeToRefs(store)

  return {
    // State refs
    timeInSeconds,
    timeRecordToEdit,
    currentTimer,
    editOrUpdatePanel,
    intervalId,
    // Computed
    timerIsRunning,

    // Actions
    startTimer: store.startTimer,
    updateTimer: store.updateTimer,
    resetTimer: store.resetTimer,
    resumeTimer: store.resumeTimer,
    openCreateOrEditPanel: store.openCreateOrEditPanel,
    closeCreateOrEditPanel: store.closeCreateOrEditPanel,
    reset: store.reset,
  }
}
