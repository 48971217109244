import { ref } from 'vue'
import { Editor } from '@tiptap/vue-2'
// We have to import theses because for some reason, theses extensions methods are not recognized by the CI here
// @ts-ignore
import { Ai } from '@tiptap-pro/extension-ai'
// @ts-ignore
import { Color } from '@tiptap/extension-color'
import { AbbyPlans } from '@abby/core-legacy'

// Créer un état partagé avec une ref
export const isAiGenerating = ref(false)

export const useEditorAiAction = () => {
  return ({ editor, selectedText }: { editor: Editor, selectedText: string }) => {

    editor.on('transaction', ({ editor, transaction }) => {
      if (transaction.docChanged && isAiGenerating.value) {
        // Trouver tous les éléments avec la classe tiptap-ai-insertion
        const doc = editor.view.dom
        const aiInsertions = doc.getElementsByClassName('tiptap-ai-insertion')

        // Appliquer la couleur à chaque insertion
        Array.from(aiInsertions).forEach((insertion) => {
          const pos = editor.view.posAtDOM(insertion, 0)
          const endPos = pos + insertion.textContent!.length

          editor.chain()
            // @ts-ignore
            .setTextSelection({ from: pos, to: endPos } as unknown as Range)
            .setColor('#0075eb')
            .setTextSelection(endPos)
            .run()
        })
      }
    })

    const startAiGeneration = () => {
      isAiGenerating.value = true
    }

    return [
      {
        text: 'Personnalisé',
        customAction: true,
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiTextPrompt({
            text: selectedText,
            stream: true,
            format: 'rich-text',
          }).run()
        },
      },
      {
        text: 'Raccourcir',
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiShorten({
            text: `Raccourcis le texte suivant : ${selectedText}`,
            stream: true,
            language: 'fr',
            format: 'rich-text',
          }).run()
        },
      },
      {
        text: 'Améliorer le style',
        plan: AbbyPlans.ABBY_BUSINESS,
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiAdjustTone('default', {
            text: `Améliore le style d'écriture du texte suivant : ${selectedText}`,
            stream: true,
            language: 'fr',
            format: 'rich-text',
          }).run()
        },
      },
      {
        text: 'Améliorer l\'orthographe et la grammaire',
        plan: AbbyPlans.ABBY_BUSINESS,
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiFixSpellingAndGrammar({
            text: `Améliore l'orthographe et la grammaire du texte suivant : ${selectedText}`,
            stream: true,
            language: 'fr',
            format: 'rich-text',
          }).run()
        },
      },
      {
        text: 'Allonger',
        plan: AbbyPlans.ABBY_BUSINESS,
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiExtend({
            text: `Fais du texte suivant un texte un peu plus long : ${selectedText}. N'inventes rien, garde la même signification.`,
            stream: true,
            language: 'fr',
            format: 'rich-text',
          }).run()
        },
      },
      {
        text: 'Résumer',
        plan: AbbyPlans.ABBY_BUSINESS,
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiSummarize({
            text: `Résume le texte suivant : ${selectedText}`,
            stream: true,
            language: 'fr',
            format: 'rich-text',
          }).run()
        },
      },
      {
        text: 'Traduire',
        plan: AbbyPlans.ABBY_BUSINESS,
        action: () => {
          startAiGeneration()
          editor.chain().focus().aiTextPrompt({
            text: `Traduis le texte suivant en français quelque soit la langue de ce texte : "${selectedText}". Si le texte est déjà en français, traduis-le en anglais. Dans tous les cas, pas d'explications, juste renvoie le texte traduit.`,
            stream: true,
            format: 'rich-text',
          }).run()
        },
      },
    ]
  }
}
