import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useBillingRepository } from '~/composables/billing/_common/useBilling.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useFetchBillingUrssafSyncStatus = () => {
  const billingRepository = useBillingRepository()
  const alertManager = useAlertManager()

  return async (billing: BillingItem) => {
    const result = await billingRepository.fetchBillingUrssafSyncStatus(billing)
    alertManager.success('Le statut urssaf du document a bien été actualisé.')
    return result
  }
}
