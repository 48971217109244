import { LegalStatus, TaxSystem } from '@abby/core-legacy'
import { useI18nManager } from '~/composables/abby/manager/action/useI18n.manager'

const LIST: Record<number, Array<{ text: string, value: number, list: Array<TaxSystem> }>> = {
  [LegalStatus.EI]: [
    {
      text: 'Profession libérale (micro-BNC ou 2035)',
      value: 1,
      list: [
        TaxSystem.MICRO_BNC,
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant (micro-BIC ou 2031)',
      value: 2,
      list: [
        TaxSystem.MICRO_BIC,
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
      ],
    },
  ],
  [LegalStatus.SASU]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.SAS]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.SASU_SAS]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.EURL_SARL]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.EURL]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.EIRL]: [
    {
      text: 'Profession libérale (micro-BNC ou 2035)',
      value: 1,
      list: [
        TaxSystem.MICRO_BNC,
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant (micro-BIC ou 2031)',
      value: 2,
      list: [
        TaxSystem.MICRO_BIC,
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
      ],
    },
  ],
  [LegalStatus.SARL]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.SNC]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.SA]: [
    {
      text: 'Profession libérale',
      value: 1,
      list: [
        TaxSystem.DECLARATION_CONTROLEE_2035,
      ],
    },
    {
      text: 'Artisan-commerçant',
      value: 2,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IR_2033_2031,
        TaxSystem.REEL_NORMAL_IR_2033_2031,
      ],
    },
    {
      text: 'Impôt sur les sociétés',
      value: 3,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
  ],
  [LegalStatus.SCI]: [
    {
      text: 'Impôt sur les sociétés 2033 & 2065',
      value: 1,
      list: [
        TaxSystem.REEL_SIMPLIFIE_IS_2033_2065,
        TaxSystem.REEL_NORMAL_IS_2050_2065,
      ],
    },
    {
      text: 'Impôt sur le revenu 2072 & 2044',
      value: 2,
      list: [
        TaxSystem.MICRO_REVENU_FONCIER_IR_2072_2044,
      ],
    },
    {
      text: 'Micro-foncier',
      value: 3,
      list: [
        TaxSystem.MICRO_FONCIER,
      ],
    },
  ],
  [LegalStatus.ASSOCIATION]: [
    {
      text: 'Association à but non lucratif',
      value: 1,
      list: [
        TaxSystem.ASSOCIATION_NON_LUCRATIF,
      ],
    },
  ],
}

const DEFAULT_TAX_SYSTEM_BY_LEGAL_STATUS: Record<Partial<LegalStatus>, TaxSystem> = {
  [LegalStatus.MICRO_ENTREPRISE]: TaxSystem.MICRO_BIC_OR_BNC,
  [LegalStatus.EI]: TaxSystem.MICRO_BIC_OR_BNC,
  [LegalStatus.SAS]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.SARL]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.SASU]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.EURL]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.EIRL]: TaxSystem.MICRO_BIC_OR_BNC,
  [LegalStatus.SA]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.SNC]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.SASU_SAS]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.EURL_SARL]: TaxSystem.DECLARATION_CONTROLEE_2035,
  [LegalStatus.SCI]: TaxSystem.MICRO_REVENU_FONCIER_IR_2072_2044,
  [LegalStatus.ASSOCIATION]: TaxSystem.ASSOCIATION_NON_LUCRATIF,
  [LegalStatus.OTHER]: TaxSystem.MICRO_BIC_OR_BNC,
}

export const useGetCompanyTaxSystems = () => {
  const i18nManager = useI18nManager()

  const getTaxSystemsByLegalStatusWithCategories = (legalStatus: LegalStatus) => LIST[legalStatus]

  const getTaxSystemsByLegalStatus = (legalStatus: LegalStatus) => {
    const taxSystemCategories = LIST[legalStatus]

    if (!taxSystemCategories) { return [] }

    const taxSystems = []
    for (let i = 0; i < taxSystemCategories.length; i += 1) {
      const taxSystemCategory = taxSystemCategories[i]
      taxSystems.push(...taxSystemCategory.list)
    }
    return taxSystems.map(taxSystem => ({ text: i18nManager.t(`taxSystem.${taxSystem.toUpperCase()}`), value: taxSystem }))
  }

  const getDefaultTaxSystemByLegalStatus = (legalStatus: LegalStatus) => {
    return DEFAULT_TAX_SYSTEM_BY_LEGAL_STATUS[legalStatus]
  }

  return {
    getTaxSystemsByLegalStatusWithCategories,
    getTaxSystemsByLegalStatus,
    getDefaultTaxSystemByLegalStatus,
  }
}
