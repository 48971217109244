import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'
import {
  ClientCopyTiersPrestationMessageHttpMapper,
} from '~/services/client/_common/mappers/ClientCopyTiersPrestationEmailMessage.mapper'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useClientStore } from '~/composables/client/_common/useClient.store'

export const useOpenCopyUrssafActionEmailNotReceivedMessage = () => {
  const contactRepository = useContactRepository()
  const clientStore = useClientStore()

  return async (contact: ContactItem) => {
    const customer = await contactRepository.getContactSapData(contact.id)
    if (customer) {
      const result = ClientCopyTiersPrestationMessageHttpMapper.toDomain({
        customer,
        title: 'Email d\'activation non reçu',
        subject: 'Email d\'activation du compte Urssaf particulier non reçu',
        content: `Bonjour,<br><br>
        Le particulier avec l'id : ${customer.id} ne reçoit pas le mail pour activer son compte.<br>
        Son adresse email est : ${customer.email}<br><br>
        Merci d'avance,<br><br>
        Bien cordialement`,
      })
      clientStore.toggleClientCopyTiersPrestationModal(true, result)
    }
  }
}
