import { UpdateContactNotes } from '@abby/shared'
import { ContactMapper } from '~/services/client/contact/mappers/Contact.mapper'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useClientStore } from '~/composables/client/_common/useClient.store'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useUpdateContactNotes = () => {
  const contactRepository = useContactRepository()
  const clientStore = useClientStore()
  const alertManager = useAlertManager()

  return async (id: string, notes: UpdateContactNotes) => {
    const editedContact = await contactRepository.updateContactNotes(id, notes)
    const contact = ContactMapper.toDomain(editedContact)
    if (clientStore.selectedContact.value) {
      clientStore.selectedContact.value.notes = contact.notes
    }
    alertManager.success('Les notes du contact ont bien été mises à jour')
  }
}
