import { useFetchStockManagement } from '~/composables/product/stock/action/useFetchStockManagement'

export const useIsStockManagementEnabled = () => {

  const { fetchStockManagement } = useFetchStockManagement()

  const getIsStockManagementEnabled = async () => {
    const { data: stockConfiguration } = await fetchStockManagement()
    return !!stockConfiguration.value?.isActivated
  }

  return {
    getIsStockManagementEnabled,
  }
}
