import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import type {
  CreateTimeRecord,
  ReadTimeRecord,
  UpdateTimeRecord,
} from '@abby/core-legacy'
import { Ref } from 'vue'
import { useBusManager } from '~/composables/abby/manager/action/useBus.manager'
import { useApi } from '~/composables/abby/manager/action/useApi.manager'
import { useToasterManager } from '~/composables/abby/manager/action/useToaster.manager'
import { useTimeTrackingSelectionStore } from '~/composables/productivity/timeTracking/useTimeTrackingSelection.store'

export type TimeTrackingPaginateQuery = {
  page: Ref<number>
  limit: Ref<number>
  opportunityId?: Ref<string>
}

export const useTimeTrackingRepository = () => {
  const busManager = useBusManager()
  const queryClient = useQueryClient()
  const api = useApi()
  const toasterManager = useToasterManager()
  const { reset } = useTimeTrackingSelectionStore()

  const paginate = ({ page, limit, opportunityId }: TimeTrackingPaginateQuery) => {
    const {
      data,
      isLoading,
      isFetching,
      isError,
      isInitialLoading,
    } = useQuery({
      queryKey: ['timeTracking', { page, limit, opportunityId }],
      queryFn: async () => {
        return await api.timeTracking.getPaginated({
          page: page.value,
          limit: limit.value,
          opportunityId: opportunityId?.value,
        })
      },
      onError: (e: any) => {
        toasterManager.autoError(e)
      },
      keepPreviousData: true,
    })

    return {
      data,
      isLoading,
      isFetching,
      isError,
      isInitialLoading,
    }
  }

  const { mutateAsync: createTimeRecord } = useMutation({
    mutationFn: async (payload: CreateTimeRecord) => {
      return await api.timeTracking.create(payload)
    },
    onSuccess: (timeRecord: ReadTimeRecord) => {
      invalidateTimeTracking()
      return timeRecord
    },
    onError: (e: any) => {
      toasterManager.autoError(e)
    },
  })

  const { mutateAsync: updateTimeRecord } = useMutation({
    mutationFn: async ({ payload, id }: { payload: UpdateTimeRecord; id: string }) => {
      return await api.timeTracking.update(payload, id)
    },
    onSuccess: (timeRecord) => {
      invalidateTimeTracking()
      busManager.emit('timeRecordUpdated', timeRecord)
    },
    onError: (e: any) => {
      toasterManager.autoError(e)
    },
  })

  const { mutateAsync: bulkUpdateTimeRecords } = useMutation({
    mutationFn: async ({ payload, ids }: { payload: UpdateTimeRecord; ids: string[] }) => {
      return await api.timeTracking.bulkUpdate(payload, ids)
    },
    onSuccess: () => {
      invalidateTimeTracking()
    },
    onError: (e: any) => {
      toasterManager.autoError(e)
    },
  })

  const { mutateAsync: deleteTimeRecord } = useMutation({
    mutationFn: async (id: string) => {
      return await api.timeTracking.delete(id)
    },
    onSuccess: () => {
      toasterManager.success({
        message: "L'enregistrement a bien été supprimé",
      })
      invalidateTimeTracking()
    },
    onError: (e: any) => {
      toasterManager.autoError(e)
    },
  })

  const { mutateAsync: bulkDeleteTimeRecords } = useMutation({
    mutationFn: async (ids: string[]) => {
      await api.timeTracking.bulkDelete(ids)
      return ids
    },
    onSuccess: (deletedIds) => {
      deletedIds.forEach((id) => {
        deleteTimeRecord(id)
      })
      toasterManager.success({
        message: 'Enregistrements supprimés',
      })
      invalidateTimeTracking()
    },
    onError: (e: any) => {
      toasterManager.autoError(e)
    },
    onSettled: () => {
      reset()
    },
  })

  const invalidateTimeTracking = () => {
    queryClient.invalidateQueries({ queryKey: ['timeTracking'] })
  }

  return {
    paginate,
    createTimeRecord,
    updateTimeRecord,
    bulkUpdateTimeRecords,
    deleteTimeRecord,
    bulkDeleteTimeRecords,
    invalidateTimeTracking,
  }
}
