import { Editor } from '@tiptap/vue-2'
// We have to import theses because for some reason, theses extension methods are not recognized by the CI here
// @ts-ignore
import { Ai } from '@tiptap-pro/extension-ai'
// @ts-ignore
import { Color } from '@tiptap/extension-color'

export const useEditorAiProposition = () => {
  return ({ editor, oldEditorContent, color }: { editor: Editor, oldEditorContent: string, color: string }) => {
    return [
      {
        text: 'Accepter',
        icon: 'check',
        action: () => {
          const doc = editor.view.dom
          const spans = doc.querySelectorAll('span[style*="color: #0075eb"], span[style*="color: rgb(0, 117, 235)"]')

          Array.from(spans).forEach((span) => {
            const pos = editor.view.posAtDOM(span, 0)
            const endPos = pos + span.textContent!.length

            editor
              .chain()
              .focus()
              .setTextSelection({ from: pos, to: endPos })
              .setColor(color)
              .run()
          })

          editor
            .chain()
            .focus()
            .aiAccept()
            .blur()
            .run()
        },
      },
      {
        text: 'Refuser',
        icon: 'close',
        action: () => {
          editor
            .chain()
            .focus()
            .setContent(oldEditorContent)
            .removeEmptyTextStyle()
            .aiReject()
            .run()
        },
      },
      {
        text: 'Régénérer',
        icon: 'refresh',
        action: () => {
          editor.chain().focus().aiRegenerate().run()
        },
      },
    ]
  }
}
