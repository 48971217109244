import { useProductRepository } from '~/composables/product/_common/useProduct.repository'
import { ProductForm } from '~/services/product/_common/forms/Product.form'

export const useCreateProduct = () => {
  const productRepository = useProductRepository()

  const createProduct = async (product: ProductForm) => {
    const productToCreate = { ...product }
    return await productRepository.createProduct(productToCreate)
  }

  return async (product: ProductForm) => {
    return await createProduct(product)
  }
}
