import { OrganizationItem } from '~/services/client/organization/entities/OrganizationItem.entity'
import {
  useOrganizationRepository,
} from '~/composables/client/organization/useOrganization.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useUnarchiveOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()

  return async (organization: OrganizationItem) => {
    await organizationRepository.unarchiveOrganization(organization.id)
    alertManager.success('L\'organisation a été désarchivée avec succès.')
  }
}
