import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'
import { useContactRepository } from '~/composables/client/contact/useContact.repository'
import { useAlertManager } from '~/composables/abby/manager/action/useAlert.manager'

export const useArchiveContact = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()

  return async (contact: ContactItem) => {
    await contactRepository.archiveContact(contact.id)
    alertManager.success('Le contact a été archivé avec succès.')
  }
}
