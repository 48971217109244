import { IVat } from '../Interface/models/IVat';

export const Vat: Array<IVat> = [
  {
    id: 1,
    slug: '20,00 %',
    isODT: false,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: false,
    rate: 2000,
    billingRate: 2000,
  },
  {
    id: 2,
    slug: '10,00 %',
    isODT: false,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: false,
    rate: 1000,
    billingRate: 1000,
  },
  {
    id: 6,
    slug: '8,50 %',
    isODT: true,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: false,
    rate: 850,
    billingRate: 850,
  },
  {
    id: 3,
    slug: '5,50 %',
    isODT: false,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: false,
    rate: 550,
    billingRate: 550,
  },
  {
    id: 4,
    slug: '2,10 %',
    isODT: true,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: false,
    rate: 210,
    billingRate: 210,
  },
  {
    id: 5,
    slug: 'Hors Taxe',
    isODT: false,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: false,
    rate: 0,
    billingRate: 0,
  }, {
    id: 7,
    slug: 'Union Européenne',
    isODT: false,
    isEuropeanUnion: true,
    isOutsideEuropeanUnion: false,
    rate: 2000,
    billingRate: 0,
  },
  {
    id: 8,
    slug: 'Hors UE',
    isODT: false,
    isEuropeanUnion: false,
    isOutsideEuropeanUnion: true,
    rate: 2000,
    billingRate: 0,
  },
];

export const findVatFromId = (vatId: number): IVat | undefined => Vat.find(el => el.id === vatId);
